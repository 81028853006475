import React, { Component } from 'react';
import { Dialog, RadioButton, Button, Checkbox, Text, Textarea, Tooltip } from 'new-ui';
import { getText } from '../../../i18n';

import { isSmartAgent } from '../../bi/utils/env';

import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';

import { ApproveDialogProps, ApproveDialogState, ApproverType } from './types';

import styles from './styles.module.css';

const LABELS = {
  COMMENT_FOR_APPROVER: getText('components:approveDialog.commentForApprover'),
  SELECT_APPROVER: getText('components:approveDialog.selectApprover'),
  NEEDED_COMMENT: getText('components:approveDialog.neededComment'),
  CONTACT_WITH_ADMIN: getText('components:approveDialog.contactWithAdmin'),
  NO_APPROVERS: getText('components:approveDialog.noApprovers'),
  TRIP_WILL_BE_SENT: getText('components:approveDialog.tripWillBeSent'),
  SEEN_NEEDED_COMMENT: getText('components:approveDialog.seenNeededComment'),
  SEND: getText('common:send'),
  ERROR_EMPTY_COMMENT: getText('components:approvalSchemeDialog.errorEmptyComment'),
  APPROVAL_CKR: getText('approve:approvalsCKR'),
  APPROVALS_CKR: getText('approve:approvalsCKRDecline'),
};

class ApproveDialog extends Component<ApproveDialogProps, ApproveDialogState> {
  state = {
    loading: false,
  };

  static defaultProps = {
    header: null,
    emptyComment: () => {},
  };

  handleSendToApprove = () => {
    const { cartId, handleSendToApprove } = this.props;

    this.setState({ loading: true });

    handleSendToApprove(cartId).then(() => this.setState({ loading: false }));
  };

  getApproverName = ({ Name, Email }: ApproverType) => {
    const { showApproversCKR } = this.props;

    if (showApproversCKR) {
      return LABELS.APPROVAL_CKR;
    }

    if (Name === Email) {
      return (
        <Text qaAttr={ QA_ATTRIBUTES.cart.approval.dialog.approversList }>
          { Email }
        </Text>
      );
    }

    return (
      <div className={ styles.name }>
        <Text type='SEMIBOLD_16'>{ Name }</Text>
        &nbsp;
        {'—'}
        &nbsp;
        <Text qaAttr={ QA_ATTRIBUTES.cart.approval.dialog.approversList }>
          { Email }
        </Text>
      </div>
    );
  };

  renderTooltipContent = (label: string) => (
    <Text
      type='NORMAL_14_130'
      color='white'
      className={ styles.content }
    >
      { label }
    </Text>
  );

  renderApproversList() {
    const {
      approve: {
        approversList,
        comment,
        chooseOptions,
        chosenApprovers,
      },
      updateApproveComment,
      handleApproversListChange,
      showApproversCKR,
    } = this.props;

    const commentHtml = (
      <Textarea
        value={ comment }
        onChange={ updateApproveComment }
        placeholder={ LABELS.COMMENT_FOR_APPROVER }
      />
    );
    let blockHtml = null;

    if (!approversList.length) {
      blockHtml = (
        <>
          <Text type='NORMAL_16_140'>{LABELS.NO_APPROVERS}</Text>
          <Text type='NORMAL_16_140'>{LABELS.CONTACT_WITH_ADMIN}</Text>
        </>
      );
    } else if (approversList.length === 1) {
      const approver = () => {
        if (showApproversCKR) {
          return LABELS.APPROVALS_CKR;
        }

        if (approversList[0].Name === approversList[0].Email) {
          return approversList[0].Email;
        }

        return `${approversList[0].Email} - ${approversList[0].Name}.`;
      };

      const commentApprover = showApproversCKR ? LABELS.APPROVAL_CKR : approversList[0].Name;

      blockHtml = (
        <>
          <Text type='NORMAL_16_140' className={ styles.row }>
            {LABELS.TRIP_WILL_BE_SENT}&nbsp;
            <Text
              type='SEMIBOLD_16'
              className={ styles.item }
              qaAttr={ QA_ATTRIBUTES.cart.approval.dialog.approver }
            >
              { approver() }
            </Text>
          </Text>
          <Text type='NORMAL_16_140' className={ `${styles['comment-title']} ${styles.row}` }>
            {LABELS.SEEN_NEEDED_COMMENT}&nbsp;
            <Text type='SEMIBOLD_16' className={ styles.item }>{commentApprover}.</Text>
          </Text>
          { commentHtml }
        </>
      );
    } else if (approversList.length > 1) {
      blockHtml = (
        <>
          <Text className={ styles['list-title'] } qaAttr={ QA_ATTRIBUTES.cart.approval.dialog.selectApprover }>
            {LABELS.SELECT_APPROVER}
          </Text>
          <div className={ styles['approvers-list'] }>
            { approversList.map(item => (
              <div key={ item.Email } className={ styles.item }>
                {
                  chooseOptions ? (
                    <RadioButton
                      value={ (chosenApprovers[0] === item.Email) as unknown as string }
                      alternativeDesign={ isSmartAgent }
                      onChange={ () => handleApproversListChange(item.Email, true) }
                    >
                      { this.getApproverName(item) }
                    </RadioButton>
                  ) : (
                    <Checkbox
                      value={ chosenApprovers.includes(item.Email) }
                      onChange={ () => handleApproversListChange(item.Email) }
                    >
                      { this.getApproverName(item) }
                    </Checkbox>
                  )
                }
              </div>))
            }
          </div>
          <Text className={ styles['comment-title'] }>{LABELS.NEEDED_COMMENT}</Text>
          { commentHtml }
        </>
      );
    }

    return blockHtml;
  }

  render() {
    const {
      header,
      approve: {
        chosenApprovers,
        comment,
      },
      onClose,
      show,
      emptyComment,
    } = this.props;
    const { loading } = this.state;

    return (
      <Dialog show={ show } onChange={ onClose } showClosing>
        <div className={ styles.wrapper }>
          { header && (
            <Text
              type='bold_20_130'
              className={ styles.header }
            >
              { header }
            </Text>
          ) }
          { this.renderApproversList() }
          <div className={ styles.actions }>
            <Tooltip
              position='bottom'
              show={ emptyComment(comment) }
              renderContent={ () => this.renderTooltipContent(LABELS.ERROR_EMPTY_COMMENT) }
              className={ styles.airport_name }
            >
              <Button
                type='secondary'
                onClick={ this.handleSendToApprove }
                disabled={ !chosenApprovers.length || emptyComment(comment) || loading }
                qaAttr={ QA_ATTRIBUTES.cart.approval.dialog.buttonApprove }
              >
                {LABELS.SEND}
              </Button>
            </Tooltip>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default ApproveDialog;
