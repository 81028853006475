interface IAddress {
  Street: string | null;
  Number: string | null;
  Extra: string | null;
}

interface IGeoPosition {
  Latitude: number | null;
  Longitude: number | null;
}

interface IRoute {
  Address: IAddress;
  City: string;
  Country: string;
  GeoPosition: IGeoPosition | null;
  Id: number;
}

interface TaxiItemRoutesProps {
  item: IRoute;
  index: number;
  length: number;
}

interface ITaxiItemRoute {
  Address: {
    Extra: string,
    Number: string,
    Street: string,
  },
  City: string,
  Country: string,
  GeoPosition: {
    Latitude: number,
    Longitude: number,
  },
  Id: number,
}

interface ITaxiItem {
  EventDate: string,
  Class: number,
  Route: ITaxiItemRoute[],
}

export enum AnalyticTaxiClass {
  economy = 'economy',
  comfort = 'comfort',
  comfort_plus = 'comfort_plus',
  business = 'business',
  children = 'children',
  minivan = 'minivan',
  cruise = 'cruise',
  prime = 'prime',
  lite = 'lite',
  standart = 'standart',
  optimum = 'optimum',
}

export type {
  TaxiItemRoutesProps,
  IRoute,
  ITaxiItem,
  ITaxiItemRoute,
};
