import React from 'react';
import { Text } from 'new-ui';

import { getText } from '../../../../../i18n';

import { formatRangeDateWithSimplicity } from '../../../../bi/utils/formatDate';
import { getEmployeeFullNameWithSimplicity } from '../../../../bi/utils/employees';

import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import { IConflictSegment } from '../../../../bi/services/checkout/types';

const LABELS = {
  AIR: getText('trip:services.air'),
};

const SimpleTripAirSu = ({
  ArrivalDate,
  ArrivalCity,
  DepartureDate,
  DepartureCity,
  Employee,
  FlightNumber,
  Pnr,
}: IConflictSegment) => {
  const date = formatRangeDateWithSimplicity(
    DepartureDate,
    ArrivalDate,
    { withTime: true },
  );

  const employee = getEmployeeFullNameWithSimplicity(Employee);

  return (
    <div>
      <Text
        qaAttr={ QA_ATTRIBUTES.cart.duplicate.date }
      >
        { date }, SU { FlightNumber }
      </Text>
      <Text
        qaAttr={ QA_ATTRIBUTES.cart.duplicate.info }
        type='NORMAL_14_130'
      >
        { LABELS.AIR } { DepartureCity } &ndash; { ArrivalCity }
      </Text>
      <Text
        qaAttr={ QA_ATTRIBUTES.cart.duplicate.travellers }
        type='NORMAL_14'
        color='gray'
      >
        { employee }
      </Text>
      <Text
        qaAttr={ QA_ATTRIBUTES.cart.duplicate.travellers }
        type='NORMAL_14'
        color='gray'
      >
        { Pnr }
      </Text>
    </div>
  );
};

export default SimpleTripAirSu;
