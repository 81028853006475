import React from 'react';
import { observer } from 'mobx-react';
import { Checkbox, DotLoading, Text } from 'new-ui';

import { DraggableItem } from '../../../DraggableItem';

import { getText } from '../../../../../i18n';

import { EmployeeCloneItem } from '../../../EmployeeCloneItem';

import { getApproversAndRolesLength } from '../../../../bi/utils/approval';

import {
  APPROVAL_SCHEME_CONDITION_OF_APPROVAL_TYPES,
  APPROVAL_SCHEME_STEP_FIELDS,
  APPROVAL_SCHEME_TYPES,
  APPROVAL_STEP_DRAGGABLE_ITEM_TYPES,
  APPROVAL_TYPES,
} from '../../../../bi/constants/approvalSchemes';

import { ApprovalSchemePreStep, IEmployees, IRoles } from '../../../../bi/services/approvalSchemes/types';

import styles from './styles.module.css';

const LABELS = {
  CONDITION_OF_APPROVAL: {
    EVERY: getText('approvalScheme:step.conditionOfApproval.every'),
    ONLY_ONE: getText('approvalScheme:step.conditionOfApproval.onlyOne'),
  },
  SEND_TO: {
    EVERY: getText('approvalScheme:step.sendTo.every'),
    EVERY_REPORT: getText('approvalScheme:step.sendTo.everyReport'),
    CHOSEN: getText('approvalScheme:step.sendTo.chosen'),
  },
  ANYONE_ELSE: getText('approvalScheme:step.anyoneElse'),
  ADD_EMPLOYEE_OR_ROLE: getText('approvalScheme:step.addEmployeeOrRole'),
};

interface IApproverComponents {
  employees: IEmployees;
  roles: IRoles;
  value: ApprovalSchemePreStep;
  renderEmployee(e: any): React.ReactElement<typeof EmployeeCloneItem>;
  onChange(value: any): void;
  onClickAddApprover(): void;
  onDragRemovableApprover(cb: any): void;
  onDragEndRemovableApprover(): void;
  field: string;
  qaAttr: string;
}

type Payload = Record<string, (string | number)[] | number>;

const ApproverComponents = observer(({
  employees,
  roles,
  value,
  renderEmployee,
  onChange,
  onClickAddApprover,
  onDragRemovableApprover,
  onDragEndRemovableApprover,
  field = APPROVAL_TYPES.TRIP,
  qaAttr = '',
}: IApproverComponents) => {
  const { loading: eLoading, hash: employeesHash } = employees;
  const { loading: rLoading, hash: rolesHash } = roles;

  const { Roles, Approvers, ConditionOfApproval } = value;

  const handleOnChange = (payload: any) => onChange({ ...value, ...payload });

  const approversAndRolesLength = getApproversAndRolesLength(Roles, Approvers);

  const handleOnRemoveApprover = (id: string | number, type: string) => {
    const preparedSet: Set<string | number> = new Set((value as Record<string, any>)[type]);

    preparedSet.delete(id);
    const array = Array.from(preparedSet);
    const payload: Payload = { [type]: array };

    if (approversAndRolesLength === 2) {
      payload[APPROVAL_SCHEME_STEP_FIELDS.CONDITION_OF_APPROVAL] = APPROVAL_SCHEME_CONDITION_OF_APPROVAL_TYPES.EVERY.value;
    }

    handleOnChange(payload);
  };

  if (eLoading || rLoading) {
    return (
      <div className={ styles.loading }>
        <DotLoading />
      </div>
    );
  }

  const getRadioButtonsHeaderText = (type: number) => {
    if (type === APPROVAL_SCHEME_TYPES.EVERY.value) {
      return LABELS.CONDITION_OF_APPROVAL.EVERY;
    }

    return LABELS.CONDITION_OF_APPROVAL.ONLY_ONE;
  };

  const addApproverText = approversAndRolesLength ? LABELS.ANYONE_ELSE : LABELS.ADD_EMPLOYEE_OR_ROLE;

  const getCheckboxDescription = (type: number) => {
    if (type === APPROVAL_SCHEME_CONDITION_OF_APPROVAL_TYPES.ONLY_ONE_SEND_EVERY.value) {
      return field === APPROVAL_TYPES.TRIP ? LABELS.SEND_TO.EVERY : LABELS.SEND_TO.EVERY_REPORT;
    }

    return LABELS.SEND_TO.CHOSEN;
  };

  const headerContent = approversAndRolesLength > 1 && (
    <Text
      className={ styles.header }
      type='NORMAL_14'
    >
      { getRadioButtonsHeaderText(ConditionOfApproval) }
    </Text>
  );

  const checkboxContent = (ConditionOfApproval === APPROVAL_SCHEME_CONDITION_OF_APPROVAL_TYPES.ONLY_ONE_SEND_EVERY.value ||
    ConditionOfApproval === APPROVAL_SCHEME_CONDITION_OF_APPROVAL_TYPES.ONLY_ONE_SEND_CHOSEN.value) && (
    <div className={ styles.checkbox }>
      <Checkbox
        onChange={ v => {
          const payload = v ?
            APPROVAL_SCHEME_CONDITION_OF_APPROVAL_TYPES.ONLY_ONE_SEND_EVERY.value :
            APPROVAL_SCHEME_CONDITION_OF_APPROVAL_TYPES.ONLY_ONE_SEND_CHOSEN.value;

          handleOnChange({ [APPROVAL_SCHEME_STEP_FIELDS.CONDITION_OF_APPROVAL]: payload });
        } }
        value={ ConditionOfApproval === APPROVAL_SCHEME_CONDITION_OF_APPROVAL_TYPES.ONLY_ONE_SEND_EVERY.value }
      >
        <Text type='NORMAL_14'>
          { APPROVAL_SCHEME_CONDITION_OF_APPROVAL_TYPES.ONLY_ONE_SEND_EVERY.label }
        </Text>
      </Checkbox>
      <Text
        type='NORMAL_14_130'
        color='gray'
        className={ styles.description }
      >
        { getCheckboxDescription(ConditionOfApproval) }
      </Text>
    </div>
  );

  return (
    <div className={ styles.approvers }>
      { headerContent }
      { Roles.map(i => (
        <DraggableItem
          key={ i }
          onClick={ () => handleOnRemoveApprover(i, APPROVAL_STEP_DRAGGABLE_ITEM_TYPES.ROLE) }
          onDragStart={ () =>
            onDragRemovableApprover(() => handleOnRemoveApprover(i, APPROVAL_STEP_DRAGGABLE_ITEM_TYPES.ROLE)) }
          onDragEnd={ onDragEndRemovableApprover }
          isRed
          text={ rolesHash[i].Name }
          isStyle
        />))
      }
      { Approvers.map(i => (
        <DraggableItem
          key={ i }
          onClick={ () => handleOnRemoveApprover(i, APPROVAL_STEP_DRAGGABLE_ITEM_TYPES.EMPLOYEE) }
          onDragStart={ () =>
            onDragRemovableApprover(() => handleOnRemoveApprover(i, APPROVAL_STEP_DRAGGABLE_ITEM_TYPES.EMPLOYEE)) }
          onDragEnd={ onDragEndRemovableApprover }
          renderContent={ () => renderEmployee(employeesHash[i]) }
          isStyle
        />))
      }
      { checkboxContent }
      <div
        className={ styles['another-approver'] }
        onClick={ onClickAddApprover }
        data-qa={ qaAttr }
      >
        <Text color='gray'>
          { addApproverText }
        </Text>
      </div>
    </div>
  );
});

export { ApproverComponents };
