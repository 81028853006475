import React from 'react';
import clsx from 'clsx';
import { Text, Tooltip } from 'new-ui';

import { TootipPositionType } from 'new-ui/dist/src/components/Tooltip/types';

import styles from './styles.module.css';

interface IOptionButtonProps {
  title: string,
  position: TootipPositionType,
  disabled: boolean,
  subtitle: string | React.JSX.Element,
  isCurrent: boolean,
  isProcessing: boolean,
  tooltipContent: () => React.ReactNode,
  onOptionButtonClick: () => void,
}

const OptionButton = ({
  title,
  position,
  disabled,
  subtitle,
  isCurrent,
  isProcessing,
  tooltipContent,
  onOptionButtonClick,
}: IOptionButtonProps) => (
  <Tooltip
    show={ disabled && !isProcessing }
    position={ position }
    renderContent={ tooltipContent }
    className={ clsx(styles.wrapper, {
      [styles.current]: isCurrent,
      [styles.disabled]: disabled,
    }) }
    childrenClassName={ styles.children_tooltip }
  >
    <div
      className={ clsx(styles.content, {
        [styles.disabled]: disabled || isProcessing,
      }) }
      onClick={ () => !disabled && !isProcessing && onOptionButtonClick() }
    >
      <div className={ styles.text_wrapper }>
        <Text type='NORMAL_14' className={ styles.title }>{ title }</Text>
        <div className={ styles.subtitle }>
          { subtitle }
        </div>
      </div>
    </div>
  </Tooltip>
);

export default OptionButton;
