import { action, computed, makeObservable, observable } from 'mobx';

import mapSavedTicketToTrainItemModel from '../utils/mapSavedTicketToTrainItemModel';

import { ISavedTicket, SavedTicket } from '../../../types/trains';

export type TTrainSavedTicketStore = typeof TrainSavedTicketStore;

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable savedTicket: ISavedTicket | null = null;
  @observable isChoosingBackTrain: boolean = false;

  @computed
  get mappedSavedTicket(): SavedTicket | null {
    return this.savedTicket
    // @ts-ignore
      ? mapSavedTicketToTrainItemModel(this.savedTicket)
      : null;
  }

  @action
  setIsChoosingBackTrain = (value: boolean) => {
    this.isChoosingBackTrain = value;
  };

  @action
  setSavedTicket = (value: null | ISavedTicket) => {
    this.savedTicket = value;
  };

  @action
  clearStore = () => {
    this.savedTicket = null;
    this.isChoosingBackTrain = false;
  };
}

const TrainSavedTicketStore = new Store();

export { TrainSavedTicketStore };
