import React, { useState, useEffect, useRef } from 'react';
import { Moment } from 'moment';
import {
  Button,
  IconButton,
  StyledWrapper,
  Text,
} from 'new-ui';

import { getText } from '../../../../../i18n';

import { AirlineSearchRoute } from '../../../Menu/AirlineSearchMenu/components/AirlineSearchRoute';

import {
  defaultFieldSearchChange,
  getCurrentSearchRoute,
  updatedFieldSearchChangeRoutesInfo,
  updatedFieldSearchChangeRoutesSearch,
} from '../../../../bi/utils/changeAirTrip';

import DIRECTIONS from '../../../../bi/constants/directions';
import { FIELDS_CHANGE, PATH } from '../../../../bi/constants/changeAirTrip';
import { HISTORY_ACTIONS } from '../../../../bi/constants/history';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import { SearchMenuDirectionProps } from './types';
import { AirSearchRoute } from '../../../../bi/types/airline';
import { SelectedInterface } from '../../../../bi/utils/changeAirTrip/types';

import styles from './styles/index.module.css';

const LABELS = {
  PICK_UP_OPTIONS: getText('components:changeAirTrip.pickUpOptions'),
  ADD_FLIGHT: getText('components:menu.air.main.addFlight'),
};

const {
  changeTrip: {
    air: {
      searchMenu: {
        panelDirection,
        addRoute,
        searchButton,
      },
    },
  },
} = QA_ATTRIBUTES;

const SearchMenuDirection = ({
  airlineService,
  history,
  path,
  ticketData,
  routesInfo,
  routesSearch,
  useRoutesInfo,
  onSearch,
}: SearchMenuDirectionProps) => {
  const [searchRoutes, setSearchRoutes] = useState<AirSearchRoute[]>([]);
  const [valid, setValid] = useState<boolean | null>(null);

  const currentSearchRouteRef = useRef(ticketData && getCurrentSearchRoute(ticketData.Routes, ticketData.Fare?.IsTicketChangeableAfterDeparture));

  const updateAirlineState = ({ routes, isValid }: { routes: AirSearchRoute[]; isValid: boolean }) => {
    setSearchRoutes(() => [...routes]);
    setValid(isValid);
  };

  const updateSearchValue = (index: number, dataDirectionFrom: SelectedInterface, dataDirectionTo: SelectedInterface, departureDateMoment: Moment) => {
    airlineService.setSearchValue(FIELDS_CHANGE.SUGGEST_SELECT, { field: DIRECTIONS.FROM, key: index, value: dataDirectionFrom });
    airlineService.setSearchValue(FIELDS_CHANGE.SUGGEST_SELECT, { field: DIRECTIONS.TO, key: index, value: dataDirectionTo });
    airlineService.setSearchValue(FIELDS_CHANGE.DATE, { key: index, value: departureDateMoment });
  };

  const handleDefaultFieldSearch = () => currentSearchRouteRef.current.forEach((route, index) => {
    const [dataDirectionFrom, dataDirectionTo, departureDateMoment] = defaultFieldSearchChange(route);
    updateSearchValue(index, dataDirectionFrom, dataDirectionTo, departureDateMoment);
  });

  const handleUpdateFieldSearch = () => {
    if (useRoutesInfo) {
      return routesInfo?.forEach((route, index) => {
        const [dataDirectionFrom, dataDirectionTo, departureDateMoment] = updatedFieldSearchChangeRoutesInfo(route);
        updateSearchValue(index, dataDirectionFrom, dataDirectionTo, departureDateMoment);
      });
    }

    return routesSearch?.forEach((route, index) => {
      const [dataDirectionFrom, dataDirectionTo, departureDateMoment] = updatedFieldSearchChangeRoutesSearch(route);
      updateSearchValue(index, dataDirectionFrom, dataDirectionTo, departureDateMoment);
    });
  };

  useEffect(() => {
    const unsubscribeAirlineFn = airlineService.subscribeSearch(updateAirlineState);

    if (path === PATH.DIRECTION) {
      airlineService.setNewSearch();
      airlineService.setSearchValue(FIELDS_CHANGE.COMPLEX, true);
      currentSearchRouteRef.current.forEach((_, index) => index !== 0 && airlineService.addSearchRoute());
      handleDefaultFieldSearch();
    }

    if (path === PATH.DIRECTION_RESULT) {
      if (history.action === HISTORY_ACTIONS.POP) {
        airlineService.setSearchValue(FIELDS_CHANGE.COMPLEX, true);
        routesInfo?.forEach((_, index) => index !== 0 && airlineService.addSearchRoute());
      }

      handleUpdateFieldSearch();
    }

    return () => {
      unsubscribeAirlineFn();
    };
  }, []);

  const renderSearchRoutes = () => searchRoutes.map((route, index, routes) => (
    <AirlineSearchRoute
      key={ `airline__direction__row__${index}` }
      ind={ index }
      route={ route }
      isComplex
      airlineService={ airlineService }
      isChangeAirTrip
      numberOfRoutes={ routes.length }
      qaAttrs={ panelDirection }
    />
  ));

  const renderLabel = (label: string, style = '') => <Text type='NORMAL_14' className={ style }>{ label }</Text>;

  const renderAddButtonSearchRoute = () => (
    <div className={ styles.add_button }>
      <IconButton
        qaAttr={ addRoute }
        iconType='plusRound'
        onClick={ () => airlineService.addSearchRoute() }
      >
        { renderLabel(LABELS.ADD_FLIGHT, styles.label_add) }
      </IconButton>
    </div>
  );

  return (
    <StyledWrapper className={ styles.wrapper }>
      <div className={ `${styles.row_route} ${styles.complex}` }>
        { renderSearchRoutes() }
        <div className={ styles.action }>
          { renderAddButtonSearchRoute() }
        </div>
      </div>
      <div className={ styles.search }>
        <Button
          qaAttr={ searchButton }
          type='secondary'
          className={ styles.search_button }
          disabled={ !valid }
          onClick={ onSearch }
        >
          <Text type='NORMAL_18' color='white'>
            { LABELS.PICK_UP_OPTIONS }
          </Text>
        </Button>
      </div>
    </StyledWrapper>
  );
};

export { SearchMenuDirection };
