import React from 'react';
import { Text } from 'new-ui';

import { getText } from '../../../i18n';

import arrayWithSeparators from '../../bi/utils/arrayWithSeparators';
import guid from '../../bi/utils/guid';
import preparedFares, { hasRefundWithConditions } from '../../bi/utils/fares';

import { CHANGETICKET, REFUNDTICKET, VALUETICKET } from '../../bi/constants/airline';

import { AirlineFare } from '../../bi/types/airline';

import styles from './styles/index.module.css';

const CARRYONPLACESDEFAULTVALUE = 'включена';

const LABELS = {
  includes: getText('components:fareDetails.includes'),
  included: getText('components:fareDetails.included'),
  notIncluded: getText('components:fareDetails.notIncluded'),
  includedWithPlaces: (places: string) => getText('components:fareDetails.includedWithPlaces', { places }),
  charge: getText('components:fareDetails.charge'),
  notAvailable: getText('components:fareDetails.notAvailable'),
  withoutTaxes: getText('components:fareDetails.withoutTaxes'),
  noPenaltyCompany: getText('components:fareDetails.noPenaltyCompany'),
  withTax: getText('components:fareDetails.withTax'),
  nonReturnRate: getText('components:fareDetails.nonReturnRate'),
  restricted: getText('components:fareDetails.restricted'),
  restrict: getText('components:fareDetails.restrict'),
  allowed: getText('components:fareDetails.allowed'),
  fare: getText('components:fareDetails.fare'),
  choosingSeatsIncluded: getText('components:fareDetails.choosingSeatsIncluded'),
  choosingSeatsPaid: getText('components:fareDetails.choosingSeatsPaid'),
  businessHallIncluded: getText('components:fareDetails.businessHallIncluded'),
  businessHallNotIncluded: getText('components:fareDetails.businessHallNotIncluded'),
  upgradeRate: getText('components:fareDetails.upgradeRate'),
  refund: getText('components:fareDetails.refund'),
  change: getText('components:fareDetails.change'),
  refundAfterDeparture: getText('components:fareDetails.refundAfterDeparture'),
  changeAfterDeparture: getText('components:fareDetails.changeAfterDeparture'),
  conditionsOf: getText('components:fareDetails.conditionsOf'),
  baggage: getText('components:fareDetails.baggage'),
  carryOn: getText('components:fareDetails.carryOn'),
  refundBeforeDeparture: getText('components:fareDetails.refundBeforeDeparture'),
  changeBeforeDeparture: getText('components:fareDetails.changeBeforeDeparture'),
};

const prepareBaggage = (value: string, places: string) => {
  switch (value) {
    case VALUETICKET.INCLUDED:
      return places ? LABELS.includedWithPlaces(places) : LABELS.included;
    case VALUETICKET.NOTDEFINED:
      return '';
    default:
      return LABELS.notIncluded;
  }
};

const prepareCarryOn = (value: string, places: string) => {
  switch (value) {
    case VALUETICKET.INCLUDED:
      return places || CARRYONPLACESDEFAULTVALUE;
    case VALUETICKET.CHARGE:
      return LABELS.charge;
    default:
      return '';
  }
};

const prepareRefundable = (value: string) => {
  switch (value) {
    case VALUETICKET.NOTOFFERED:
      return LABELS.notAvailable;
    case VALUETICKET.INCLUDED:
      return LABELS.withoutTaxes;
    case VALUETICKET.CHARGE:
      return LABELS.withTax;
    case VALUETICKET.NOTDEFINED:
      // return 'уточните у Travel помощника возможность возврата';
      return LABELS.nonReturnRate;
    case VALUETICKET.NOTPENALTY:
      return LABELS.noPenaltyCompany;
    default:
      return '';
  }
};

const prepareChangeable = (value: string) => {
  switch (value) {
    case VALUETICKET.NOTOFFERED:
      return LABELS.restricted;
    case VALUETICKET.INCLUDED:
      return LABELS.withoutTaxes;
    case VALUETICKET.CHARGE:
      return LABELS.withTax;
    case VALUETICKET.NOTPENALTY:
      return LABELS.noPenaltyCompany;
    default:
      return '';
  }
};

const prepareRefundableAfterDeparture = (value: string) => {
  switch (value) {
    case VALUETICKET.INCLUDED:
      return LABELS.allowed;
    case VALUETICKET.NOTOFFERED:
      return LABELS.restrict;
    case VALUETICKET.NOTPENALTY:
      return LABELS.noPenaltyCompany;
    case VALUETICKET.CHARGE:
      return LABELS.withTax;
    default:
      return '';
  }
};

const prepareChangeableAfterDeparture = (value: string) => {
  switch (value) {
    case VALUETICKET.INCLUDED:
      return LABELS.withoutTaxes;
    case VALUETICKET.CHARGE:
      return LABELS.withTax;
    case VALUETICKET.NOTOFFERED:
      return LABELS.restricted;
    case VALUETICKET.NOTPENALTY:
      return LABELS.noPenaltyCompany;
    default:
      return '';
  }
};

const renderItem = (label: string, value: string) => {
  if (value) {
    return (
      <li className={ styles.item }>
        <b>{ label }:&nbsp;</b>
        { value }
      </li>
    );
  }

  return null;
};

interface FareDetailsProps {
  fare: AirlineFare,
  title?: boolean,
  className?: string | null,
  separateSymbol?: {
    type?: string,
    value?: string,
  } | null,
  asItem?: boolean,
  customTitle?: string,
  isCart?: boolean,
  customRefundabilityAndExchangeability?: boolean,
  showTicketBullits?: boolean,
}

class FareDetails extends React.Component<FareDetailsProps> {
  static defaultProps = {
    title: false,
    className: null,
    separateSymbol: null,
    asItem: false,
    customTitle: '',
    isCart: false,
    customRefundabilityAndExchangeability: false,
    showTicketBullits: false,
  };

  renderCartView = () => {
    const { fare, title, separateSymbol, asItem, customTitle, customRefundabilityAndExchangeability } = this.props;
    const {
      Name,
      IsBaggageIncluded,
      BaggagePlaces,
      IsCarryonIncluded,
      CarryonPlaces,
      IsTicketRefundable,
      IsTicketChangeable,
      IsTicketRefundableAfterDeparture,
      IsTicketChangeableAfterDeparture,
      CanRegistrationSeat,
      IsBusinessHallIncluded,
      CanUpgradeRate,
      AirlineBonusInformation,
      RefundTicketWithCondition,
      ChangeTicketWithCondition,
      SeatDescription,
      Note,
    } = fare;
    let details = [];

    const seatDesc = SeatDescription ? `(${SeatDescription})` : '';

    if (CanRegistrationSeat === VALUETICKET.INCLUDED) {
      details.push(`${LABELS.choosingSeatsIncluded} ${seatDesc}`);
    }

    if (CanRegistrationSeat === VALUETICKET.CHARGE) {
      details.push(`${LABELS.choosingSeatsPaid} ${seatDesc}`);
    }

    if (IsBusinessHallIncluded === VALUETICKET.INCLUDED) {
      details.push(LABELS.businessHallIncluded);
    }

    if (IsBusinessHallIncluded === VALUETICKET.NOTOFFERED) {
      details.push(LABELS.businessHallNotIncluded);
    }

    if (CanUpgradeRate === VALUETICKET.INCLUDED) {
      details.push(LABELS.upgradeRate);
    }

    if (AirlineBonusInformation) {
      details.push(AirlineBonusInformation);
    }

    if (RefundTicketWithCondition && !customRefundabilityAndExchangeability) {
      Object.keys(RefundTicketWithCondition).forEach((field) => {
        if (REFUNDTICKET[RefundTicketWithCondition[field]]) {
          details.push(`${LABELS.refund} ${field}: ${REFUNDTICKET[RefundTicketWithCondition[field]]}`);
        }
      });
    }

    if (ChangeTicketWithCondition && !customRefundabilityAndExchangeability) {
      Object.keys(ChangeTicketWithCondition).forEach((field) => {
        if (CHANGETICKET[ChangeTicketWithCondition[field]]) {
          details.push(`${LABELS.change} ${field}: ${CHANGETICKET[ChangeTicketWithCondition[field]]}`);
        }
      });
    }

    const baggageValue = prepareBaggage(IsBaggageIncluded, BaggagePlaces);
    const carryOnValue = prepareCarryOn(IsCarryonIncluded, CarryonPlaces);
    const refundableValue = prepareRefundable(IsTicketRefundable);
    const refundableAfterDeparture = prepareRefundableAfterDeparture(IsTicketRefundableAfterDeparture);
    const changeableValue = prepareChangeable(IsTicketChangeable);
    const changeableAfterDeparture = prepareChangeableAfterDeparture(IsTicketChangeableAfterDeparture);
    const fareNameValue = customTitle || (() => (title ? Name : ''))();

    if (refundableAfterDeparture && !customRefundabilityAndExchangeability) {
      details.push(`${LABELS.refundAfterDeparture} ${refundableAfterDeparture}`);
    }

    if (changeableAfterDeparture && !customRefundabilityAndExchangeability) {
      details.push(`${LABELS.changeAfterDeparture} ${changeableAfterDeparture}`);
    }

    if (customRefundabilityAndExchangeability) {
      details.push(LABELS.conditionsOf);
    }

    if (separateSymbol) {
      details = arrayWithSeparators(details, separateSymbol.value);
    }

    if (Note) {
      details.push(Note);
    }

    const fareStyles = [styles.fare];

    if (asItem) {
      fareStyles.push(styles.fare_as_item);
    }

    const detailsContent = details.map((detail, index) => (
      <div
        key={ `fare_tooltip_${guid()}_${index}` }
        className={ `${fareStyles.join(' ')}` }
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={ { __html: detail } }
      />
    ));

    const nameContent = renderItem(LABELS.fare, fareNameValue);
    const baggageContent = renderItem(LABELS.baggage, baggageValue);
    const carryOnContent = renderItem(LABELS.carryOn, carryOnValue);
    const refundableContent = !hasRefundWithConditions(RefundTicketWithCondition)
      && !customRefundabilityAndExchangeability
      && renderItem(LABELS.refundBeforeDeparture, refundableValue);
    const changeableContent = !customRefundabilityAndExchangeability
      && renderItem(LABELS.changeBeforeDeparture, changeableValue);

    return (
      <div className={ `${styles.wrap} ${styles['cart-view']}` }>
        <ul>
          { nameContent }
          { baggageContent }
          { carryOnContent }
          { refundableContent }
          { changeableContent }
        </ul>
        <div className={ styles.details }>
          { detailsContent }
        </div>
      </div>
    );
  };

  renderDefault = () => {
    const { fare, asItem, title, className, customTitle, separateSymbol, customRefundabilityAndExchangeability } = this.props;

    let details = preparedFares(fare, customRefundabilityAndExchangeability);
    const wrapperStyles = [styles.wrapper];

    if (className) {
      wrapperStyles.push(className);
    }

    const titleHtml = fare.Name && title && (
      <Text type='SEMIBOLD_14' className={ styles.title }>
        {LABELS.fare} &laquo;{ fare.Name }&raquo;:
      </Text>
    );
    const customTitleHtml = title && customTitle && <Text type='SEMIBOLD_14'>{ customTitle }</Text>;

    if (separateSymbol) {
      details = arrayWithSeparators(details, separateSymbol.value);
    }

    const fareStyles: string[] = [];

    if (title) {
      fareStyles.push(styles.item);
    }

    if (asItem) {
      fareStyles.push(styles.fare_as_item);
    }

    const fareHtml = details.map((detail, index) => (
      <div
        key={ `fare_tooltip_${guid()}_${index}` }
        className={ fareStyles.join(' ') }
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={ { __html: detail } }
      />
    ));

    return (
      <div className={ wrapperStyles.join(' ') }>
        { customTitle ? customTitleHtml : titleHtml }
        { fareHtml }
      </div>
    );
  };

  render() {
    const { isCart, showTicketBullits } = this.props;

    return isCart || showTicketBullits ? this.renderCartView() : this.renderDefault();
  }
}

export default FareDetails;
