import { getText } from '../../../../i18n';

import { SERVICETYPE } from '../../constants/serviceType';
import type { TravelPolicyRoute } from './types';

const COMMON_TRAVEL_POLICY_STORE_FIELDS = {
  NAME: 'name',
  DESCRIPTION: 'description',
};

const TRAVEL_POLICY_STORES_TYPES = {
  AIR: SERVICETYPE.AIR,
  TRAIN: SERVICETYPE.TRAIN,
  HOTEL: SERVICETYPE.HOTEL,
  TAXI: SERVICETYPE.TAXI,
  TRANSFER: SERVICETYPE.TRANSFER,
  VIP_HALL: SERVICETYPE.VIP_HALL,
  COMMON: 'COMMON',
};

const TRAVEL_POLICY_STORE_FIELDS = {
  APPLY: 'Apply',
  ONE_WAY: 'MaxPrice',
  NOT_GREATER_PRICE: 'NotGreaterPrice',
  DAYS_COUNT: 'Time',
  CLASS: 'Class',
  TARIFF: 'Tariff',
  DIRECTIONS: 'Directions',
  EXCEPTIONS: 'Exceptions',
  SAPSAN_CLASS: 'SapsanClass',
  REFUNDABLE: 'Refundable',
  VAT: 'Vat',
  DAY_OFF: 'DayOff',
  INCLUDED_BAGGAGE: 'IncludedBaggage',
  PROHIBIT_BOOK: 'prohibitBook',
  ALLOW_BOOK: 'AllowBook',
  CLASSES: undefined,
} as const;

export type TravelPolicyStoreFieldsType = typeof TRAVEL_POLICY_STORE_FIELDS;

const TRAVEL_POLICY_CURRENCIES = {
  RUB: 0,
  USD: 1,
  EUR: 2,
  GBP: 3,
};

const TRAVEL_POLICY_CURRENCIES_LABELS = {
  [TRAVEL_POLICY_CURRENCIES.RUB]: getText('services:travelPolicy.const.currencies.rub'),
  [TRAVEL_POLICY_CURRENCIES.USD]: getText('services:travelPolicy.const.currencies.usd'),
  [TRAVEL_POLICY_CURRENCIES.EUR]: getText('services:travelPolicy.const.currencies.eur'),
  [TRAVEL_POLICY_CURRENCIES.GBP]: getText('services:travelPolicy.const.currencies.gbp'),
};

const TRAVEL_POLICY_FIELDS_DICTIONARIES = {
  NOT_GREATER_PRICE_TYPE: {
    RUBLES: 0,
    PERCENTS: 1,
  },
  CLASSES: {
    ECONOM: 0,
    ECONOM_OR_BUSINESS: 1,
    RESERVED_SEATS: 2,
  },
  TAXI_CLASSES: {
    ECONOMY: 1,
    COMFORT: 2,
    COMFORT_PLUS: 3,
    BUSINESS: 4,
    CHILDREN: 5,
    MINIVAN: 6,
    CRUISE: 7,
    PREMIER: 8,
    LITE: 9,
    STANDART: 10,
    OPTIMUM: 11,
  },
  TRANSFER_CLASSES: {
    STANDARD: 1,
    TOLL_ROAD_STANDARD: 2,
    COMFORT: 3,
    TOLL_ROAD_COMFORT: 4,
    BUSINESS_LITE: 5,
    BUSINESS: 6,
    TOLL_ROAD_BUSINESS: 7,
    MINIVAN: 8,
    MINIVAN_VIP: 9,
    MINIBUS: 10,
    MINIBUS_VIP: 11,
    EXECUTIVE: 12,
  },
  DIRECTION_TYPES: {
    RUSSIA: 1,
    CHOSEN: 0,
  },
  EXCEPTION_TYPES: {
    HOURS: 0,
    ROUTES: 1,
    COUNTRIES: 2,
  },
};

const TRAVEL_POLICY_DEFAULT_PRICE = 5000;

const TRAVEL_POLICY_DEFAULT_ROUTE: TravelPolicyRoute = {
  From: {
    Name: '',
    isSelected: false,
    Code: '',
    showSuggests: false,
  },
  To: {
    Name: '',
    isSelected: false,
    Code: '',
    showSuggests: false,
  },
  fullName: '',
  isValid: false,
};

const TRAVEL_POLICY_GENERAL_FIELDS_TO_BE_RENDERED = {
  [SERVICETYPE.AIR]: [
    TRAVEL_POLICY_STORE_FIELDS.ONE_WAY,
    TRAVEL_POLICY_STORE_FIELDS.NOT_GREATER_PRICE,
    TRAVEL_POLICY_STORE_FIELDS.DAYS_COUNT,
    TRAVEL_POLICY_STORE_FIELDS.CLASS,
    TRAVEL_POLICY_STORE_FIELDS.REFUNDABLE,
    TRAVEL_POLICY_STORE_FIELDS.DAY_OFF,
    TRAVEL_POLICY_STORE_FIELDS.INCLUDED_BAGGAGE,
  ],
  [SERVICETYPE.HOTEL]: [
    TRAVEL_POLICY_STORE_FIELDS.ONE_WAY,
    // TRAVEL_POLICY_STORE_FIELDS.NOT_GREATER_PRICE,
    TRAVEL_POLICY_STORE_FIELDS.REFUNDABLE,
    TRAVEL_POLICY_STORE_FIELDS.VAT,
    TRAVEL_POLICY_STORE_FIELDS.DAY_OFF,
  ],
  [SERVICETYPE.TRAIN]: [
    TRAVEL_POLICY_STORE_FIELDS.ONE_WAY,
    TRAVEL_POLICY_STORE_FIELDS.NOT_GREATER_PRICE,
    TRAVEL_POLICY_STORE_FIELDS.CLASS,
    TRAVEL_POLICY_STORE_FIELDS.DAY_OFF,
    TRAVEL_POLICY_STORE_FIELDS.SAPSAN_CLASS,
  ],
  [SERVICETYPE.TAXI]: [
    TRAVEL_POLICY_STORE_FIELDS.PROHIBIT_BOOK,
    TRAVEL_POLICY_STORE_FIELDS.CLASS,
    TRAVEL_POLICY_STORE_FIELDS.DAY_OFF,
  ],
  [SERVICETYPE.TRANSFER]: [
    TRAVEL_POLICY_STORE_FIELDS.ONE_WAY,
    TRAVEL_POLICY_STORE_FIELDS.CLASS,
    TRAVEL_POLICY_STORE_FIELDS.DAY_OFF,
  ],
  [SERVICETYPE.VIP_HALL]: [
    TRAVEL_POLICY_STORE_FIELDS.ALLOW_BOOK,
  ],
};

const DEFAULT_GENERAL_RULES = {
  [TRAVEL_POLICY_STORE_FIELDS.ONE_WAY]: {
    IsEnabled: false,
    Value: TRAVEL_POLICY_DEFAULT_PRICE,
  },
  [TRAVEL_POLICY_STORE_FIELDS.CLASS]: {
    IsEnabled: false,
    Value: TRAVEL_POLICY_FIELDS_DICTIONARIES.TRANSFER_CLASSES.STANDARD,
  },
  [TRAVEL_POLICY_STORE_FIELDS.DAY_OFF]: {
    IsEnabled: false,
  },
};

const DEFAULT_STORE = {
  [TRAVEL_POLICY_STORE_FIELDS.APPLY]: false,
  ...DEFAULT_GENERAL_RULES,
};

const TEXTS_FOR_UNIQUE_TRAVEL_POLICY = {
  HEADER: getText('settings:travelPolicy.policyUnique.policyUniqueHeader.header'),
  SUB_TEXT_ONE: getText('settings:travelPolicy.policyUnique.policyUniqueHeader.sub.textOne'),
  SUB_TEXT_TWO: getText('settings:travelPolicy.policyUnique.policyUniqueHeader.sub.textTwo'),
};

export {
  COMMON_TRAVEL_POLICY_STORE_FIELDS,
  TRAVEL_POLICY_STORES_TYPES,
  TRAVEL_POLICY_STORE_FIELDS,
  TRAVEL_POLICY_FIELDS_DICTIONARIES,
  TRAVEL_POLICY_DEFAULT_PRICE,
  TRAVEL_POLICY_DEFAULT_ROUTE,
  TRAVEL_POLICY_GENERAL_FIELDS_TO_BE_RENDERED,
  TRAVEL_POLICY_CURRENCIES,
  TRAVEL_POLICY_CURRENCIES_LABELS,
  DEFAULT_GENERAL_RULES,
  DEFAULT_STORE,
  TEXTS_FOR_UNIQUE_TRAVEL_POLICY,
};
