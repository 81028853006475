import React from 'react';
import { Button, Dialog, Text } from 'new-ui';

import { getText } from '../../../i18n';

import AirlineItem from '../AirlineItem';
import HotelItem from '../HotelItem';
import TransferItem from '../TransferItem';
import TrainItem from '../TrainItem';
import AeroexpressItem from '../AeroexpressItem';

import { SERVICETYPE } from '../../bi/constants/serviceType';

import {
  BOOKINGSTEP,
  EBookingItemStatusChanges,
  IBookingItem,
} from '../../bi/types/booking';
import { AirRoute } from '../../bi/types/airline';

import styles from '../../page/Booking/index.module.css';

const LABELS = {
  TICKETSNOTAVAILABLE: getText('booking:ticketsNotAvailable'),
  BACKTOCART: getText('booking:backToCart'),
  COMMON_ERROR_DIALOG: {
    TITLE: getText('booking:commonErrorDialog.title'),
    TO_TP_HELPER: getText('booking:commonErrorDialog.toTpHelper'),
  },
  BOOKING_ERROR_DIALOG: {
    TITLE: getText('booking:bookingErrorDialog.title'),
  },
};

interface IBookingErrorDialogProps {
  errorMessages: string[],
  errors: { CartItem: IBookingItem, Message: string }[],
  isCancelling: boolean,
  showErrorDialog: boolean,
  statuses: any[],
  renderCartItem?: (CartItem: IBookingItem) => React.ReactNode,
  onClickErrorDialog(): void,
  onSendRequest(): void,
  ShowTravelHelperButton: boolean,
}

const BookingErrorDialog = ({
  errorMessages,
  errors,
  isCancelling,
  showErrorDialog,
  statuses,
  onClickErrorDialog,
  renderCartItem,
  onSendRequest,
  ShowTravelHelperButton,
}: IBookingErrorDialogProps) => {
  if (isCancelling) {
    return null;
  }

  const renderCartItemComponent = ({
    Data,
    ServiceType,
    bookingStatus: { Changes },
  }: IBookingItem): React.ReactNode => {
    const itemData = JSON.parse(Data);
    const { Metadata, Routes } = itemData;

    switch (ServiceType) {
      case SERVICETYPE.AIR:
        return (
          <ul className={ styles['reset-ul'] }>
            {Routes.map((route: AirRoute) => (
              <AirlineItem
                key={ route.ID }
                route={ route }
                meta={ Metadata }
              />
            ))}
          </ul>
        );

      case SERVICETYPE.HOTEL:
        return (
          <HotelItem
            soldOut={ Changes === EBookingItemStatusChanges.SoldOut }
            item={ itemData }
          />
        );

      case SERVICETYPE.TRANSFER:
        return <TransferItem item={ itemData } />;

      case SERVICETYPE.TRAIN:
        return <TrainItem item={ itemData } />;

      case SERVICETYPE.AEROEXPRESS:
        return <AeroexpressItem item={ itemData } />;
    }

    return null;
  };

  const renderCommonErrorDialog = () => {
    const errorMessagesHtml = () => errorMessages.map((message, index) =>
      <div key={ index } className={ styles.error }>
        <Text className={ styles.message } type='NORMAL_14' color='red'>
          { message }
        </Text>
      </div>,
    );

    const errorsHtml = () => errors.map(({ CartItem, Message }, index) => {
      const renderItem = renderCartItem || renderCartItemComponent;

      return (
        <div key={ index } className={ styles.error }>
          { CartItem && (renderItem(CartItem)) }
          <Text className={ styles.message } type='NORMAL_14' color='red'>
            { Message }
          </Text>
        </div>
      );
    });

    const renderErorrs = () => (errorMessages.length ? errorMessagesHtml() : errorsHtml());
    const renderSendToTpHelperButton = ShowTravelHelperButton && (
      <Button onClick={ onSendRequest } type='textual' className={ styles.secondary }>
        { LABELS.COMMON_ERROR_DIALOG.TO_TP_HELPER }
      </Button>
    );

    return (
      <Dialog
        show={ showErrorDialog }
        onChange={ onClickErrorDialog }
      >
        <div className={ styles.dialog }>
          <Text type='bold_20' className={ styles.title }>
            {LABELS.COMMON_ERROR_DIALOG.TITLE}
          </Text>
          { renderErorrs() }
          <div className={ styles.actions }>
            <Button onClick={ onClickErrorDialog } type='primary'>
              { LABELS.BACKTOCART }
            </Button>
            { renderSendToTpHelperButton }
          </div>
        </div>
      </Dialog>
    );
  };

  const renderBookingErrorDialog = () => {
    const withError = statuses.filter(
      ({ BookingStep, Changes }) =>
        BookingStep === BOOKINGSTEP.BookingError ||
        BookingStep === BOOKINGSTEP.ReservationError ||
        Changes === EBookingItemStatusChanges.NotEnoughPlaces,
    );

    return (
      <Dialog
        show={ showErrorDialog }
        onChange={ onClickErrorDialog }
      >
        <div className={ styles.dialog }>
          <Text type='bold_20' className={ styles.title }>{LABELS.BOOKING_ERROR_DIALOG.TITLE}</Text>
          {withError.map(({ Error }, id) => (
            <Text type='NORMAL_14_130' key={ id }>
              { Error || LABELS.TICKETSNOTAVAILABLE }
            </Text>
          ))}
          <div className={ styles.actions }>
            <Button
              type='primary'
              onClick={ onClickErrorDialog }
              loading={ isCancelling }
            >
              { LABELS.BACKTOCART }
            </Button>
          </div>
        </div>
      </Dialog>
    );
  };

  return errorMessages?.length || errors?.length > 0
    ? renderCommonErrorDialog()
    : renderBookingErrorDialog();
};

export { BookingErrorDialog };
