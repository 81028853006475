import React, { useState, DragEvent } from 'react';
import { observer } from 'mobx-react';
import { Icon, LinkButton, Text } from 'new-ui';

import { getText } from '../../../../i18n';

import { ConditionalDialog } from '../components/conditionalOfApprovers/ConditionalDialog';
import { ApproverComponents } from '../components/approvers/Approvers';
import { EmployeeCloneItem } from '../../EmployeeCloneItem';

import { getApproversAndRolesLength } from '../../../bi/utils/approval';

import { APPROVAL_SCHEME_STEP_FIELDS, APPROVAL_TYPES } from '../../../bi/constants/approvalSchemes';
import { QA_ATTRIBUTES } from '../../../bi/constants/attributesForTests';

import { ApprovalSchemePreStep, IEmployees, IRoles } from '../../../bi/services/approvalSchemes/types';

import styles from './styles.module.css';

const LABELS = {
  STEP_COUNT: (step: number) => getText('approvalScheme:step.stepCount', { step }),
  REMOVE_STEP: getText('approvalScheme:step.removeStep'),
  DROP_CART: getText('approvalScheme:step.dropCart'),
};

interface IApprovalSchemeStepProps {
  ind: number;
  value: ApprovalSchemePreStep;
  draggableItem: {
    id: number | null | string,
    type: string,
  }
  employees: IEmployees;
  roles: IRoles;
  field: string;
  renderEmployee(e: any): React.ReactElement<typeof EmployeeCloneItem>;
  onRemove(): void;
  onChange(value: any): void;
  onClickAddApprover(): void;
  onDragRemovableApprover(cb: any): void;
  onDragEndRemovableApprover(): void;
}

const ApprovalSchemeStep = observer(({
  ind,
  value,
  draggableItem,
  employees,
  roles,
  field = APPROVAL_TYPES.TRIP,
  renderEmployee,
  onRemove,
  onChange,
  onClickAddApprover,
  onDragRemovableApprover,
  onDragEndRemovableApprover,
}: IApprovalSchemeStepProps) => {
  const [canBeDropped, setCanBeDropped] = useState(false);
  const [dialog, setDialog] = useState({
    cb: () => {},
    show: false,
  });

  const { id: draggableItemId, type: draggableItemType } = draggableItem;
  const { Roles, Approvers } = value;

  const handleOnChange = (payload: any) => onChange({ ...value, ...payload });

  const handleDialogButtonClick = (v: number) => {
    const { cb } = dialog;

    // @ts-ignore
    handleOnChange({ [APPROVAL_SCHEME_STEP_FIELDS.CONDITION_OF_APPROVAL]: v, ...cb() });
  };

  const handleOnDrop = () => {
    setCanBeDropped(false);

    if (!draggableItemType || (value as Record<string, any>)[draggableItemType].includes(draggableItemId)) {
      return null;
    }

    const payload = { [draggableItemType]: Array.from(new Set((value as Record<string, any>)[draggableItemType]).add(draggableItemId)) };

    if (getApproversAndRolesLength(Roles, Approvers) === 1) {
      return setDialog({
        show: true,
        cb: () => {
          setDialog({
            cb: () => {},
            show: false,
          });
          setCanBeDropped(false);

          return payload;
        },
      });
    }

    return handleOnChange(payload);
  };

  const arrowContent = ind !== 1 && (
    <div className={ styles.arrow }>
      <Icon
        className={ styles.icon }
        color='gray'
        type='arrowsBack'
      />
    </div>
  );

  const renderRemoveButton = () => {
    if (ind === 1) {
      return null;
    }

    return (
      <LinkButton
        theme='blue-without-border'
        onClick={ onRemove }
      >
        { LABELS.REMOVE_STEP }
      </LinkButton>
    );
  };

  const canBeDroppedWithId = canBeDropped && draggableItemId !== null;

  const renderDroppable = () => (
    <div
      className={ styles['droppable-panel'] }
      onDragLeave={ () => setCanBeDropped(false) }
      onDragOver={ e => e.preventDefault() }
      onDrop={ handleOnDrop }
    >
      <Text type='normal_20'>
        { LABELS.DROP_CART }
      </Text>
    </div>
  );

  const droppablePanel = canBeDroppedWithId && renderDroppable();

  const wrapperClassNames = [styles.wrapper];

  if (canBeDroppedWithId) {
    wrapperClassNames.push(styles.droppable);
  }

  if (draggableItemId !== null) {
    wrapperClassNames.push(styles.highlighted);
  }

  const onDragProps = canBeDroppedWithId ? {} : {
    onDragEnter: () => setCanBeDropped(true),
    onDragOver: (e: DragEvent) => e.preventDefault(),
    onDrop: handleOnDrop,
  };

  const qaAttrStep = `${QA_ATTRIBUTES.approvalSchemes.trips.preTravel.moveToStep}-${ind}`;

  return (
    <div>
      { arrowContent }
      <div className={ wrapperClassNames.join(' ') } { ...onDragProps }>
        <div className={ styles.common }>
          <div className={ styles.header }>
            <Text type='NORMAL_18'>
              { LABELS.STEP_COUNT(ind) }
            </Text>
            { renderRemoveButton() }
          </div>
          <ApproverComponents
            employees={ employees }
            roles={ roles }
            value={ value }
            field={ field }
            renderEmployee={ renderEmployee }
            onChange={ onChange }
            onClickAddApprover={ onClickAddApprover }
            onDragRemovableApprover={ onDragRemovableApprover }
            onDragEndRemovableApprover={ onDragEndRemovableApprover }
            qaAttr={ qaAttrStep }
          />
        </div>
        { droppablePanel }
        <ConditionalDialog
          show={ dialog.show }
          onClick={ handleDialogButtonClick }
        />
      </div>
    </div>
  );
});

export { ApprovalSchemeStep };
