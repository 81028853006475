import { IconType } from 'new-ui/src/components/Icon/types';

import { getText } from '../../../i18n';

import ROUTES from './routes';
import { QA_ATTRIBUTES } from './attributesForTests';

import { IDefaultMenuItems } from '../types/app';

const DEFAULTMENUTYPE = {
  SMARTDESC: 'smartdesc',
  SEARCH: 'search',
  TAXI: 'taxi',
  SEARCHAIRLINE: 'searchAirline',
  SEARCHHOTEL: 'searchHotel',
  SEARCHTRANSFER: 'searchTransfer',
  SEARCHTRAIN: 'searchTrain',
  SEARCHAEROEXPRESS: 'searchAeroexpress',
  SEARCH_TAXI: 'searchTaxi',
  SEARCHVIP: 'searchVip',
  SEARCH_CONFERENCE: 'searchConference',
  SEARCH_GROUP: 'searchGroup',
  SEARCH_EVENT: 'searchEvent',
  TRIPS: 'trips',
  REPORT: 'report',
  SETTINGS: 'settings',
  CART: 'cart',
  WAITINGAPPROVE: 'waitingApprove',
  REQUESTS: 'requests',
  REQUEST_1C: 'request_1C',
  REQUEST_APPLICATION_SCHEME: 'requestApplicationScheme',
  EXPENSE_REPORTS: 'expenseReports',
  CHARTS_ANALYTICS: 'chartsAnalytics',
  TRAVELERS: 'travelers',
  ACCOUNTING: 'accounting',
  APPLICATION: 'application',
  EVENT: 'event',
  COORDINATION: 'coordination',
}; // скорее всего удалится, когда все на enum переедет

export enum DEFAULT_MENU_TYPE {
  SMARTDESC = 'smartdesc',
  SEARCH = 'search',
  TAXI = 'taxi',
  SEARCHAIRLINE = 'searchAirline',
  SEARCHHOTEL = 'searchHotel',
  SEARCHTRANSFER = 'searchTransfer',
  SEARCHTRAIN = 'searchTrain',
  SEARCHAEROEXPRESS = 'searchAeroexpress',
  SEARCH_TAXI = 'searchTaxi',
  SEARCHVIP = 'searchVip',
  SEARCH_CONFERENCE = 'searchConference',
  SEARCH_GROUP = 'searchGroup',
  SEARCH_EVENT = 'searchEvent',
  TRIPS = 'trips',
  REPORT = 'report',
  SETTINGS = 'settings',
  CART = 'cart',
  WAITING_APPROVE = 'waitingApprove',
  REQUESTS = 'requests',
  REQUEST_1C = 'request_1C',
  REQUEST_APPLICATION_SCHEME = 'requestApplicationScheme',
  EXPENSE_REPORTS = 'expenseReports',
  CHARTS_ANALYTICS = 'chartsAnalytics',
  TRAVELERS = 'travelers',
  APPLICATION = 'application',
  EVENT = 'event',
  ACCOUNTING = 'accounting',
}

type DEFAULTMENUTYPEE =
  | 'smartdesc'
  | 'search'
  | 'taxi'
  | 'searchAirline'
  | 'searchHotel'
  | 'searchTransfer'
  | 'searchTrain'
  | 'searchAeroexpress'
  | 'searchTaxi'
  | 'searchVip'
  | 'searchConference'
  | 'searchGroup'
  | 'searchEvent'
  | 'trips'
  | 'report'
  | 'settings'
  | 'cart'
  | 'waitingApprove'
  | 'requests'
  | 'expenseReports'
  | 'chartsAnalytics'
  | 'travelers'
  | 'accounting'
  | 'application'
  | 'coordination'
  | 'requestApplicationScheme'
  | 'request_1C'
  | 'event';

const ICON_TYPES_MAP: Record<string, IconType> = {
  cart: 'menuCart',
  smartdesc: 'menuSmartdeskNew',
  search: 'menuSearch',
  report: 'menuReports',
  requests: 'menuRequests',
  waitingApprove: 'menuApprove',
  trips: 'menuTrips',
  expenseReports: 'menuExpenseReports',
  chartsAnalytics: 'menuChartsAnalytics',
  searchTaxi: 'searchTaxi',
  searchAirline: 'searchAir',
  searchAeroexpress: 'searchAeroexpress',
  searchTrain: 'searchTrain',
  searchHotel: 'searchHotel',
  searchTransfer: 'searchTransfer',
  searchConference: 'searchConference',
  searchEvent: 'searchEvent',
  searchVip: 'searchEvent',
  searchGroup: 'searchGroup',
  travelers: 'groupeOfPeople',
  requestApplicationScheme: 'menuSchemeApplicationRequest',
  request_1C: 'menuRequest1C',
  accounting: 'accounting',
  application: 'menuRequests',
  event: 'searchGroup',
  coordination: 'menuApprove',
};

const SEARCHOPTION = {
  SEARCHVIP: 'VipHall',
  SEARCH_CONFERENCE: 'Conference',
  SEARCH_GROUP: 'GroupCheckin',
  SEARCH_EVENT: 'Event',
};

const NOT_AVAILABLE_FOR_DEMO = getText('constants:app.notForDemo');

const ERROR_CODE = {
  CONFLICT: 409,
  DEPENDENCY: 424,
  NOT_FOUND: 404,
  BAD_REQUEST: 400,
  REQUEST_ENTITY_TOO_LARGE: 413,
  INTERNAL_SERVER_ERROR: 500,
  FORBIDDEN: 403,
};

const ERROR_MESSAGE = {
  FORBIDDEN: 'Forbidden',
};

const INPUT_TYPES = {
  TEXT: 'text',
  NUMBER: 'number',
};

const DEFAULT_MENU_ITEMS: IDefaultMenuItems = {
  SMARTDESCK: {
    url: ROUTES.SMARTDESK,
    type: DEFAULT_MENU_TYPE.SMARTDESC,
    needClickHandle: false,
    qaAttr: QA_ATTRIBUTES.header.smartdesk,
  },
  REQUESTS: {
    title: getText('services:app.menuItems.requests_1C'),
    url: ROUTES.REQUESTS,
    type: DEFAULT_MENU_TYPE.REQUEST_1C,
    needClickHandle: false,
    qaAttr: QA_ATTRIBUTES.header.application.requests1c,
  },
  REQUESTSAPPLICATIONSCHEME: {
    title: getText('services:app.menuItems.requestsSmartway'),
    url: ROUTES.APPLICATION_SCHEME_REQUEST,
    type: DEFAULT_MENU_TYPE.REQUEST_APPLICATION_SCHEME,
    needClickHandle: false,
    qaAttr: QA_ATTRIBUTES.header.application.preTravelRequest,
  },
  REQUESTSAPPLICATIONSCHEMEAGGREGATOR: {
    title: getText('services:app.menuItems.requests'),
    url: ROUTES.APPLICATION_SCHEME_REQUEST,
    type: DEFAULT_MENU_TYPE.REQUEST_APPLICATION_SCHEME,
    needClickHandle: false,
    qaAttr: QA_ATTRIBUTES.header.application.preTravelRequest,
  },
  SEARCH: {
    title: getText('services:app.menuItems.search'),
    url: ROUTES.SEARCH.AIR,
    type: DEFAULT_MENU_TYPE.SEARCH,
    needClickHandle: false,
    qaAttr: QA_ATTRIBUTES.header.search,
  },
  TAXI: {
    title: getText('services:app.menuItems.taxi'),
    url: ROUTES.SEARCH.TAXI,
    type: DEFAULT_MENU_TYPE.SEARCH_TAXI,
    needClickHandle: false,
    qaAttr: QA_ATTRIBUTES.smartdesk.search.taxi,
  },
  SEARCHAIRLINE: {
    title: getText('services:app.menuItems.air'),
    url: ROUTES.SEARCH.AIR,
    type: DEFAULT_MENU_TYPE.SEARCHAIRLINE,
    needClickHandle: true,
    qaAttr: QA_ATTRIBUTES.smartdesk.search.air,
  },
  SEARCHHOTEL: {
    title: getText('services:app.menuItems.hotel'),
    url: ROUTES.SEARCH.HOTEL,
    type: DEFAULT_MENU_TYPE.SEARCHHOTEL,
    needClickHandle: true,
    qaAttr: QA_ATTRIBUTES.smartdesk.search.hotel,
  },
  SEARCHHOTEL_PPR: {
    title: getText('services:app.menuItems.hotel'),
    url: ROUTES.SEARCH.HOTEL,
    type: DEFAULT_MENU_TYPE.SEARCHHOTEL,
    needClickHandle: true,
    qaAttr: QA_ATTRIBUTES.smartdesk.search.hotel,
    sticker: getText('services:app.salesHotel'),
  },
  SEARCHTRAIN: {
    title: getText('services:app.menuItems.train'),
    url: ROUTES.SEARCH.TRAIN,
    type: DEFAULT_MENU_TYPE.SEARCHTRAIN,
    needClickHandle: true,
    qaAttr: QA_ATTRIBUTES.smartdesk.search.train,
  },
  SEARCHTRANSFER: {
    title: getText('services:app.menuItems.transfer'),
    url: ROUTES.SEARCH.TRANSFER,
    type: DEFAULT_MENU_TYPE.SEARCHTRANSFER,
    needClickHandle: true,
    qaAttr: QA_ATTRIBUTES.smartdesk.search.transfer,
  },
  SEARCHAEROEXPRESS: {
    title: getText('services:app.menuItems.aeroexpress'),
    url: ROUTES.SEARCH.AEROEXPRESS,
    type: DEFAULT_MENU_TYPE.SEARCHAEROEXPRESS,
    needClickHandle: true,
    qaAttr: QA_ATTRIBUTES.smartdesk.search.aeroexpress,
  },
  SEARCHVIP: {
    title: getText('services:app.menuItems.vip'),
    url: ROUTES.SEARCH.VIP,
    type: DEFAULT_MENU_TYPE.SEARCHVIP,
    needClickHandle: true,
    headerOnly: true,
    qaAttr: QA_ATTRIBUTES.smartdesk.search.vip,
  },
  SEARCH_CONFERENCE: {
    title: getText('services:app.menuItems.conference'),
    url: ROUTES.SEARCH.CONFERENCE,
    type: DEFAULT_MENU_TYPE.SEARCH_CONFERENCE,
    needClickHandle: true,
    headerOnly: true,
    qaAttr: QA_ATTRIBUTES.smartdesk.search.conference,
  },
  SEARCH_GROUP: {
    title: getText('services:app.menuItems.group'),
    url: ROUTES.SEARCH.GROUP,
    type: DEFAULT_MENU_TYPE.SEARCH_GROUP,
    needClickHandle: true,
    headerOnly: true,
    qaAttr: QA_ATTRIBUTES.smartdesk.search.group,
  },
  SEARCH_EVENT: {
    title: getText('services:app.menuItems.event'),
    url: ROUTES.SEARCH.EVENT,
    type: DEFAULT_MENU_TYPE.SEARCH_EVENT,
    needClickHandle: true,
    headerOnly: true,
    qaAttr: QA_ATTRIBUTES.smartdesk.search.event,
  },
  TRIPS: {
    title: getText('services:app.menuItems.trips'),
    url: ROUTES.TRIPS.MAIN,
    type: DEFAULT_MENU_TYPE.TRIPS,
    needClickHandle: false,
    qaAttr: QA_ATTRIBUTES.header.trips.general,
  },
  REGISTER_TRIPS: {
    title: getText('services:app.menuItems.registerTrips'),
    url: ROUTES.TRIPS.MAIN,
    type: DEFAULT_MENU_TYPE.TRIPS,
    needClickHandle: false,
  },
  REPORT: {
    title: getText('services:app.menuItems.report'),
    url: ROUTES.REPORTS.MAIN,
    type: DEFAULT_MENU_TYPE.REPORT,
    needClickHandle: false,
  },
  EXPENSE_REPORT: {
    title: getText('services:app.menuItems.expenseReports'),
    url: ROUTES.EXPENSE.MAIN,
    type: DEFAULT_MENU_TYPE.EXPENSE_REPORTS,
    needClickHandle: false,
    qaAttr: QA_ATTRIBUTES.header.expenseReport,
  },
  CHARTS_ANALYTICS: {
    title: getText('services:app.menuItems.chartsAnalytics'),
    url: ROUTES.CHARTS_ANALYTICS.GENERAL,
    type: DEFAULT_MENU_TYPE.CHARTS_ANALYTICS,
    needClickHandle: false,
  },
  SETTINGS: {
    title: getText('services:app.menuItems.settings'),
    url: ROUTES.SETTINGS.MAIN,
    type: DEFAULT_MENU_TYPE.SETTINGS,
    needClickHandle: false,
  },
  APPROVE_TRIPS: {
    title: getText('services:app.menuItems.trips'),
    url: ROUTES.APPROVE.TRIPS,
    type: DEFAULT_MENU_TYPE.TRIPS,
    needClickHandle: false,
    qaAttr: QA_ATTRIBUTES.header.approval.trips,
  },
  APPROVE_TRAVEL: {
    title: getText('services:app.menuItems.requests'),
    url: ROUTES.APPROVE.REQUESTS,
    type: DEFAULT_MENU_TYPE.REQUESTS,
    needClickHandle: false,
    qaAttr: QA_ATTRIBUTES.header.approval.preTravel,
  },
  APPROVE_EXPENSE_REPORTS: {
    title: getText('services:app.menuItems.expenseReports'),
    url: ROUTES.APPROVE.EXPENSE_REPORTS,
    type: DEFAULT_MENU_TYPE.EXPENSE_REPORTS,
    needClickHandle: false,
    qaAttr: QA_ATTRIBUTES.header.approval.expenseReport,
  },
  TRAVELERS: {
    title: getText('services:app.menuItems.travelers'),
    url: ROUTES.SMART_AGENT.TRVELERS,
    type: DEFAULT_MENU_TYPE.TRAVELERS,
    needClickHandle: false,
  },
  APPLICATION: {
    title: getText('services:app.menuItems.application'),
    url: ROUTES.APPLICATION,
    type: DEFAULT_MENU_TYPE.APPLICATION,
    needClickHandle: false,
    qaAttr: QA_ATTRIBUTES.header.application.general,
  },
  EVENT: {
    title: getText('services:app.menuItems.event'),
    url: ROUTES.EVENT,
    type: DEFAULT_MENU_TYPE.EVENT,
    needClickHandle: false,
  },
  ACCOUNTING: {
    title: getText('services:app.menuItems.accounting'),
    url: ROUTES.ACCOUNTING,
    type: DEFAULT_MENU_TYPE.ACCOUNTING,
    needClickHandle: false,
  },
};

const ANALYTICS_AGENT_MODE = {
  AIRLINE: 'airline',
  TRAIN: 'train',
  HOTELS: 'hotels',
  AGENT: 'agent',
  CLIENT: 'client',
};

export {
  ICON_TYPES_MAP,
  DEFAULTMENUTYPE,
  SEARCHOPTION,
  NOT_AVAILABLE_FOR_DEMO,
  ERROR_CODE,
  ERROR_MESSAGE,
  INPUT_TYPES,
  DEFAULT_MENU_ITEMS,
  ANALYTICS_AGENT_MODE,
};

export type {
  DEFAULTMENUTYPEE,
};
