import React from 'react';
import { Button, Dialog } from 'new-ui';
import { createPortal } from 'react-dom';

import { getText } from '../../../i18n';

import { MainAnalytic } from '../../bi/utils/analytics';
import { isSmartAgent } from '../../bi/utils/env';

import { CloudPaymentsDialog } from '../CloudPaymentsDialog';

import { CloudPaymentsProps, CloudPaymentsState } from './types';

import styles from './styles/index.module.css';

const LABEL = getText('components:cloudPayments.label');

class CloudPayments extends React.Component<CloudPaymentsProps, CloudPaymentsState> {
  static defaultProps = {
    onClickAnalytics: () => {},
    buttonType: '',
    notShowReportPayment: true,
    companyIdProps: null,
  };

  constructor(props: CloudPaymentsProps) {
    super(props);

    this.state = {
      showDialog: false,
      showCompaniesSelect: true,
      showApproveForm: false,
      showPayment: false,
    };
  }

  handleSetDialogVisibility = (value: boolean) => {
    const isCompanies = isSmartAgent ? null : this.props.companies.length > 1;

    this.setState({
      showDialog: value,
      showCompaniesSelect: isCompanies,
      showApproveForm: !isCompanies,
      showPayment: false,
    });
  };

  handleClickCloudPayments = (value: boolean) => {
    this.handleSetDialogVisibility(value);

    if (this.props.onClickAnalytics) {
      this.props.onClickAnalytics();
    }

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMARTDESC.SMARTDESK_REPLENISHMENT_CARD_PRESSED);
  };

  handleToggleShowCompaniesSelect = () => {
    const { showCompaniesSelect, showApproveForm } = this.state;

    this.setState({
      showCompaniesSelect: !showCompaniesSelect,
      showApproveForm: !showApproveForm,
    });
  };

  handleToggleShowApproveForm = () => {
    const { showApproveForm, showPayment } = this.state;

    this.setState({
      showApproveForm: !showApproveForm,
      showPayment: !showPayment,
    });
  };

  renderDialog = () => {
    const { showDialog, showCompaniesSelect, showApproveForm, showPayment } = this.state;
    const { email, companies, companyId, paymentService, companyIdProps, companyName, companyInn, allCompanies } = this.props;

    const currentCompany = companyIdProps || companyId;

    return createPortal(
      <Dialog
        show={ showDialog }
        showClosing
        onChange={ () => this.handleSetDialogVisibility(false) }
      >
        <CloudPaymentsDialog
          onCloseDialog={ () => this.handleSetDialogVisibility(false) }
          onChangeCompaniesSelect={ this.handleToggleShowCompaniesSelect }
          onChangeApproveForm={ this.handleToggleShowApproveForm }
          companyEmail={ email }
          companyName={ companyName }
          companyInn={ companyInn }
          currentCompany={ currentCompany }
          companiesItems={ companies }
          showCompaniesSelect={ showCompaniesSelect }
          showApproveForm={ showApproveForm }
          paymentService={ paymentService }
          showPayment={ showPayment }
          allCompanies={ allCompanies }
          autoFocusInput={ showDialog }
        />
      </Dialog>,
      document.body,
    );
  };

  renderButtonForSmartWay = () => (
    <Button
      className={ styles.button }
      type='primary-outline'
      onClick={ () => this.handleClickCloudPayments(true) }
    >
      { LABEL }
    </Button>
  );

  renderButtonForSmartAgent = () => {
    const { buttonType, notShowReportPayment, companyIdProps } = this.props;
    const checkTypeTheme = buttonType ? 'secondary' : 'textual-decoration';

    if (notShowReportPayment) {
      return null;
    }

    const onClick = () => {
      if (companyIdProps) {
        this.handleToggleShowCompaniesSelect();
      }

      this.handleClickCloudPayments(true);
    };

    return (
      <Button
        type={ checkTypeTheme }
        onClick={ () => onClick() }
      >
        { LABEL }
      </Button>
    );
  };

  renderButtonPayment = () => (isSmartAgent ? this.renderButtonForSmartAgent() : this.renderButtonForSmartWay());

  render() {
    return (
      <>
        { this.renderButtonPayment() }
        { this.renderDialog() }
      </>
    );
  }
}

export default CloudPayments;
