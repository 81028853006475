import React from 'react';
import { Text, Tooltip, Price } from 'new-ui';

import FareDetails from '../FareDetails';

import { isSmartAgent } from '../../bi/utils/env';

import SYMBOLS from '../../bi/constants/symbols';

import { AirlineFare } from '../../bi/types/airline';

import styles from './styles/index.module.css';

interface FareItemProps {
  fare: AirlineFare,
  currentFareId?: string | null,
  currentPrice: number,
  onFareClick(fare: any): void,
  customRefundabilityAndExchangeability?: boolean,
  isChangeAirTrip?: boolean,
  agentMode: boolean,
}

const FareItem = ({
  fare,
  currentFareId = null,
  currentPrice,
  onFareClick,
  customRefundabilityAndExchangeability = false,
  isChangeAirTrip = false,
  agentMode,
}: FareItemProps) => {
  const { Id, Name, Price: { TotalPrice, AgentFee } } = fare;
  const currentFare = currentFareId === Id;
  const wrapperStyles = [styles.wrapper];
  const labelStyles = [styles.label];

  if (currentFare) {
    wrapperStyles.push(styles.current);
  }

  if (isChangeAirTrip) {
    labelStyles.push(styles.label_change_air_trip);
  }

  const color = currentFare ? 'default' : 'accent';

  const price = currentFare ? currentPrice : TotalPrice - currentPrice;
  const curAgentFee = !agentMode ? AgentFee : 0;
  const curPriceSA = currentFare
    ? Number(currentPrice) + Number(curAgentFee)
    : Number(TotalPrice) + Number(curAgentFee);

  const preparedPrice = isSmartAgent ? curPriceSA : price;

  const symbolHtml = !currentFare && !isSmartAgent && TotalPrice - currentPrice > 0 && (
    <Text type='NORMAL_14' color={ color }>{SYMBOLS.PLUS.value}&thinsp;</Text>
  );

  const tooltip = () => (
    <Text
      type='NORMAL_14_130'
      color='white'
      className={ styles.tooltip }
    >
      <FareDetails
        customRefundabilityAndExchangeability={ customRefundabilityAndExchangeability }
        fare={ fare }
      />
    </Text>
  );

  const renderPrice = () => (!isChangeAirTrip && (
    <div className={ styles.price }>
      { symbolHtml }
      <Price
        marginSmall
        type='NORMAL_14'
        typeCurrency='NORMAL_14'
        color={ color }
        value={ preparedPrice }
      />
    </div>
  ));

  return (
    <Tooltip
      position='bottom'
      renderContent={ tooltip }
      className={ wrapperStyles.join(' ') }
      childrenClassName={ styles.children_tooltip }
    >
      <div className={ styles.content } onClick={ () => onFareClick(fare) }>
        <div className={ styles.fare }>
          <Text type='NORMAL_14' className={ labelStyles.join(' ') }>{ Name }</Text>
          { renderPrice() }
        </div>
      </div>
    </Tooltip>
  );
};

export default FareItem;
