import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Checkbox, Dialog, DotLoading, Text, Textarea, Tooltip, NoResults } from 'new-ui';

import { getText, getTextArray } from '../../../i18n';

import { getEmployeeFullName } from '../../bi/utils/employees';
import toDecline from '../../bi/utils/toDecline';

import { APPROVAL_SCHEME_TYPES } from '../../bi/constants/approvalSchemes';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';

import { ApprovalSchemeDialogProps, Approver, Step } from './types';

import styles from './styles/index.module.css';

const LABELS = {
  TITLE: getText('components:approvalSchemeDialog.title'),
  NO_SCHEME_ERROR: getText('components:approvalSchemeDialog.noSchemeError'),
  CHOOSE: getText('components:approvalSchemeDialog.select'),
  WITHOUT_CHOICE: getText('components:approvalSchemeDialog.withoutChoice'),
  NOT_FIRST_STEP_EVERY: getText('components:approvalSchemeDialog.notFirstStepEvery'),
  SENT_TO: getTextArray('components:approvalSchemeDialog.sentToDeclines'),
  NOT_FIRST_STEP_CHOOSE: getText('components:approvalSchemeDialog.notFirstStepChoose'),
  STEP: getText('components:approvalSchemeDialog.step'),
  HINTS: {
    ONE_STEP_EVERY: getText('components:approvalSchemeDialog.hints.oneStepEvery'),
    ONE_STEP_CHOOSE: getText('components:approvalSchemeDialog.hints.oneStepChoose'),
    ONE_STEP_REQ_EVERY: getText('components:approvalSchemeDialog.hints.oneStepReqEvery'),
    ONE_STEP_REQ_CHOOSE: getText('components:approvalSchemeDialog.hints.oneStepReqChoose'),
    STEP_EVERY: getText('components:approvalSchemeDialog.hints.stepEvery'),
    STEP_CHOOSE: getText('components:approvalSchemeDialog.hints.stepChoose'),
  },
  COMMENT: {
    DESCRIPTION: getText('components:approvalSchemeDialog.comment.description'),
    ON_EVERY_STEPS: getText('components:approvalSchemeDialog.comment.onEverySteps'),
    PLACEHOLDER: getText('components:approvalSchemeDialog.comment.placeholder'),
  },
  SUBMIT: getText('components:approvalSchemeDialog.submit'),
  BUY: getText('components:approvalSchemeDialog.buy'),
  LOADING_ERROR: getText('components:approvalSchemeDialog.loadingError'),
  ERROR_EMPTY_COMMENT: getText('components:approvalSchemeDialog.errorEmptyComment'),
  ERROR_NOT_ALL_APPROVERS_SELECTED: getText('components:approvalSchemeDialog.notAllRequiredApproversSelected'),
  APPROVAL_CKR: getText('approve:approvalsCKR'),
};

const prepareWithoutChoiceLabel = (count: number) => `${LABELS.WITHOUT_CHOICE} ${toDecline(count, LABELS.SENT_TO)}:`;

const shouldChoose = (approvalCondition: string) =>
  approvalCondition === 'OneOfApproversAndSendSelected';

const checkSchemeSteps = (Steps: Step[], PreSteps: Step[], currentUserId: null | number | string, isTpDisrupted: boolean) => {
  if (!Steps.length) {
    return PreSteps;
  }

  if (isTpDisrupted) {
    return Steps;
  }

  const onlyTPSteps = Steps?.every(({ Type }: { Type: string }) => Type === APPROVAL_SCHEME_TYPES.DISRUPTED_TP.value);
  const currentUserApprover = (steps: Step[]) => steps.every(s => s.Approvers && s.Approvers.length === 1 && s.Approvers[0] === currentUserId);
  const stepWithoutHeads = (steps: Step[]) => steps.every(s => s.Roles.length === 0);

  if (onlyTPSteps || (currentUserApprover(Steps) && stepWithoutHeads(Steps))) return PreSteps;

  const filterTPSteps = Steps.filter(({ Type }: { Type: string }) => Type !== APPROVAL_SCHEME_TYPES.DISRUPTED_TP.value);

  if (filterTPSteps && currentUserApprover(filterTPSteps) && stepWithoutHeads(filterTPSteps)) return PreSteps;

  return Steps;
};

const ApprovalSchemeDialog: React.FC<ApprovalSchemeDialogProps> = observer(({
  scheme = null,
  currentUserId,
  error,
  isTpDisrupted,
  onSubmit,
  onClose,
  userSessionService,
  approvalType = '',
  loaded,
  show,
  emptyComment,
  approveTexts,
  onlySteps,
  isBooking,
  showApproversCKR = false,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedApprovers, setSelectedApprovers] = useState<{ userId: string; Id: string }[]>([]);
  const [fetchErrors, setFetchErrors] = useState<boolean[]>([]);
  const [comment, setComment] = useState<string>('');
  const [allRequiredApproversIsSelected, setAllRequiredApproversIsSelected] = useState<Record<string, string[]>>({});

  useEffect(() => {
    if (scheme && !scheme?.CanSkipApproval) {
      const { Steps, PreSteps } = scheme;
      const objIds: Record<string, string[]> = {};

      [...PreSteps, ...Steps]
        .filter(({ StepCondition, Approvers }) => shouldChoose(StepCondition) && Approvers?.length > 1)
        .forEach(({ Id }) => {
          objIds[Id] = [];
        });

      return setAllRequiredApproversIsSelected(objIds);
    }

    return setAllRequiredApproversIsSelected({});
  }, [scheme]);

  const handleChangeChosenApprover = (userIdCheckedValue: boolean, userId: string, Id: string) => {
    const currentStep = allRequiredApproversIsSelected[Id];

    if (userIdCheckedValue) {
      currentStep.push(userId);
      setAllRequiredApproversIsSelected({ ...allRequiredApproversIsSelected, [Id]: currentStep });

      return setSelectedApprovers(prev => ([...prev, { userId, Id }]));
    }

    const changedCurrentStep = currentStep.filter((id) => id !== userId);

    setAllRequiredApproversIsSelected({ ...allRequiredApproversIsSelected, [Id]: changedCurrentStep });

    return setSelectedApprovers(prev => prev.filter(el => userId !== el.userId));
  };

  const renderComment = (nonSkippedStepsLength: number) => {
    const title = `${LABELS.COMMENT.DESCRIPTION} ${nonSkippedStepsLength > 1 ? LABELS.COMMENT.ON_EVERY_STEPS : ''}:`;

    return (
      <div className={ styles.comment }>
        <Text className={ styles.title }>{title}</Text>
        <Textarea
          placeholder={ LABELS.COMMENT.PLACEHOLDER }
          onChange={ value => setComment(value) }
          value={ comment }
        />
      </div>
    );
  };

  const renderTooltipContent = (label: string) => (
    <Text
      type='NORMAL_14_130'
      color='white'
      className={ styles.content }
    >
      { label }
    </Text>
  );

  const submitForm = () => {
    const { Steps, PreSteps } = scheme;

    let preparedSteps: Step[] = [];

    if (PreSteps?.length > 0) preparedSteps = [...PreSteps];

    if (Steps?.length > 0) preparedSteps = [...preparedSteps, ...Steps];

    const isPreSteps = PreSteps?.length ? preparedSteps.find((i) => i.Id === PreSteps[0].Id) : false;
    const errorsClone = [...fetchErrors];
    const preparedToSaveSteps: Step[] | any = [];

    preparedSteps.forEach(({ skip, Approvers, StepCondition, Id }, ind) => {
      if (skip) {
        return preparedToSaveSteps.push({ Id, Approvers: [] });
      }

      if (shouldChoose(StepCondition) && Approvers && Approvers.length > 1) {
        errorsClone[ind] = !selectedApprovers.length;
        const result: string[] = [];

        selectedApprovers.forEach(el => {
          if (el.Id === Id) {
            result.push(el.userId);
          }
        });

        return preparedToSaveSteps.push({ Id, Approvers: result });
      }

      const approversId = Approvers && Approvers.map(({ UserId }: { UserId: number }) => UserId);

      return preparedToSaveSteps.push({ Id, Approvers: approversId });
    });

    setFetchErrors(errorsClone);

    if (errorsClone.some(i => i)) {
      return;
    }

    const model = {
      ApprovalComment: comment,
      StepsSettings: preparedToSaveSteps,
    };

    setLoading(true);

    userSessionService.defaultProjectHead();
    userSessionService.defaultDepartmentHead();

    onSubmit(model, isPreSteps).then(() => setLoading(false));
  };

  const renderActions = () => {
    const allRequiredStepsIsSelected = Object.values(allRequiredApproversIsSelected).some((value) => !value.length);
    const customButtonAndLabel = scheme.CanSkipApproval || isBooking;
    const label = customButtonAndLabel ? approveTexts.BUTTON : LABELS.SUBMIT;
    const buttonAndTooltip = customButtonAndLabel ? false : emptyComment(comment);
    const tooltipContent = buttonAndTooltip ? renderTooltipContent(LABELS.ERROR_EMPTY_COMMENT) : renderTooltipContent(LABELS.ERROR_NOT_ALL_APPROVERS_SELECTED);

    return (
      <div className={ styles.actions }>
        <Tooltip
          position='bottom'
          show={ buttonAndTooltip || allRequiredStepsIsSelected }
          renderContent={ () => tooltipContent }
        >
          <Button
            onClick={ submitForm }
            loading={ loading }
            type='secondary'
            disabled={ buttonAndTooltip || allRequiredStepsIsSelected }
            qaAttr={ QA_ATTRIBUTES.cart.approval.scheme.dialog.buttonApprove }
          >
            { label }
          </Button>
        </Tooltip>
      </div>
    );
  };

  const renderEmployeeById = (employeeParam: Approver, qaAttrApprover = '') => {
    const { LastName, FirstName, MiddleName, Email, Surname } = employeeParam;
    const fullName = getEmployeeFullName({ Surname: MiddleName, Name: FirstName, Patronymic: LastName });
    let email = '';

    if (Email) {
      email = `(${Email})`;
    }

    if (Surname && !Email) {
      email = Surname;
    }

    return showApproversCKR ? <Text className={ styles.employee }>{ LABELS.APPROVAL_CKR }</Text> : (
      <Text className={ styles.employee }>
        {fullName}
        <Text
          color='gray'
          className={ styles.inline }
          qaAttr={ qaAttrApprover }
        >
          { email }
        </Text>
      </Text>
    );
  };

  const renderStepWithoutChoiceApprovers = (step: Step[] | any) => {
    const { Approvers } = step;

    const renderEmployee = (approver: Approver) => (showApproversCKR ? LABELS.APPROVAL_CKR : renderEmployeeById(approver, QA_ATTRIBUTES.cart.approval.scheme.dialog.approversAll));

    const renderApprovers = () => (
      Approvers.map((approver: Approver, index: number) =>
        <li key={ index }>
          { renderEmployee(approver) }
        </li>,
      )
    );

    const content = Approvers.length === 1
      ? renderEmployeeById(Approvers[0], QA_ATTRIBUTES.cart.approval.scheme.dialog.approver)
      : (
        <ul>
          <Text className={ styles.text_approvers }>
            {renderApprovers()}
          </Text>
        </ul>
      );

    const stylesApprovers = Approvers.length !== 1 ? styles.approvers : '';

    return (
      <div className={ stylesApprovers }>
        { content }
      </div>
    );
  };

  const renderStepWithChoiceApprovers = ({ Approvers, Id }: Step) => (
    <div className={ styles.approvers }>
      {Approvers && Approvers.map((approver: Approver, index: number) => (
        <Checkbox
          key={ approver.UserId }
          onChange={ (value) => handleChangeChosenApprover(value, approver.UserId, Id) }
          value={ allRequiredApproversIsSelected[Id]?.includes(approver.UserId) }
          className={ styles.checkbox }
          qaAttr={ `${QA_ATTRIBUTES.cart.approval.scheme.dialog.aprroverCheckbox}-${index}` }
        >
          { renderEmployeeById(approver, QA_ATTRIBUTES.cart.approval.scheme.dialog.approver) }
        </Checkbox>
      )) }
    </div>
  );

  const renderStepHeader = ({ StepCondition, Approvers }: { StepCondition: string, Approvers: Approver[] }, index: number, length: number, realIndex: number) => {
    const stepNumber = length > 1 && (<Text type='SEMIBOLD_16' className={ styles.number }> { LABELS.STEP } {realIndex + 1}</Text>);
    let descriptionContent = '';
    const stepConditionApprovers = shouldChoose(StepCondition) && Approvers.length > 1;

    if (realIndex === 0) {
      descriptionContent = stepConditionApprovers ? LABELS.CHOOSE : prepareWithoutChoiceLabel(Approvers.length);
    } else {
      descriptionContent = stepConditionApprovers ? LABELS.NOT_FIRST_STEP_CHOOSE : `${LABELS.NOT_FIRST_STEP_EVERY} ${toDecline(Approvers.length, LABELS.SENT_TO)}:`;
    }

    return (
      <div className={ styles.header }>
        { stepNumber }
        <Text color={ fetchErrors[index] ? 'red' : 'default' }>
          <Text>{descriptionContent}</Text>
        </Text>
      </div>
    );
  };

  const getHintText = (ConditionOfApproval: string, variants: string[]) => {
    switch (ConditionOfApproval) {
      case 'AllApprovers':
        return variants[0];
      case 'OneOfApproversAndSendSelected':
      case 'OneOfApproversAndSendAll':
        return variants[1];
      default:
        return '';
    }
  };

  const prepareStepHintContent = ({ StepCondition, Approvers }: Step, index: number, length: number) => {
    if (Approvers && Approvers.length === 1) {
      return null;
    }

    const variants = approvalType === '' ?
      [LABELS.HINTS.ONE_STEP_EVERY, LABELS.HINTS.ONE_STEP_CHOOSE] : [LABELS.HINTS.ONE_STEP_REQ_EVERY, LABELS.HINTS.ONE_STEP_REQ_CHOOSE];

    if (index === 0 && length === 1) {
      return getHintText(StepCondition, variants);
    }

    return getHintText(StepCondition, [LABELS.HINTS.STEP_EVERY, LABELS.HINTS.STEP_CHOOSE]);
  };

  const renderSteps = (reducedSteps: Step[], nonSkippedStepsLength: number) => {
    let nonSkippedCount = 0;

    return (
      <div className={ styles.steps }>
        {
          reducedSteps.map((step, i) => {
            const { skip, StepCondition, Approvers } = step;

            if (skip) {
              return null;
            }

            const content = shouldChoose(StepCondition) && Approvers && Approvers.length > 1
              ? renderStepWithChoiceApprovers(step)
              : renderStepWithoutChoiceApprovers(step);

            const borderBottomClass = nonSkippedStepsLength ? styles.border : '';
            const hintContent = prepareStepHintContent(step, nonSkippedCount, nonSkippedStepsLength);
            const headerContent = renderStepHeader(step, i, nonSkippedStepsLength, nonSkippedCount);

            nonSkippedCount++;

            return (
              <div className={ `${styles.step} ${borderBottomClass}` } key={ i }>
                { headerContent }
                { content }
                { hintContent &&
                  <Text
                    className={ styles.hint }
                    qaAttr={ QA_ATTRIBUTES.cart.approval.scheme.dialog.hintContent }
                  >
                    { hintContent }
                  </Text>
                }
              </div>
            );
          })
        }
      </div>
    );
  };

  const prepareRenderContent = () => {
    if (!scheme) {
      return <NoResults styles={ { paddingTop: 0 } } label={ LABELS.NO_SCHEME_ERROR } />;
    }

    const { Steps, PreSteps, CanSkipApproval } = scheme;

    if (CanSkipApproval || isBooking) {
      return (
        <div className={ styles.wrapper }>
          <Text className={ styles.header } type='bold_20'>{ LABELS.TITLE }</Text>
          <Text className={ styles['self-approve'] }>{ approveTexts.TEXT }</Text>
          { renderActions() }
        </div>
      );
    }

    const selectSteps = checkSchemeSteps(Steps, PreSteps, currentUserId, isTpDisrupted);

    const reducedSteps = onlySteps ? selectSteps : [...PreSteps, ...Steps];

    const checkStepsFromAllAdmin = approvalType !== '' && reducedSteps.every(s => s.admin) ? [reducedSteps.shift()] : reducedSteps;
    const nonSkippedStepsLength = checkStepsFromAllAdmin.filter(({ skip }) => !skip).length;

    return (
      <div className={ styles.wrapper }>
        <Text className={ styles.header } type='bold_20'>{ LABELS.TITLE }</Text>
        { renderSteps(checkStepsFromAllAdmin, nonSkippedStepsLength) }
        { renderComment(nonSkippedStepsLength) }
        { renderActions() }
      </div>
    );
  };

  let content;

  if (loaded) {
    content = (
      <div className={ styles.loading }>
        <DotLoading />
      </div>
    );
  } else if (error) {
    content = (
      <NoResults styles={ { paddingTop: 0 } } label={ LABELS.LOADING_ERROR } />
    );
  } else if (show) {
    content = prepareRenderContent();
  }

  return (
    <Dialog
      show={ show }
      onChange={ onClose }
    >
      <div className={ styles.wrapper }>
        { content }
      </div>
    </Dialog>);
});

ApprovalSchemeDialog.defaultProps = {
  scheme: null,
  approvalType: '',
  admin: null,
  isBooking: false,
  approveTexts: {
    BUTTON: '',
    TEXT: '',
  },
};

export default ApprovalSchemeDialog;
