import { currentLng, getText } from '../../../i18n';
import { cloudPaymentsLanguages, languages } from '../../../i18n/locales/consts';

import CONFIG from '../../../config';
import { isSmartAgent } from './env';

interface CalculationResult {
  amountOnAccount: number;
  percent: number;
  transactionFee: number;
  totalAmount: number;
}

interface CloudPaymentItem {
  label: string;
  price: number;
  quantity: number;
  amount: number;
  vat: number;
  method: number;
  object: number;
  measurementUnit: string;
}

interface CloudPaymentData {
  companyId: string;
  type: string;
  fee: number;
  cloudPayments: {
    customerReceipt: {
      Items: CloudPaymentItem[];
      taxationSystem: number;
      email: string;
      customerInfo: string;
      customerInn: string;
      amounts: {
        electronic: number;
        advancePayment: number;
        credit: number;
        provision: number;
      };
    };
  };
}

declare global {
  interface Window {
    cp: {
      CloudPayments: new (options: { language: string }) => {
        auth: (
          params: {
            publicId: string;
            description: string;
            amount: number;
            accountId: string;
            email: string;
            customerInfo: string;
            customerInn: string;
            data: CloudPaymentData;
          },
          onSuccess: (data: any) => void,
          onFail: (error: any) => void
        ) => void;
      };
    };
  }
}

const DESCRIPTION_WITH_PERCENT = getText('utils:cloudPayments.descriptionWithPercent');
const DESCRIPTION_WITH_PERCENT_SMART_AGENT = getText('utils:cloudPayments.descriptionWithPercentSmartAgent');
const DESCRIPTION_WITHOUT_PERCENT = getText('utils:cloudPayments.descriptionWithoutPercent');
const DESCRIPTION_WITHOUT_PERCENT_SMART_AGENT = getText('utils:cloudPayments.descriptionWithoutPercentSmartAgent');
const CURRENCY = 'RUB';
const SKIN = 'mini';
const PRODUCT_NAME = getText('utils:cloudPayments.title.product');
const PRODUCT_NAME_SMART_AGENT = getText('utils:cloudPayments.title.productSmartAgent');
const checkProduct = isSmartAgent ? PRODUCT_NAME_SMART_AGENT : PRODUCT_NAME;
const TRANSACTION_FEE_NAME = getText('utils:cloudPayments.title.fee');
const QUANTITY = 1;
const VATTYPE = 0;
const METHODPAYMENT = 3;
const OBJECTPAYMENT = 4;
const MEASUREMENT = getText('utils:cloudPayments.pieces');
const TAXIONSYSTEMTYPE = 0;
const MIN_PROCENT = 250;

const params = {
  publicId: CONFIG.CLOUDPAYMENTS.PUBLIC_KEY,
  currency: CURRENCY,
  skin: SKIN,
  requireEmail: true,
};

const PERCENTAGES = {
  SMARTWAY: 0.022,
  SMARTAGENT: 0.018,
};

const selectedPercentage = isSmartAgent ? PERCENTAGES.SMARTAGENT : PERCENTAGES.SMARTWAY;

const checkCloudPaymentsInstance = () => !!window.cp;

const openCloudPaymentsForm = (
  amount: number,
  transactionPercent: number,
  amountOnAccount: number,
  isChargeTransactionFee: boolean,
  email: string,
  customerInfo: string,
  customerInn: string,
  companyId: string,
  onSuccess: (data: any) => void,
  onFail: (error: any) => void,
) => {
  const Items = isChargeTransactionFee ? [
    {
      label: checkProduct,
      price: amountOnAccount,
      quantity: QUANTITY,
      amount: amountOnAccount,
      vat: VATTYPE,
      method: METHODPAYMENT,
      object: OBJECTPAYMENT,
      measurementUnit: MEASUREMENT,
    },
    {
      label: TRANSACTION_FEE_NAME,
      price: transactionPercent,
      quantity: QUANTITY,
      amount: transactionPercent,
      vat: VATTYPE,
      method: METHODPAYMENT,
      object: OBJECTPAYMENT,
      measurementUnit: MEASUREMENT,
    },
  ] : [
    {
      label: checkProduct,
      price: amount,
      quantity: QUANTITY,
      amount,
      vat: VATTYPE,
      method: METHODPAYMENT,
      object: OBJECTPAYMENT,
      measurementUnit: MEASUREMENT,
    },
  ];

  const customerReceipt = {
    Items,
    taxationSystem: TAXIONSYSTEMTYPE,
    email,
    customerInfo,
    customerInn,
    amounts: {
      electronic: amount,
      advancePayment: 0,
      credit: 0,
      provision: 0,
    },
  };

  const checkAccountDescription = isSmartAgent ? DESCRIPTION_WITH_PERCENT_SMART_AGENT : DESCRIPTION_WITH_PERCENT;

  const checkAccountDescriptionWithPercent = isSmartAgent ? DESCRIPTION_WITHOUT_PERCENT_SMART_AGENT : DESCRIPTION_WITHOUT_PERCENT;

  const description = isChargeTransactionFee ? checkAccountDescription : checkAccountDescriptionWithPercent;

  const language = cloudPaymentsLanguages[currentLng] || languages.ru;

  return new window.cp.CloudPayments({ language }).auth({
    ...params,
    description,
    amount,
    accountId: email,
    email,
    customerInfo,
    customerInn,
    data: {
      type: 'BalanceIncomingCorpAccount',
      companyId,
      fee: isChargeTransactionFee ? transactionPercent : 0,
      cloudPayments: {
        customerReceipt,
      },
    },
  }, onSuccess, onFail);
};

const transactionFeeLogic = (percent: number, isChargeTransactionFee: boolean) => {
  if (isSmartAgent) {
    return +(isChargeTransactionFee && percent);
  }

  return +(isChargeTransactionFee && (percent >= MIN_PROCENT ? percent : MIN_PROCENT));
};

const calculationAmount = (value: string, isChargeTransactionFee: boolean): CalculationResult => {
  const amountOnAccount = (parseInt(value.split(' ').join(''), 10) || 0);
  const percent = Math.ceil(amountOnAccount * selectedPercentage);
  const transactionFee = transactionFeeLogic(percent, isChargeTransactionFee);
  const totalAmount = amountOnAccount && amountOnAccount + transactionFee;

  return { amountOnAccount, percent, transactionFee, totalAmount };
};

export { openCloudPaymentsForm, checkCloudPaymentsInstance, calculationAmount };
