import React from 'react';
import { Icon, Text } from 'new-ui';

import { isNull } from '../../../../bi/utils/isNull';

import { ISortSelectItem } from '../../../../bi/types/trains';

import styles from './index.module.css';

interface SortArrowOptionProps {
  item: ISortSelectItem,
}

const SortArrowOption = ({ item }: SortArrowOptionProps) => {
  const { type, label } = item;

  const hasType = !isNull(type);
  const typeIsUp = hasType && !!type;

  const iconStyles = [styles.icon];

  if (!typeIsUp) {
    iconStyles.push(styles.down);
  }

  return (
    <Text
      type='NORMAL_16'
      className={ styles.item }
    >
      { label }
      { hasType && (
        <Icon
          type='arrowsUp'
          className={ iconStyles.join(' ') }
        />
      ) }
    </Text>
  );
};

export { SortArrowOption };
