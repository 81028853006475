import React from 'react';
import { Text } from 'new-ui';

import styles from '../styles/intercom.module.css';

const WarningIntercomSA = () => (
  <div className={ styles.text_wrapper }>
    <Text type='bold_18'>
      В данный момент Travel-помощник может быть недоступен по техническим причинам.
    </Text>
    <Text type='bold_18' className={ styles.text }>
      Мы связались с поставщиком услуги и прикладываем все усилия для возобновления его работы.
    </Text>
    <Text type='bold_18' className={ styles.text }>
      По всем вопросам можете обратиться по номеру телефона 8-800-600-63-74 или написать на почту help@smartagent.online
    </Text>
  </div>
);

export { WarningIntercomSA };
