import React, { ReactNode } from 'react';
import { StyledWrapper, Text } from 'new-ui';

import { getText } from '../../../i18n';

import { HistoryObj } from '../../bi/services/hotels/types';
import { IHistoryItem } from '../../bi/types/transfer';
import { AirlineHistoryItem } from '../../bi/types/airline';

import { ITrainHistoryItem } from '../../bi/types/trains';

import styles from './styles/index.module.css';

const LABELS = {
  youSearch: getText('components:searchHistory.youWereLooking'),
};

type HistoryItem = HistoryObj | IHistoryItem | AirlineHistoryItem | ITrainHistoryItem;

interface SearchHistoryProps {
  items: HistoryObj[] | IHistoryItem[] | AirlineHistoryItem[] | ITrainHistoryItem[],
  renderItem(item: HistoryItem): ReactNode,
  onClick(item: HistoryItem): void,
  qaAttrLabel?: string,
  qaAttrWrapper?: string,
}

const SearchHistory = ({
  items,
  renderItem,
  onClick,
  qaAttrLabel = '',
  qaAttrWrapper = '',
}: SearchHistoryProps) => {
  const renderItems = () => items.slice(0, 4).map((i, ind) => (
    <StyledWrapper qaAttr={ qaAttrWrapper } key={ ind } onClick={ () => onClick(i) } className={ styles.item }>
      { renderItem(i) }
    </StyledWrapper>
  ));

  return (
    <div className={ styles.wrapper }>
      <Text qaAttr={ qaAttrLabel } type='bold_16' className={ styles.title }>
        { LABELS.youSearch }
      </Text>
      <div className={ styles.items }>
        { renderItems() }
      </div>
    </div>
  );
};

export { SearchHistory };
