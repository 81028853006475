import React, { ReactNode } from 'react';
import { Text } from 'new-ui';

import { getText } from '../../../../i18n';

import SimpleTripAirSu from './SimpleTripAirSu';

import { IBookingConflict, IConflictSegment, ISourcesItem } from '../../../bi/services/checkout/types';

import styles from '../styles/index.module.css';

interface IDuplicateBookings {
  duplicateBookings: IBookingConflict[],
  sources: ISourcesItem[],
  renderItem: (item: ISourcesItem) => ReactNode,
}

const LABELS = {
  RESERVATION: (userName: string) => getText('constants:checkout.reservation', { userName }),
  DUPLICATE_CARTS: getText('constants:checkout.duplicate.titles.duplicateCarts'),
};

const DuplicateBookings = ({
  duplicateBookings,
  sources,
  renderItem,
}: IDuplicateBookings) => {
  const renderCartItems = () =>
    duplicateBookings.map(({ CartItemId }) =>
      sources.map((source) => {
        const { Id } = source;
        const findItem = Id === CartItemId;

        if (!findItem) return null;

        return (
          <div className={ styles.item } key={ Id }>
            { renderItem(source as ISourcesItem) }
          </div>
        );
      }),
    );

  const renderUserSegments = (segments: IConflictSegment[]) =>
    segments.map((
      segment: IConflictSegment,
      index: number,
    ) => (
      <div key={ index } className={ styles.item }>
        <SimpleTripAirSu { ...segment } />
      </div>
    ));

  const renderReservedSegments = () =>
    duplicateBookings.map(
      ({ Segments }: { Segments: IConflictSegment[] },
      ) => {
        const groupedSegments = Segments.reduce(
          (
            acc: Record<string, IConflictSegment[]>,
            segment: IConflictSegment,
          ) => {
            const { UserName } = segment;

            if (!acc[UserName]) {
              acc[UserName] = [];
            }

            acc[UserName].push(segment);

            return acc;
          }, {});

        return Object.entries(groupedSegments)
          .map(([
            userName,
            userSegments,
          ]) => (
            <div key={ userName }>
              <Text className={ styles.title } type='NORMAL_18'>
                { LABELS.RESERVATION(userName) }
              </Text>
              { renderUserSegments(userSegments) }
            </div>
          ));
      });

  return (
    <div className={ styles.items }>
      { renderReservedSegments() }
      <Text
        className={ styles.title }
        type='NORMAL_18'
      >
        { LABELS.DUPLICATE_CARTS }
      </Text>
      { renderCartItems() }
    </div>
  );
};

export default DuplicateBookings;
