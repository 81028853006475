import React, { ReactElement, Component } from 'react';
import { Button, IconButton, Text, Tooltip, Price, ItemPanel, Icon, BookMarkProps } from 'new-ui';

import { getText } from '../../../i18n';

import Config from '../../../config';

import AirItem from './components/airItem';
import InfoDialog from './components/infoDialog';
import TooltipText from './components/tooltipText';
import { Complex } from './components/complex';
import { TravellersAmount } from './components/travellersAmount';
import AgencyFee from '../AgencyFee';
import { FavoriteAction } from '../FavoriteAction';
import FareItem from '../FareItem';
import FareDetails from '../FareDetails';
import { LinkAction } from '../LinkAction';
import { TravelPolicyDialog } from '../TravelPolicyDialog';
import { NameErrorsTravelPolicy } from '../NameErrorsTravelPolicy';
import AirlineBonus from '../AirlineBonus';
import { ToNote } from '../ToNote';
import { RoundIconWithTooltip } from '../RoundIconWithTooltip';

import guid from '../../bi/utils/guid';
import getAirlineSameCompany from '../../bi/utils/getAirlineSameCompany';
import { getAirlineType } from '../../bi/utils/airline';
import { isSmartAgent } from '../../bi/utils/env';

import ANIMATION from '../../bi/constants/animation';
import CSSCLASS from '../../bi/constants/cssClass';
import FAVORITESACTION from '../../bi/constants/favorites';
import SYMBOLS from '../../bi/constants/symbols';
import CLASSNAMESFORTESTS from '../../bi/constants/classnamesfortests';
import { TRAVELPOLICYFILTER } from '../../bi/constants/travelPolicy';
import {
  AIRLINE_IDS,
  AIRLINE_IDS_W_CUSTOM_REFUND,
  OPERATINGEXCEPTIONS,
  AIRLINE_NAMES,
} from '../../bi/constants/airline';
import { BUYTRIPSACCOUNTRIGHT, BUYTRIPSPERSONALRIGHT } from '../../bi/constants/rights';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';

import {
  AirlineFare,
  IAirlineSearchItem,
  IAirlineSearchItemState,
  AirlineTravelPolicyInterface,
  AirlineSourceWithOriginalPrice,
} from '../../bi/types/airline';

import styles from './styles/index.module.css';

const TURKISH_CODES = ['TK4911', 'TK4910'];

const applyTravelPolicy = (item: AirlineFare | AirlineSourceWithOriginalPrice, selectedTravelPolicy: string) => {
  const hasTP = item.TravelPolicy && item.TravelPolicy.Apply;
  let selectedTP = false;

  if (hasTP) {
    if (selectedTravelPolicy !== TRAVELPOLICYFILTER.NOTAPPLIED) {
      selectedTP = !!item.TravelPolicy.Errors[selectedTravelPolicy];
    }
  }

  return hasTP && selectedTP;
};

const nameErrorsToDialogTP = (item: AirlineTravelPolicyInterface, selectedTravelPolicy: string) => ({
  [selectedTravelPolicy]: item.TravelPolicy.Errors[selectedTravelPolicy],
});

const mergeTravelPoliciesErrors = (a: Record<string, string>, b: Record<string, string>) => {
  const merged = {} as any;

  Object.keys(b).forEach((i) => {
    merged[i] = [b[i]];

    const fromA = a[i];

    if (fromA) {
      merged[i].push(fromA);
    }

    merged[i] = merged[i].join('');
  });

  return merged;
};

const LABELS = {
  BANKRUPTCY_DIALOG: getText('components:airlineSearchItem.attention'),
  FARE_MORE_BTN: getText('components:airlineSearchItem.moreBtn'),
  CANCEL: getText('components:airlineSearchItem.cancel'),
  WHATEVER_BUY: getText('components:airlineSearchItem.whateverBuy'),
  AIRLINE: getText('components:airlineSearchItem.airline'),
  AIRLINES: getText('components:airlineSearchItem.airlines'),
  ADDED: getText('components:airlineSearchItem.added'),
  TO_CART: getText('components:airlineSearchItem.toCart'),
  CHEAPEST: getText('components:airlineSearchItem.cheapest'),
  CHEAPEST_TICKETS: getText('components:airlineSearchItem.cheapestTicketWarning'),
  AVAILABLE: getText('components:airlineSearchItem.available'),
  EXPORT_FLIGHT: getText('components:airlineSearchItem.exportFlight'),
  C: 'С',
  CHOOSE_OPTION: getText('components:airlineSearchItem.chooseOption'),
  ADD_TO_CART: getText('common:addToCart'),
  BOOKMARK: {
    CHEAPEST: getText('components:airlineSearchItem.bookmark.cheapest'),
    FASTEST: getText('components:airlineSearchItem.bookmark.fastest'),
    CORPORATE: getText('components:airlineSearchItem.bookmark.corporate'),
    TOOLTIP: {
      MESSAGE: getText('components:airlineSearchItem.bookmark.tooltip'),
      AIRLINE_NAMES: {
        S7: getText('components:airlineSearchItem.bookmark.airlines.s7'),
        UNITED_AIRLINES: getText('components:airlineSearchItem.bookmark.airlines.unitedAirlines'),
      },
    },
  },
};

const getTooltipLabelForBookMark = (providers: string[]) => {
  if (providers.includes(AIRLINE_NAMES.S7)) return LABELS.BOOKMARK.TOOLTIP.MESSAGE;

  if (providers.includes(AIRLINE_NAMES.U6)) return LABELS.BOOKMARK.TOOLTIP.MESSAGE;

  return '';
};

const getBookMarkData = (bookmark: string, tooltip: string) => {
  switch (bookmark) {
    case 'Cheapest':
      return {
        text: LABELS.BOOKMARK.CHEAPEST,
        color: 'bluish',
      };
    case 'Fastest':
      return {
        text: LABELS.BOOKMARK.FASTEST,
        color: 'default',
      };
    case 'Recommended':
      return {
        text: LABELS.BOOKMARK.CORPORATE,
        color: 'second-green',
        labelTooltip: tooltip,
      };
  }

  return [];
};

type Colors = 'green' | 'red' | 'yellow';

class AirlineSearchItem extends Component<IAirlineSearchItem, IAirlineSearchItemState> {
  static defaultProps = {
    isComplex: false,
    hideBookmarks: false,
    onShowDetails: () => {},
    onChangeFare: () => {},
    onClickToLinkButton: () => {},
    qaAttrItemWrapper: '',
    qaAttrButton: '',
    qaAttrFavorite: '',
    qaAttrItem: null,
    isChangeAirTrip: false,
    priceFilter: 0,
  };

  constructor(props: IAirlineSearchItem) {
    super(props);
    const { item: { Fares, Routes }, selectedTravelPolicy, featureFlagsService, workspaceService } = props;

    const currentBonus = Fares[0].Bonus || null;
    const applyTP = applyTravelPolicy(Fares[0], selectedTravelPolicy);
    const { DisableToCartIfTPApply: disableToCartIfTPApply } = featureFlagsService.getFeatureFlags();
    const { travelPolicy } = workspaceService;

    this.state = {
      animationClass: '',
      currentFareId: Fares[0].Id,
      minPriceFare: Fares[0].Price.TotalPrice,
      currentBonus,
      currentPrice: Fares[0].Price.TotalPrice,
      showDetails: false,
      showTravelPolicyDialog: false,
      applyTPFromFare: applyTP,
      fareWithTp: Fares[0],
      sameAirlineCompany: getAirlineSameCompany(Routes),
      infoDialog: false,
      infoDialogForNote: false,
      disableToCartIfTPApply: disableToCartIfTPApply || false,
      accountTravelPolicy: travelPolicy,
      showHiddenFares: false,
      isShowOnlyInfoComplexDialog: false,
    };
  }

  UNSAFE_componentWillReceiveProps = (
    { item, selectedTravelPolicy }:{ item: AirlineSourceWithOriginalPrice, selectedTravelPolicy:string },
  ) => {
    const applyTP = applyTravelPolicy(item.Fares[0], selectedTravelPolicy);

    this.setState({
      currentFareId: item.Fares[0].Id,
      currentPrice: item.Fares[0].Price.TotalPrice,
      applyTPFromFare: applyTP,
      fareWithTp: item.Fares[0],
    });
  };

  handleAnimationEnd = () => this.setState({
    animationClass: '',
  });

  toggleInfoDialog = (forNote = false, value: boolean) => this.setState({
    infoDialog: value,
    infoDialogForNote: forNote,
  });

  toggleComplexInfo = () =>
    this.setState({
      isShowOnlyInfoComplexDialog: !this.state.isShowOnlyInfoComplexDialog,
    });

  hasComplexJoins = () => {
    const { item } = this.props;

    return item.OriginalTrips.length > item.Routes.length;
  };

  handleAddToCart = (
    ignoreComplexJoins = false,
    showDialog = false,
    price?: string | number,
  ) => {
    const { item, onAddToCart } = this.props;
    const { currentFareId } = this.state;
    const { Fares } = item;

    const priceTicket = Fares.find(({ Id }) => currentFareId === Id)?.Price.TotalPrice;

    const finalPrice = Number(price) || priceTicket;

    if (this.hasComplexJoins() && !ignoreComplexJoins) {
      this.toggleInfoDialog(false, showDialog);

      return;
    }

    onAddToCart(item, currentFareId, finalPrice);

    this.setState({
      animationClass: ANIMATION.MOVETOCART,
      showTravelPolicyDialog: false,
    });
  };

  handleAddToNote = (ignoreComplexJoins = false, showDialog = false) => {
    const { item, onAddToNotepad } = this.props;
    const { currentFareId } = this.state;

    if (this.hasComplexJoins() && !ignoreComplexJoins) {
      this.toggleInfoDialog(true, showDialog);

      return;
    }

    onAddToNotepad(item, currentFareId);

    this.setState({
      animationClass: ANIMATION.MOVETONOTE,
    });
  };

  handleAddToCartFromTP = () => {
    const showDialogComplex = this.hasComplexJoins() && !this.props.isComplex;

    if (showDialogComplex) {
      this.handleCloseTravelPolicyDialog();
    }

    return this.handleAddToCart(false, showDialogComplex);
  };

  handleChangeFavorite = (action: string) => {
    this.props.onAddToFavorite(this.props.item, action);

    if (action === FAVORITESACTION.ADD) {
      this.setState({
        animationClass: ANIMATION.PULSE,
      });
    }
  };

  handleShowSegments = () => this.setState({
    showDetails: !this.state.showDetails,
  }, () => {
    if (this.state.showDetails) {
      this.props.onShowDetails?.();
    }
  });

  handleFareClick = (fare: AirlineFare) => {
    const { item: { Fares }, selectedTravelPolicy } = this.props;
    const { Id, Price: { TotalPrice }, Bonus } = fare;

    const apply = applyTravelPolicy(fare, selectedTravelPolicy);

    this.setState({
      currentFareId: Id,
      currentPrice: TotalPrice,
      currentBonus: Bonus || null,
      applyTPFromFare: apply,
      fareWithTp: fare,
    }, () => {
      this.props.onChangeFare?.(fare, Fares);
    });
  };

  handleShowTravelPolicyDialog = () => this.setState({
    showTravelPolicyDialog: true,
  });

  handleCloseTravelPolicyDialog = () => this.setState({
    showTravelPolicyDialog: false,
  });

  handleTypeItem = (faresAmount: number, showDetails: boolean) => {
    if (faresAmount > 1 && !showDetails) {
      return 'FlexibleFareHide';
    }

    if (faresAmount === 1 && !showDetails) {
      return 'FixedFareHide';
    }

    return null;
  };

  handleSeparateSymbol = (faresAmount: number, showDetails: boolean) => {
    const typeItem = this.handleTypeItem(faresAmount, showDetails);

    switch (typeItem) {
      case 'FlexibleFareHide':
        return SYMBOLS.COMMA;
      case 'FixedFareHide':
        return SYMBOLS.DOT;
      default:
        return null;
    }
  };

  getAirlineOfSeatMapsTooltipForDemo = () => {
    const { workspaceService, item } = this.props;

    // TODO: вернуть обратно, когда попросит бизнес(это временно)
    if (!workspaceService.isDemo || workspaceService.isDemo) {
      return '';
    }

    const segments = item.Routes.flatMap(({ Segments }) => Segments);
    const firstSegmentsAirline = segments[0].OperatingAirline.ID;

    if (firstSegmentsAirline === AIRLINE_IDS.GLOBUS || firstSegmentsAirline === AIRLINE_IDS.S7) {
      const someSegmentsIsNotByS7 = segments.some(
        ({ OperatingAirline: { ID } }) => ID !== AIRLINE_IDS.GLOBUS && ID !== AIRLINE_IDS.S7);

      return someSegmentsIsNotByS7 ? '' : AIRLINE_IDS.S7;
    }

    if (firstSegmentsAirline === AIRLINE_IDS.POBEDA) {
      const someSegmentsIsNotByPobeda = segments.some(
        ({ OperatingAirline: { ID } }) => ID !== AIRLINE_IDS.POBEDA);

      return someSegmentsIsNotByPobeda ? '' : AIRLINE_IDS.POBEDA;
    }

    return '';
  };

  setShowHiddenFares = () => this.setState({ showHiddenFares: true });

  renderHeader = () => {
    const {
      item,
      travelPolicyList,
      isComplex,
      selectedTravelPolicy,
      onGetLink,
      onClickToLinkButton,
      onCopyLink,
      qaAttrItem,
      index,
      isChangeAirTrip,
    } = this.props;
    const { applyTPFromFare, fareWithTp } = this.state;

    const firstEl = 0;
    const qaAttrFavorite = index === firstEl ? qaAttrItem?.toFavorite : '';
    const qaAttrNote = index === firstEl ? qaAttrItem?.note : '';

    const applyTP = applyTravelPolicy(applyTPFromFare ? fareWithTp : item, selectedTravelPolicy);
    let currentItem = item;
    let currentApplyTP = applyTP;

    const applyTPOrFromFare = applyTP || applyTPFromFare;
    const complex = this.hasComplexJoins() && !isComplex;

    if (applyTP && fareWithTp && applyTPFromFare) {
      currentItem = {
        ...item,
        TravelPolicy: {
          ...fareWithTp.TravelPolicy,
          Errors: mergeTravelPoliciesErrors(item.TravelPolicy.Errors, fareWithTp.TravelPolicy.Errors),
        },
      };
      currentApplyTP = applyTPFromFare;
    }

    const favoriteHtml = !isSmartAgent && !isComplex && !isChangeAirTrip ? (
      <FavoriteAction
        id={ item.FavoriteId }
        disabled={ currentApplyTP }
        className={ `${styles.link} ${CLASSNAMESFORTESTS.AIRLINES.TOFAVORITE}` }
        onClick={ this.handleChangeFavorite }
        qaAttrIcon={ qaAttrFavorite || '' }
        qaAttrDialog={ QA_ATTRIBUTES.favorites.deleteDialog }
      />
    ) : null;

    const toNoteHtml = !isChangeAirTrip && (
      <ToNote
        disabled={ applyTPOrFromFare }
        className={ `${styles.link} ${CLASSNAMESFORTESTS.AIRLINES.TONOTEPAD}` }
        onClick={ () => this.handleAddToNote(false, complex) }
        qaAttr={ qaAttrNote || '' }
      />
    );

    const linkHtml = !isComplex ? (
      <LinkAction
        item={ item }
        className={ `${styles.get_link} ${CLASSNAMESFORTESTS.AIRLINES.GETLINK}` }
        generateLink={ onGetLink }
        onCopyToClipboard={ onCopyLink }
        onClick={ onClickToLinkButton }
      />
    ) : null;

    return (
      <div className={ styles.header }>
        <div className={ styles.actions }>
          <NameErrorsTravelPolicy
            oneTravelPolicy
            hiddenNames
            item={ currentItem }
            applyTP={ currentApplyTP }
            travelPolicyList={ travelPolicyList }
            selectedTravelPolicy={ selectedTravelPolicy }
          />
          <div className={ styles.options }>
            { linkHtml }
            { toNoteHtml }
            { favoriteHtml }
          </div>
        </div>
      </div>
    );
  };

  renderAirItem = (isFirstEl: boolean) => {
    const { item, onUpdateCheckbox, qaAttrItem } = this.props;
    const { showDetails } = this.state;

    const countRoutes = item.Routes.length > 1;

    return item.Routes.map((route, index) => (
      <AirItem
        key={ `airline_route_${guid()}_${index}` }
        isFirstEl={ isFirstEl }
        lastChild={ index === (item.Routes.length - 1) }
        manyRoutes={ item.Routes.length > 1 }
        index={ index }
        route={ route }
        countRoutes={ countRoutes }
        showDetails={ showDetails }
        onUpdateCheckbox={ onUpdateCheckbox }
        onShowSegments={ this.handleShowSegments }
        hasComplexJoins={ this.hasComplexJoins() }
        seatMapAirline={ this.getAirlineOfSeatMapsTooltipForDemo() }
        qaAttrs={ qaAttrItem }
      />
    ));
  };

  renderAttentionText = () => {
    const { item } = this.props;

    const mappedCompany = item.Routes.map(({ Segments }) => Segments.map((segment) => {
      const airlineType = getAirlineType(segment);

      return `${segment[airlineType].ID}${segment.FlightNumber}`;
    }));

    const finderTurkishCompany = mappedCompany.some(i => i.find(companyName => TURKISH_CODES.includes(companyName)));

    return finderTurkishCompany && (
      <div className={ styles.attention }>
        <Text type='SEMIBOLD_16' color='red'>{ LABELS.EXPORT_FLIGHT }</Text>
      </div>
    );
  };

  renderPriceTooltip = (isDetails = false) => {
    const { item, travellers } = this.props;
    const AirlinePrice = item.Fares
      .find(({ Id }) => this.state.currentFareId === Id)?.Price;

    const defaultPrice = {
      Base: 0,
      Commission: 0,
      Fee: 0,
      Tax: 0,
      Taxes: 0,
      TotalPrice: 0,
      VAT: 0,
    };

    const price = AirlinePrice || defaultPrice;

    return (
      <TooltipText
        price={ price }
        travellers={ travellers }
        isDetails={ isDetails }
      />
    );
  };

  renderFareMore = (isHiddenFares: boolean) => {
    if (!isHiddenFares) return null;

    return (
      <IconButton
        disabled={ false }
        iconType='arrowsDownDimblue'
        onClick={ this.setShowHiddenFares }
        iconSide='right'
      >
        { LABELS.FARE_MORE_BTN }
      </IconButton>
    );
  };

  renderFareItems = (qaAttrItemsWrapper = '') => {
    const { item, isChangeAirTrip, appService } = this.props;
    const { currentFareId, currentPrice, showHiddenFares } = this.state;

    const { agentMode } = appService.get();

    const customRefundabilityAndExchangeability = AIRLINE_IDS_W_CUSTOM_REFUND.includes(item.Routes[0].Segments[0].MarketingAirline.ID);

    let isHiddenFares = false;

    const fareHtml = item.Fares.reduce<ReactElement[]>((result, fare, ind) => {
      const tagAdditional = fare.Tags?.includes('additional');

      if (tagAdditional && !showHiddenFares) {
        isHiddenFares = true;

        return result;
      }

      // const applyTP = applyTravelPolicy(fare, selectedTravelPolicy);

      const currentFare = (
        <FareItem
          customRefundabilityAndExchangeability={ customRefundabilityAndExchangeability }
          key={ `airline_fare_${guid()}_${ind}` }
          fare={ fare }
          currentFareId={ currentFareId }
          currentPrice={ currentPrice }
          // Не используется тут
          // warning={ applyTP }
          isChangeAirTrip={ isChangeAirTrip }
          onFareClick={ (value: AirlineFare) => this.handleFareClick(value) }
          agentMode={ agentMode }
        />
      );

      result.push(currentFare);

      return result;
    }, []);

    return (
      <>
        <div className={ styles.fare } data-qa={ qaAttrItemsWrapper }>
          { fareHtml }
        </div>
        { this.renderFareMore(isHiddenFares) }
      </>
    );
  };

  renderFaresDetails = () => {
    const { showDetails, currentFareId } = this.state;
    const { item: { Fares, Routes } } = this.props;

    const findFares = Fares.find(fare => currentFareId === fare.Id);
    const separateSymbol = this.handleSeparateSymbol(Fares.length, showDetails);

    const customRefundabilityAndExchangeability = AIRLINE_IDS_W_CUSTOM_REFUND.includes(Routes[0].Segments[0].MarketingAirline.ID);

    return !!findFares && (
      <Text className={ styles.fare_details_wrapper } type='NORMAL_14_130'>
        <FareDetails
          customRefundabilityAndExchangeability={ customRefundabilityAndExchangeability }
          title={ showDetails }
          fare={ findFares }
          className={ !showDetails ? styles.fare_details : '' }
          separateSymbol={ separateSymbol }
        />
      </Text>
    );
  };

  renderTravelPolicyDialog = () => {
    const { item, selectedTravelPolicy, isChangeAirTrip } = this.props;
    const { applyTPFromFare, fareWithTp, showTravelPolicyDialog } = this.state;

    let errors = null;

    const label = isChangeAirTrip ? LABELS.CHOOSE_OPTION : LABELS.ADD_TO_CART;

    if (applyTPFromFare) {
      const mergedTp = {
        TravelPolicy: {
          ...fareWithTp.TravelPolicy,
          Errors: mergeTravelPoliciesErrors(item.TravelPolicy.Errors, fareWithTp.TravelPolicy.Errors),
        },
      };

      errors = nameErrorsToDialogTP({ ...fareWithTp, ...mergedTp }, selectedTravelPolicy);
    } else {
      errors = nameErrorsToDialogTP(item, selectedTravelPolicy);
    }

    return (
      <TravelPolicyDialog
        show={ showTravelPolicyDialog }
        errors={ errors }
        onClose={ this.handleCloseTravelPolicyDialog }
        onAdd={ this.handleAddToCartFromTP }
        label={ label }
      />
    );
  };

  renderTravellersAmount = (qaAttr = '') => {
    const { item: { Fares }, travellers, appService } = this.props;
    const { agentMode } = appService.get();

    if (travellers <= 1 || (isSmartAgent && !agentMode)) return null;

    return (
      <TravellersAmount
        travellers={ travellers }
        currentFareId={ this.state.currentFareId }
        fares={ Fares }
        qaAttr={ qaAttr }
      />
    );
  };

  renderBonus = () => {
    const { currentBonus, sameAirlineCompany } = this.state;
    const { workspaceService: { isDemo } } = this.props;

    if (!currentBonus || !sameAirlineCompany || (isDemo && isSmartAgent)) return null;

    return (
      <div className={ styles.bonus }>
        <AirlineBonus
          bonus={ currentBonus }
          sameAirlineCompany={ sameAirlineCompany }
        />
      </div>
    );
  };

  renderInfoDialog = () => {
    const { infoDialogForNote, infoDialog } = this.state;
    const transferTime = Math.floor(this.props.item.ChangeDuration / 3600);

    return (
      <InfoDialog
        show={ infoDialog }
        forNote={ infoDialogForNote }
        onAddToCart={ this.handleAddToCart }
        onAddToNote={ this.handleAddToNote }
        warningShortTransferTime={ transferTime < 3 }
      />
    );
  };

  renderComplex = () => {
    const { isShowOnlyInfoComplexDialog } = this.state;
    const transferTime = Math.floor(this.props.item.ChangeDuration / 3600);

    return (
      <Complex
        show={ isShowOnlyInfoComplexDialog }
        onClick={ this.toggleComplexInfo }
        warningShortTransferTime={ transferTime < 3 }
      />
    );
  };

  renderAFlag = () => (
    <RoundIconWithTooltip
      label={ LABELS.C }
      className={ styles.a_flag }
    />
  );

  renderTemperatureSection = () => {
    const { fareWithTp: { Tags } } = this.state;

    if (!Tags) return null;

    const color = Tags
      .find(tagColor => tagColor === 'green' || tagColor === 'red' || tagColor === 'yellow') as Colors
      || 'default';

    if (!color) return null;

    return (
      <div className={ styles['temperature-wrapper'] }>
        <div className={ `${styles.background} ${styles[color]}` } />
        <Icon type='rub' color={ color } wrapperClassName={ styles.icon } />
      </div>
    );
  };

  renderAvailableCount = () => {
    const { item, item: { Routes }, isChangeAirTrip } = this.props;

    const availableCount = Math.min.apply(null, Routes.map(route => (
      Math.min.apply(null, route.Segments.map(segment => segment.AvailableSeats as number))
    )));

    const visible = !isChangeAirTrip && item.Routes[0].Segments[0].MarketingAirline.ID !== OPERATINGEXCEPTIONS.FV;

    return visible && (
      <Text className={ styles.available } type='NORMAL_14' color='gray'>
        { LABELS.AVAILABLE } { availableCount }
      </Text>
    );
  };

  renderPriceOrChange = () => {
    const {
      index,
      item,
      preventDuplication,
      selectedTravelPolicy,
      isComplex,
      workspaceService,
      qaAttrButton,
      qaAttrItem,
      isChangeAirTrip,
      onAddToCart,
      appService,
    } = this.props;

    const {
      currentFareId,
      disableToCartIfTPApply,
      accountTravelPolicy,
      applyTPFromFare,
      fareWithTp,
    } = this.state;

    const {
      Fares,
      Id,
    } = item;

    const isFirstEl = index === 0;
    const qaAttrFirstItemButton = isFirstEl ? qaAttrButton : '';
    const qaAttrPrice = qaAttrItem?.price || '';
    const qaAttrTravellersAmount = isFirstEl ? qaAttrItem?.travellersAmount : '';
    const { agentMode } = appService.get();

    const { IsDemo, Rights: { BuyTripPersonal, BuyTripAccount } } = workspaceService.get();
    const tpRights = (BuyTripPersonal === BUYTRIPSPERSONALRIGHT.TravelPolicy || BuyTripPersonal === BUYTRIPSPERSONALRIGHT.ApprovalScheme) && BuyTripAccount === BUYTRIPSACCOUNTRIGHT.Unavailable;

    const price = Fares.find(fare => currentFareId === fare.Id)?.Price.TotalPrice || 0;
    const agentFee = Fares.find(fare => currentFareId === fare.Id)?.Price.AgentFee || '';
    const applyTP = applyTravelPolicy(applyTPFromFare ? fareWithTp : item, selectedTravelPolicy);

    const alreadyInCart = preventDuplication.includes(Id);
    const label = alreadyInCart ? LABELS.ADDED : LABELS.TO_CART;

    const optionsHtml = IsDemo ? this.renderBonus() : this.renderTravellersAmount(qaAttrTravellersAmount);
    const valuesErrorTp = fareWithTp.TravelPolicy.Errors && accountTravelPolicy ?
      Object.entries(fareWithTp.TravelPolicy.Errors).find(k => k[0] === accountTravelPolicy.Id && k[1].includes(` ${LABELS.CHEAPEST} `)) :
      [];
    const disabledRuleTp = disableToCartIfTPApply && applyTP && (tpRights && !!valuesErrorTp);
    const disabledRules = disabledRuleTp || alreadyInCart;

    const handelClickDefault = () => {
      const complex = this.hasComplexJoins() && !isComplex;

      if (applyTP) return this.handleShowTravelPolicyDialog();

      return this.handleAddToCart(false, complex, price);
    };

    const handelClickChangeAirTrip = () => {
      if (applyTP) return this.handleShowTravelPolicyDialog();

      return onAddToCart(item, currentFareId);
    };

    const typeButton = applyTP ? 'secondary-outline' : 'secondary';

    const renderAgentFee = () => {
      if (!isSmartAgent) {
        return null;
      }

      return (
        <div className={ styles.agency }>
          <AgencyFee
            fee={ agentFee }
            agentMode={ agentMode }
          />
        </div>
      );
    };

    if (isChangeAirTrip) {
      return (
        <Button
          className={ `${styles.button} ${styles.change}` }
          type={ typeButton }
          disabled={ disabledRules }
          onClick={ handelClickChangeAirTrip }
          qaAttr={ qaAttrFirstItemButton }
        >
          { LABELS.CHOOSE_OPTION }
        </Button>
      );
    }

    const pricePrepared = !agentMode ? Number(price) + Number(agentFee) : price;

    return (
      <>
        <Tooltip
          position='left'
          show={ Config.SHOW_PRICE_DETAILS }
          renderContent={ () => this.renderPriceTooltip(true) }
        >
          <Price type='bold_24' value={ pricePrepared } qaAttr={ qaAttrPrice } marginSmall />
        </Tooltip>
        {optionsHtml}
        <Tooltip
          show={ disabledRuleTp }
          renderContent={ () => (
            <div className={ styles['tooltip-content'] }>
              <Text color='white' type='NORMAL_14_130'>
                { LABELS.CHEAPEST_TICKETS }
              </Text>
            </div>
          ) }
        >
          <Button
            className={ styles.button }
            disabled={ disabledRules }
            type={ typeButton }
            onClick={ handelClickDefault }
            qaAttr={ qaAttrFirstItemButton }
          >
            { label }
          </Button>
        </Tooltip>
        { renderAgentFee() }
      </>
    );
  };

  render() {
    const {
      index,
      item,
      selectedTravelPolicy,
      isComplex,
      hideBookmarks,
      priceFilter,
      qaAttrItemWrapper,
      qaAttrFavorite,
      qaAttrItem,
    } = this.props;

    const {
      animationClass,
      showDetails,
      showTravelPolicyDialog,
      applyTPFromFare,
      fareWithTp,
      minPriceFare,
    } = this.state;

    const {
      Fares,
      FavoriteId,
      AFlag,
      Tags,
      Routes,
    } = item;

    const isFirstEl = index === 0;
    const qaAttrShowSegments = isFirstEl ? qaAttrItem?.showSegments : '';
    const qaAttrFareItems = isFirstEl ? qaAttrItem?.fareItems : '';

    const manyFares = Fares.length > 1;
    const faresHtml = manyFares && this.renderFareItems(qaAttrFareItems);

    const applyTP = applyTravelPolicy(applyTPFromFare ? fareWithTp : item, selectedTravelPolicy);

    const travelPolicyDialogHtml = showTravelPolicyDialog ? this.renderTravelPolicyDialog() : null;

    const classNameFares = [styles.wrapper_fare];

    if (manyFares && !showDetails) {
      classNameFares.push(styles.flexible);
    }

    const connectedFlightsFlag = item.OriginalTrips && item.OriginalTrips.map(i => i.AFlag);
    const aFlag = AFlag || connectedFlightsFlag.includes(true);
    const aFlagHtml = aFlag && this.renderAFlag();
    const typeIcon = showDetails ? 'arrowsUpRound' : 'arrowsDownRound';
    const complexHtml = this.hasComplexJoins() && !isComplex && this.renderComplex();
    const isFavoriteId = !isSmartAgent && !!FavoriteId;
    const stylesFavorite = isFavoriteId ? CSSCLASS.FAVORITEWRAP : '';

    const airlineNames = Routes.map(({ Segments }) => Segments.map(({ MarketingAirline: { Code } }) => Code)).flat();
    const tooltipBookmark = getTooltipLabelForBookMark(airlineNames);
    const hideTags = hideBookmarks || (priceFilter ?? 0) > minPriceFare;
    const bookMarks: BookMarkProps[] = !hideTags
      ? Tags.map(tag => getBookMarkData(tag, tooltipBookmark) as BookMarkProps)
      : [];

    return (
      <ItemPanel
        index={ index }
        warning={ applyTP }
        favorite={ isFavoriteId }
        animationClass={ animationClass }
        className={ styles.item_panel }
        onAnimationEnd={ this.handleAnimationEnd }
        renderHeader={ this.renderHeader }
        qaAttr={ qaAttrItemWrapper }
        qaAttrFavorite={ qaAttrFavorite }
        bookmarks={ bookMarks }
      >
        <div className={ `${styles.wrapper_item} ${stylesFavorite}` }>
          <div className={ styles.item }>
            { this.renderAirItem(isFirstEl) }
            { complexHtml }
            { this.renderInfoDialog() }
            <div className={ classNameFares.join(' ') }>
              { faresHtml }
              { this.renderFaresDetails() }
              { this.renderAttentionText() }
            </div>
            <div className={ styles.arrow }>
              <IconButton
                className={ styles.icon }
                iconType={ typeIcon }
                onClick={ this.handleShowSegments }
                alternativeDesign={ isSmartAgent }
                qaAttr={ qaAttrShowSegments || '' }
              />
            </div>
          </div>
          <div className={ styles.action }>
            { this.renderTemperatureSection() }
            <div className={ styles.paddings }>
              { aFlagHtml }
              <div className={ styles.price }>
                { this.renderPriceOrChange() }
              </div>
              { this.renderAvailableCount() }
            </div>
          </div>
        </div>
        { travelPolicyDialogHtml }
      </ItemPanel>
    );
  }
}

export default AirlineSearchItem;
