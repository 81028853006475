import React from 'react';
import { Button, Dialog, Text } from 'new-ui';

import { getText } from '../../../../../i18n';

import { APPROVAL_SCHEME_CONDITION_OF_APPROVAL_TYPES } from '../../../../bi/constants/approvalSchemes';

import styles from './styles.module.css';

const LABELS = {
  HEADER: getText('approvalScheme:step.conditionOfApprovalDialog.header'),
  EVERY: getText('approvalScheme:step.conditionOfApprovalDialog.every'),
  ONLY_ONE: getText('approvalScheme:step.conditionOfApprovalDialog.onlyOne'),
  TEXT: {
    TITLE: getText('approvalScheme:step.conditionTypeDialog.title'),
  },
};

interface IConditionalDialog {
  show: boolean;
  onClick: (value: number) => void;
}

const ConditionalDialog = ({
  show,
  onClick,
}: IConditionalDialog) => {
  const content = (
    <>
      <Text
        className={ styles.header }
        type='bold_20'
      >
        { LABELS.HEADER }
      </Text>
      <Text>
        { LABELS.TEXT.TITLE }
      </Text>
    </>
  );

  const actions = (
    <div className={ styles.actions }>
      <Button
        className={ styles.button }
        onClick={ () => onClick(APPROVAL_SCHEME_CONDITION_OF_APPROVAL_TYPES.EVERY.value) }
      >
        { LABELS.EVERY }
      </Button>
      <Button
        className={ styles.button }
        onClick={ () => onClick(APPROVAL_SCHEME_CONDITION_OF_APPROVAL_TYPES.ONLY_ONE_SEND_CHOSEN.value) }
      >
        { LABELS.ONLY_ONE }
      </Button>
    </div>
  );

  return (
    <Dialog
      show={ show }
      outsideClickClosing={ false }
      onChange={ () => {} }
    >
      <div className={ styles.dialog }>
        { content }
        { actions }
      </div>
    </Dialog>
  );
};

export { ConditionalDialog };
