import { Moment } from 'moment';
import { observable, computed, action, makeObservable } from 'mobx';
import { getText } from '../../../../../i18n';

import MoneyFormat from '../../../utils/money';
import parseUnix from '../../../utils/parseDateTime';
import { checkTrainNumberOrName, checkTrainNumberOrNameTransfer } from '../../../utils/train';
import textTransformation from '../../../utils/textTransformation';
import { firstSymbolUpperCase } from '../../../utils/text';
import { isSapsan } from '../corporateTrains/sapsan';

import { QA_ATTRIBUTES } from '../../../constants/attributesForTests';
import { TRAVELPOLICYFILTER } from '../../../constants/travelPolicy';
import { BUYTRIPSPERSONALRIGHT, BUYTRIPSACCOUNTRIGHT } from '../../../constants/rights';
import {
  FilterType,
  FilterKey,
  SortBy,
  TrainSortableFields,
} from '../../../constants/train';

import { TravelPolicyItem } from '../../userSession/types';
import {
  IArrSourcesWithTransfer, ICar,
  IFavorite,
  IFilters,
  IInternalSearchResult,
  IInternalSearchTrain,
  IPriceFilter,
  ISettingsBuyTrain,
  ITransfers,
  ITransfersFilter,
  ITypeCar,
  ITypeValue, IVariantWithTransfer,
  TimeFilterType,
} from '../../../types/trains';
import { ITag } from '../../../types/shared';

const LABELS = {
  PRICE_FROM_TO: (from: string, to: string) => getText('services:trains.store.tickets.tags.priceFromTo', { from, to }),
  DEPARTURE_FROM_TO: (from: string, to: string) => getText('services:trains.store.tickets.tags.departureFromTo', { from, to }),
  ARRIVAL_FROM_TO: (from: string, to: string) => getText('services:trains.store.tickets.tags.arrivalFromTo', { from, to }),
  TRAIN_NUMBER: (number: string) => getText('services:trains.store.tickets.tags.trainNumber', { number }),
  TRAIN_NAME: (name: string) => getText('services:trains.store.tickets.tags.trainName', { name }),
  FAVORITES: getText('services:trains.store.tickets.tags.favorites'),
  SAPSAN: getText('constants:train.trainNames.sapsan'),
};

const DEFAULT_FILTERS = {
  type: {},
  trainNumberOrName: '',
  isSapsan: false,
  price: {
    border: {
      from: 0,
      to: 0,
    },
    roundBy: 500,
    from: 0,
    to: 0,
  },
  time: {
    departure: {
      border: {
        from: 0,
        to: 0,
      },
      from: 0,
      to: 0,
    },
    arrival: {
      border: {
        from: 0,
        to: 0,
      },
      from: 0,
      to: 0,
    },
  },
  travelPolicyList: [],
  selectedTravelPolicy: TRAVELPOLICYFILTER.NOTAPPLIED,
  number: '',
  date: '',
  favoriteId: false,
  transfers: {},
};

export type TTrainTicketsStore = typeof TrainTicketsStore;

interface ISetTicketsListArgs {
  search: IInternalSearchResult;
  settings: ISettingsBuyTrain;
  filters?: any;
  favorite?: IFavorite;
}

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable sources: IInternalSearchTrain[] = [];
  @observable sourcesWithTransfer: IArrSourcesWithTransfer = [];
  @observable tickets: IInternalSearchTrain[] = [];
  @observable ticketsWithTransfer: IArrSourcesWithTransfer = [];
  @observable tags: ITag[] = [];

  @observable subMenu: boolean = false;
  @observable isAnyFavorite: boolean = false;
  @observable unavailableTravelPolicy: boolean = false;

  @observable sortBy: SortBy = SortBy.DEPARTURE_UP;
  @observable travelPolicyAllList: TravelPolicyItem[] = [];
  @observable favorite: IFavorite = {
    number: null,
    date: null,
  };
  @observable searchId: null | string = null;
  @observable filters: IFilters = DEFAULT_FILTERS;

  @computed
  get sortedTickets(): IInternalSearchTrain[] {
    let order = 1;
    let field = '';

    const favoritesTrainsWithoutTP: IInternalSearchTrain[] = [];
    const favoritesTrainsWithTP: IInternalSearchTrain[] = [];
    const otherTrainsWithoutTP: IInternalSearchTrain[] = [];
    const otherTrainsWithTP: IInternalSearchTrain[] = [];
    const trainsWithoutTravelPolicy: IInternalSearchTrain[] = [];
    const trainsWithTravelPolicy: IInternalSearchTrain[] = [];

    this.applyFilter.forEach((item) => {
      if (
        item.TravelPolicy.Apply &&
        this.filters.selectedTravelPolicy !== TRAVELPOLICYFILTER.NOTAPPLIED &&
        typeof (item.TravelPolicy.Errors[this.filters.selectedTravelPolicy]) !== 'undefined'
      ) {
        trainsWithTravelPolicy.push(item);

        return;
      }

      trainsWithoutTravelPolicy.push(item);
    });

    if (trainsWithoutTravelPolicy.length > 0) {
      trainsWithoutTravelPolicy.forEach((item) => {
        if (item.FavoriteId) {
          favoritesTrainsWithoutTP.push(item);
        } else {
          otherTrainsWithoutTP.push(item);
        }
      });
    }

    if (trainsWithTravelPolicy.length > 0) {
      trainsWithTravelPolicy.forEach((item) => {
        if (item.FavoriteId) {
          favoritesTrainsWithTP.push(item);
        } else {
          otherTrainsWithTP.push(item);
        }
      });
    }

    switch (this.sortBy) {
      case SortBy.DURATION_UP: {
        order = 1;
        field = 'TravelTime';
        break;
      }

      case SortBy.DURATION_DOWN: {
        order = -1;
        field = 'TravelTime';
        break;
      }

      case SortBy.ARRIVAL_UP: {
        order = 1;
        field = 'ArrivalDate';
        break;
      }

      case SortBy.ARRIVAL_DOWN: {
        order = -1;
        field = 'ArrivalDate';
        break;
      }

      case SortBy.DEPARTURE_UP: {
        order = 1;
        field = 'DepartureDate';
        break;
      }

      case SortBy.DEPARTURE_DOWN: {
        order = -1;
        field = 'DepartureDate';
        break;
      }

      case SortBy.PRICE_UP: {
        order = 1;
        field = 'Price';
        break;
      }

      case SortBy.PRICE_DOWN: {
        order = -1;
        field = 'Price';
        break;
      }
    }

    const priceSort = (first: IInternalSearchTrain, second: IInternalSearchTrain): number => {
      const firstMin = Math.min.apply(null, first.Cars.map((car) => car.MinimalCost.Total));
      const secondMin = Math.min.apply(null, second.Cars.map((car) => car.MinimalCost.Total));

      if (firstMin > secondMin) return order;

      if (firstMin < secondMin) return -order;

      return 0;
    };

    const defaultFn = ((first: IInternalSearchTrain, second: IInternalSearchTrain): number => {
      const fieldKey = field as TrainSortableFields;

      // @ts-ignore
      if (first[fieldKey] > second[fieldKey]) return order;

      // @ts-ignore
      if (first[fieldKey] < second[fieldKey]) return -order;

      return 0;
    });

    const travelTimeSort = ((first: IInternalSearchTrain, second: IInternalSearchTrain): number => {
      const fieldKey = field as TrainSortableFields;

      // @ts-ignore
      if (first[fieldKey].length > second[fieldKey].length) return order;

      // @ts-ignore
      if (first[fieldKey].length < second[fieldKey].length) return -order;

      // @ts-ignore
      if (first[fieldKey] > second[fieldKey]) return order;

      // @ts-ignore
      if (first[fieldKey] < second[fieldKey]) return -order;

      return 0;
    });

    let result = [];
    switch (field) {
      case 'Price': {
        result = [
          ...favoritesTrainsWithoutTP.sort(priceSort),
          ...otherTrainsWithoutTP.sort(priceSort),
          ...favoritesTrainsWithTP.sort(priceSort),
          ...otherTrainsWithTP.sort(priceSort),
        ];
        break;
      }

      case 'TravelTime': {
        result = [
          ...favoritesTrainsWithoutTP.sort(travelTimeSort),
          ...otherTrainsWithoutTP.sort(travelTimeSort),
          ...favoritesTrainsWithTP.sort(travelTimeSort),
          ...otherTrainsWithTP.sort(travelTimeSort),
        ];
        break;
      }

      default: {
        result = [
          ...favoritesTrainsWithoutTP.sort(defaultFn),
          ...otherTrainsWithoutTP.sort(defaultFn),
          ...favoritesTrainsWithTP.sort(defaultFn),
          ...otherTrainsWithTP.sort(defaultFn),
        ];
      }
    }

    return result;
  }

  @computed
  get sortedWithTransferTickets(): IArrSourcesWithTransfer {
    let order = 1;
    let fn: ((first: IVariantWithTransfer[], second: IVariantWithTransfer[]) => number) = () => 0;

    const { trainsWithoutTravelPolicy = [], trainsWithTravelPolicy = [] } =
      this.applyFilterTransferTickets.reduce<{
        trainsWithoutTravelPolicy: IArrSourcesWithTransfer;
        trainsWithTravelPolicy: IArrSourcesWithTransfer;
      }>((acc, trains) => {
        const isTP = trains[0].Trains.some(({ TravelPolicy }) =>
          TravelPolicy.Apply && TravelPolicy.Errors[this.filters.selectedTravelPolicy] !== 'undefined',
        ) && this.filters.selectedTravelPolicy !== TRAVELPOLICYFILTER.NOTAPPLIED;

        if (isTP) {
          return {
            trainsWithoutTravelPolicy: acc.trainsWithoutTravelPolicy,
            trainsWithTravelPolicy: [...acc.trainsWithTravelPolicy, trains],
          };
        }

        return {
          trainsWithoutTravelPolicy: [...acc.trainsWithoutTravelPolicy, trains],
          trainsWithTravelPolicy: acc.trainsWithTravelPolicy,
        };
      }, { trainsWithoutTravelPolicy: [], trainsWithTravelPolicy: [] });

    const { favoritesTrainsWithoutTP = [], otherTrainsWithoutTP = [] } = trainsWithoutTravelPolicy.reduce<{
      favoritesTrainsWithoutTP: IArrSourcesWithTransfer,
      otherTrainsWithoutTP: IArrSourcesWithTransfer,
    }>((acc, trains) => {
      if (trains[0].Trains.every(({ FavoriteId }) => FavoriteId)) {
        return {
          favoritesTrainsWithoutTP: [...acc.favoritesTrainsWithoutTP, trains],
          otherTrainsWithoutTP: acc.otherTrainsWithoutTP,
        };
      }

      return {
        favoritesTrainsWithoutTP: acc.favoritesTrainsWithoutTP,
        otherTrainsWithoutTP: [...acc.otherTrainsWithoutTP, trains],
      };
    }, { favoritesTrainsWithoutTP: [], otherTrainsWithoutTP: [] });

    const { favoritesTrainsWithTP = [], otherTrainsWithTP = [] } = trainsWithTravelPolicy.reduce<{
      favoritesTrainsWithTP: IArrSourcesWithTransfer,
      otherTrainsWithTP: IArrSourcesWithTransfer,
    }>((acc, trains) => {
      if (trains[0].Trains.every(({ FavoriteId }) => FavoriteId)) {
        return {
          favoritesTrainsWithTP: [...acc.favoritesTrainsWithTP, trains],
          otherTrainsWithTP: acc.otherTrainsWithTP,
        };
      }

      return {
        favoritesTrainsWithTP: acc.favoritesTrainsWithTP,
        otherTrainsWithTP: [...acc.otherTrainsWithTP, trains],
      };
    }, { favoritesTrainsWithTP: [], otherTrainsWithTP: [] });

    const priceSort = (
      first: IVariantWithTransfer[],
      second: IVariantWithTransfer[],
    ): number => {
      const price = (list: IVariantWithTransfer[]) => list[0].Classes.map(({ Price }) => Price);

      const firstMin = Math.min.apply(null, price(first));
      const secondMin = Math.min.apply(null, price(second));

      if (firstMin > secondMin) return order;

      if (firstMin < secondMin) return -order;

      return 0;
    };

    const durationSort = (
      first: IVariantWithTransfer[],
      second: IVariantWithTransfer[],
    ): number => {
      const time = (list: IVariantWithTransfer[]) => list[0].TripDuration + list[0].Trains
        .reduce((acc, { ChangeDuration }) => acc + ChangeDuration, 0);

      if (time(first) > time(second)) return order;

      if (time(first) < time(second)) return -order;

      return 0;
    };

    const departureDateSort = (
      first: IVariantWithTransfer[],
      second: IVariantWithTransfer[],
    ): number => {
      const date = (list: IVariantWithTransfer[]) => list[0].Trains[0].DepartureDate;

      if (date(first) > date(second)) return order;

      if (date(first) < date(second)) return -order;

      return 0;
    };

    const arrivalDateSort = (
      first: IVariantWithTransfer[],
      second: IVariantWithTransfer[],
    ): number => {
      const date = (list: IVariantWithTransfer[]) => list[0].Trains[list[0].Trains.length - 1].ArrivalDate;

      if (date(first) > date(second)) return order;

      if (date(first) < date(second)) return -order;

      return 0;
    };

    switch (this.sortBy) {
      case SortBy.DURATION_UP: {
        order = 1;
        fn = durationSort;
        break;
      }

      case SortBy.DURATION_DOWN: {
        order = -1;
        fn = durationSort;
        break;
      }

      case SortBy.ARRIVAL_UP: {
        order = 1;
        fn = arrivalDateSort;
        break;
      }

      case SortBy.ARRIVAL_DOWN: {
        order = -1;
        fn = arrivalDateSort;
        break;
      }

      case SortBy.DEPARTURE_UP: {
        order = 1;
        fn = departureDateSort;
        break;
      }

      case SortBy.DEPARTURE_DOWN: {
        order = -1;
        fn = departureDateSort;
        break;
      }

      case SortBy.PRICE_UP: {
        order = 1;
        fn = priceSort;
        break;
      }

      case SortBy.PRICE_DOWN: {
        order = -1;
        fn = priceSort;
        break;
      }
    }

    return [
      ...favoritesTrainsWithoutTP.sort(fn),
      ...otherTrainsWithoutTP.sort(fn),
      ...favoritesTrainsWithTP.sort(fn),
      ...otherTrainsWithTP.sort(fn),
    ];
  }

  @computed
  get applyFilter(): IInternalSearchTrain[] {
    const typeKeys = Object.keys(this.filters.type);
    const hasAll = typeKeys.filter(item => !this.filters.type[item]).length === typeKeys.length;
    const enterNumber = this.filters.number && this.filters.number.length > 0;
    const hasNumberOrName = this.filters.trainNumberOrName;
    const hasIsSapsan = this.filters.isSapsan;

    return this.sources.filter((item) => {
      const departureMin = this.filters.time.departure.from;
      const departureMax = this.filters.time.departure.to;
      const arrivalMin = this.filters.time.arrival.from;
      const arrivalMax = this.filters.time.arrival.to;

      const departureDate = parseUnix(item.DepartureDate).unix();
      const arrivalDate = parseUnix(item.ArrivalDate).unix();

      const hasTime = (
        departureDate >= departureMin &&
        departureDate <= departureMax &&
        arrivalDate >= arrivalMin &&
        arrivalDate <= arrivalMax
      );

      const trainNumber = enterNumber
        ? item.TrainNumber.includes(this.filters.number)
        : true;

      const isFavorite = this.filters.favoriteId
        ? item.FavoriteId
        : true;

      const trainNumberOrName = hasNumberOrName
        ? checkTrainNumberOrName(this.sources, item, hasNumberOrName)
        : true;

      const conditionIsSapsan = hasIsSapsan ?
        isSapsan({ Number: item.TrainNumber })
        : true;

      return hasTime
        && trainNumber
        && isFavorite
        && trainNumberOrName
        && conditionIsSapsan
        && item.Cars
          .filter((car) => {
            const hasPrice = car.MinimalCost.Total >= this.filters.price.from && car.MinimalCost.Total <= this.filters.price.to;
            const hasType = hasAll || this.filters.type[car.Type];

            return hasPrice && hasType;
          }).length;
    });
  }

  @computed
  get applyFilterTransferTickets(): IArrSourcesWithTransfer {
    const typeKeys = Object.keys(this.filters.type);
    const hasAll = typeKeys.filter(item => !this.filters.type[item]).length === typeKeys.length;
    const enterNumber = this.filters.number && this.filters.number.length > 0;
    const hasNumberOrName = this.filters.trainNumberOrName;
    const hasIsSapsan = this.filters.isSapsan;

    const transfersKeys = Object.keys(this.filters.transfers);
    const selectedTransfers = transfersKeys.filter(item => this.filters.transfers[item].selected);
    const notSelectedTransfers = transfersKeys.filter(item => !this.filters.transfers[item].selected);
    const hasAllTransfers = notSelectedTransfers.length === transfersKeys.length;

    return this.sourcesWithTransfer.filter((items) => {
      const departureMin = this.filters.time.departure.from;
      const departureMax = this.filters.time.departure.to;
      const arrivalMin = this.filters.time.arrival.from;
      const arrivalMax = this.filters.time.arrival.to;

      const firstItemTrains = items[0].Trains;
      const departureDate = parseUnix(firstItemTrains[0].DepartureDate).unix();
      const arrivalDate = parseUnix(firstItemTrains[firstItemTrains.length - 1].ArrivalDate).unix();

      const hasTime = (
        departureDate >= departureMin &&
        departureDate <= departureMax &&
        arrivalDate >= arrivalMin &&
        arrivalDate <= arrivalMax
      );

      const trainNumber = enterNumber
        ? items.some(({ Trains }) => Trains.some(({ TrainNumber }) => TrainNumber.includes(this.filters.number)))
        : true;

      const isFavorite = this.filters.favoriteId
        ? items.some(({ Trains }) => Trains.some(({ FavoriteId }) => FavoriteId))
        : true;

      const trainNumberOrName = hasNumberOrName
        ? items.some(({ Trains }) => Trains.some((item) =>
          checkTrainNumberOrNameTransfer(this.sourcesWithTransfer, item, hasNumberOrName),
        ))
        : true;

      const conditionIsSapsan = hasIsSapsan ?
        items.some(({ Trains }) => Trains.some(item =>
          isSapsan({ Number: item.TrainNumber }),
        )) :
        true;

      const transfers = !hasAllTransfers
        ? items.some(({ Trains }) => Trains.some((item, index) =>
          index !== 0 && selectedTransfers.includes(item.StationCodeFrom)))
        : true;

      const typesAndPrice =
        items.some(({ Trains }) => Trains.some(({ Cars }) => Cars.some(({ MinimalCost: { Total }, Type }) => {
          const hasPrice = Total >= this.filters.price.from && Total <= this.filters.price.to;
          const hasType = hasAll || this.filters.type[Type];

          return hasPrice && hasType;
        })));

      return hasTime &&
        isFavorite &&
        trainNumber &&
        trainNumberOrName &&
        conditionIsSapsan &&
        transfers &&
        typesAndPrice;
    });
  }

  @computed
  get createTags(): ITag[] {
    const tags = [];

    if (this.filters.price.border.from !== this.filters.price.from
      || this.filters.price.border.to !== this.filters.price.to) {
      tags.push({
        name: LABELS.PRICE_FROM_TO(
          MoneyFormat.money(this.filters.price.from),
          MoneyFormat.money(this.filters.price.to),
        ),
        key: FilterKey.PRICE,
        filter: FilterType.PRICE,
      });
    }

    if (this.filters.time.departure.border.from !== this.filters.time.departure.from
      || this.filters.time.departure.border.to !== this.filters.time.departure.to) {
      tags.push({
        name: LABELS.DEPARTURE_FROM_TO(
          parseUnix(this.filters.time.departure.from).format('HH:mm'),
          parseUnix(this.filters.time.departure.to).format('HH:mm'),
        ),
        key: FilterKey.DEPARTURE,
        filter: FilterType.TIME,
      });
    }

    if (this.filters.time.arrival.border.from !== this.filters.time.arrival.from
      || this.filters.time.arrival.border.to !== this.filters.time.arrival.to) {
      tags.push({
        name: LABELS.ARRIVAL_FROM_TO(
          parseUnix(this.filters.time.arrival.from).format('HH:mm'),
          parseUnix(this.filters.time.arrival.to).format('HH:mm'),
        ),
        key: FilterKey.ARRIVAL,
        filter: FilterType.TIME,
      });
    }

    Object.keys(this.filters.type).forEach((key) => {
      if (this.filters.type[key]) {
        tags.push({
          name: key,
          filter: FilterType.TYPE,
          key,
        });
      }
    });

    if (this.filters.number && this.filters.number.length > 0) {
      tags.push({
        name: LABELS.TRAIN_NUMBER(this.filters.number),
        key: FilterKey.NUMBER,
        filter: FilterType.NUMBER,
      });
    }

    if (this.filters.trainNumberOrName) {
      tags.push({
        name: LABELS.TRAIN_NAME(this.filters.trainNumberOrName.toUpperCase()),
        key: FilterKey.TRAIN_NUMBER_OR_NAME,
        filter: FilterType.TRAIN_NUMBER_OR_NAME,
        qaAttr: QA_ATTRIBUTES.search.train.tags.number,
      });
    }

    if (this.filters.isSapsan) {
      tags.push({
        name: LABELS.SAPSAN,
        key: FilterKey.IS_SAPSAN,
        filter: FilterType.IS_SAPSAN,
        qaAttr: QA_ATTRIBUTES.search.train.tags.isSapsan,
      });
    }

    if (this.filters.favoriteId) {
      tags.push({
        name: LABELS.FAVORITES,
        key: FilterKey.FAVORITE,
        filter: FilterType.FAVORITE_ID,
      });
    }

    if (this.filters.travelPolicyList.length > 1 && this.filters.selectedTravelPolicy !== TRAVELPOLICYFILTER.NOTAPPLIED) {
      const selectedPolicy = this.travelPolicyAllList.find(item => item.Id === this.filters.selectedTravelPolicy);

      // пришлось замутить проверку, тк ts ругается на find()
      if (selectedPolicy) {
        const name = selectedPolicy.Name;

        tags.push({
          name,
          key: FilterKey.TRAVEL_POLICY,
          filter: FilterType.SELECTED_TRAVEL_POLICY,
          readOnly: this.unavailableTravelPolicy,
        });
      }
    }

    Object.keys(this.filters.transfers).forEach((key) => {
      if (this.filters.transfers[key].selected) {
        tags.push({
          name: textTransformation(this.filters.transfers[key].name),
          filter: FilterType.TRANSFERS,
          key,
        });
      }
    });

    return tags;
  }

  @action
  updateFiltersParams = (
    unavailableTravelPolicy: boolean,
    accountTravelPolicy: TravelPolicyItem,
    travelPolicyAllList: TravelPolicyItem[],
  ) => {
    let min = 0;
    let max = 0;
    // what this brazzaaaa
    let departureMin: Moment | number | null | undefined = null;
    let departureMax: Moment | number | null | undefined = null;
    let arrivalMin: Moment | number | null | undefined = null;
    let arrivalMax: Moment | number | null | undefined = null;
    let selectedTP = this.filters.selectedTravelPolicy;

    const types: ITypeCar = {};
    let isAnyFavorite: boolean = false;
    const transfers: ITransfers = {};
    const travelPolicyList: string[] = [];
    travelPolicyList.push(TRAVELPOLICYFILTER.NOTAPPLIED);

    const listWithTransfer = this.sourcesWithTransfer.length ?
      this.sourcesWithTransfer.map((trains) => {
        const item = trains[0];
        const first = item.Trains[0];
        const last = item.Trains[item.Trains.length - 1];

        trains.forEach((trainTransfer) => {
          trainTransfer.Trains.forEach(({ StationCodeFrom, StationFrom }, index) => {
            if (index !== 0) {
              transfers[+StationCodeFrom] = {
                name: StationFrom,
                selected: false,
              };
            }
          });
        });

        const cars: ICar[] = [];

        trains.forEach(({ Trains }) => Trains.forEach(t => t.Cars.forEach(car => cars.push(car))));

        return {
          Cars: cars,
          DepartureDate: first.DepartureDate,
          ArrivalDate: last.ArrivalDate,
          FavoriteId: item.Trains.every(({ FavoriteId }) => FavoriteId),
          StationFrom: first.StationFrom,
          StationTo: last.StationTo,
        };
      }) :
      [];

    const list = [...this.sources, ...listWithTransfer];
    // @ts-ignore
    list.forEach((item: IInternalSearchTrain) => {
      item.Cars.forEach((car) => {
        const price = car.MinimalCost.Total;
        min = Math.min(min, price);
        max = Math.max(max, price);

        types[car.Type] = false;
      });

      const departureDate = parseUnix(item.DepartureDate);
      const arrivalDate = parseUnix(item.ArrivalDate);

      if (departureMin === null || departureDate.isBefore(departureMin)) departureMin = departureDate;

      if (departureMax === null || departureDate.isAfter(departureMax)) departureMax = departureDate;

      if (arrivalMin === null || arrivalDate.isBefore(arrivalMin)) arrivalMin = arrivalDate;

      if (arrivalMax === null || arrivalDate.isAfter(arrivalMax)) arrivalMax = arrivalDate;

      if (item.FavoriteId) isAnyFavorite = true;

      travelPolicyAllList.forEach((tp) => {
        if (tp.TrainRule.Apply && !travelPolicyList.some(tpInList => tpInList === tp.Id)) {
          travelPolicyList.push(tp.Id);
        }
      });

      if (item.TravelPolicy && item.TravelPolicy.Apply) {
        const travelPolicyErrors = Object.keys(item.TravelPolicy.Errors);

        travelPolicyErrors.forEach((tpE) => {
          if (travelPolicyList.length === 1) {
            travelPolicyList.push(tpE);
          } else if (travelPolicyList.length > 1) {
            const tpFind = travelPolicyList.filter(tp => tp === tpE);

            if (!tpFind.length) {
              travelPolicyList.push(tpE);
            }
          }
        });
      }
    });

    const date = (departureMin as Moment | null)?.clone();

    departureMin = (departureMin as Moment | null)?.startOf('D').unix();
    departureMax = (departureMax as Moment | null)?.endOf('D').unix();

    arrivalMin = (arrivalMin as Moment | null)?.unix();
    arrivalMax = (arrivalMax as Moment | null)?.unix();

    const stationFrom = list[0].StationFrom.toLowerCase();
    const departureCity = firstSymbolUpperCase(stationFrom);
    const stationTo = list[0].StationTo.toLowerCase();
    const arrivalCity = firstSymbolUpperCase(stationTo);

    if (unavailableTravelPolicy && travelPolicyList.length > 1 && !!accountTravelPolicy) {
      const hasAccountTP = travelPolicyList.filter(tp => tp === accountTravelPolicy.Id);

      if (hasAccountTP.length > 0) {
        selectedTP = hasAccountTP[0];
      } else {
        travelPolicyList.splice(0, travelPolicyList.length);
      }
    }

    return {
      filterParams: {
        ...this.filters,
        price: {
          border: {
            from: min,
            to: max,
          },
          roundBy: 500,
          from: min,
          to: max,
        },
        type: {
          ...types,
        },
        time: {
          date: date?.format('D MMMM'),
          departure: {
            border: {
              from: departureMin,
              to: departureMax,
            },
            from: departureMin,
            to: departureMax,
            city: departureCity,
          },
          arrival: {
            border: {
              from: arrivalMin,
              to: arrivalMax,
            },
            from: arrivalMin,
            to: arrivalMax,
            city: arrivalCity,
          },
        },
        selectedTravelPolicy: selectedTP,
        travelPolicyList,
        number: '',
        favoriteId: false,
        transfers,
      },
      isAnyFavorite,
    };
  };

  @action
  setTicketsList = ({
    search: { Id, Trains, TrainsWithTransfer },
    settings,
    filters,
    favorite,
  }: ISetTicketsListArgs): void => {
    const { rightsBuyTrip, accountTravelPolicy, travelPolicyAllList } = settings;

    this.sources = Trains || [];
    this.sourcesWithTransfer = TrainsWithTransfer || [];
    const unavailableTravelPolicy = rightsBuyTrip.BuyTripAccount === BUYTRIPSACCOUNTRIGHT.Unavailable &&
      rightsBuyTrip.BuyTripPersonal !== BUYTRIPSPERSONALRIGHT.Unavailable;

    let changedFilters = null;
    let isFavorite = false;
    const TrainsExists = Trains.length || TrainsWithTransfer.length;

    if (this.sources.length || this.sourcesWithTransfer.length) {
      const { filterParams, isAnyFavorite } =
        this.updateFiltersParams(unavailableTravelPolicy, accountTravelPolicy, travelPolicyAllList);

      changedFilters = TrainsExists ? {
        ...filterParams,
        ...filters,
      } : DEFAULT_FILTERS;
      isFavorite = isAnyFavorite;
    } else {
      changedFilters = TrainsExists ? {
        ...this.filters,
      } : DEFAULT_FILTERS;
    }

    this.searchId = Id;
    this.subMenu = true;
    this.filters = changedFilters;
    this.favorite = favorite || { number: null, date: null };
    this.isAnyFavorite = isFavorite;
    this.unavailableTravelPolicy = unavailableTravelPolicy;
    this.travelPolicyAllList = travelPolicyAllList;
    this.tickets = this.sources.length ? this.sortedTickets : [];
    this.ticketsWithTransfer = this.sourcesWithTransfer.length ? this.sortedWithTransferTickets : [];
    this.tags = this.createTags;
  };

  @action
  setNewSearch = (): void => {
    this.sources = [];
    this.sourcesWithTransfer = [];
    this.tickets = [];
    this.ticketsWithTransfer = [];
    this.tags = [];

    this.subMenu = false;
    this.isAnyFavorite = false;
    this.unavailableTravelPolicy = false;

    this.sortBy = SortBy.DEPARTURE_UP;
    this.travelPolicyAllList = [];
    this.favorite = { number: null, date: null };
    this.searchId = null;
    this.filters = {
      type: {},
      trainNumberOrName: '',
      isSapsan: false,
      price: {
        border: {
          from: 0,
          to: 0,
        },
        roundBy: 500,
        from: 0,
        to: 0,
      },
      time: {
        departure: {
          border: {
            from: 0,
            to: 0,
          },
          from: 0,
          to: 0,
        },
        arrival: {
          border: {
            from: 0,
            to: 0,
          },
          from: 0,
          to: 0,
        },
      },
      travelPolicyList: [],
      selectedTravelPolicy: TRAVELPOLICYFILTER.NOTAPPLIED,
      number: '',
      date: '',
      favoriteId: false,
      transfers: {},
    };
  };

  @action
  setSubMenu = (value: boolean): void => {
    this.subMenu = value;
  };

  @action
  setFilters = (): void => {
    this.tags = this.createTags;
    this.tickets = this.sortedTickets;
    this.ticketsWithTransfer = this.sortedWithTransferTickets;
  };

  @action
  updatePriceFilter = (values: number[]): IPriceFilter => ({
    border: {
      ...this.filters.price.border,
    },
    roundBy: 500,
    from: values[0],
    to: values[1],
  });

  @action
  setPriceFilter = (values: number[]): void => {
    this.filters = {
      ...this.filters,
      price: this.updatePriceFilter(values),
    };

    this.setFilters();
  };

  @action
  setTypeFilter = ({ type, value }: ITypeValue): void => {
    this.filters = {
      ...this.filters,
      type: {
        ...this.filters.type,
        [type]: value,
      },
    };

    this.setFilters();
  };

  @action
  setFavoriteFilter = (value: boolean): void => {
    this.filters = {
      ...this.filters,
      favoriteId: value,
    };

    this.setFilters();
  };

  @action
  setNumberFilter = (value: string): void => {
    this.filters = {
      ...this.filters,
      number: value,
    };

    this.setFilters();
  };

  @action
  setTrainNumberFilter = (value: string): void => {
    this.filters = {
      ...this.filters,
      trainNumberOrName: value,
    };

    this.setFilters();
  };

  @action
  setIsSapsanFilter = (value: boolean): void => {
    this.filters = {
      ...this.filters,
      isSapsan: value,
    };

    this.setFilters();
  };

  @action
  setTimeFilter = (type: TimeFilterType, values: number[]): void => {
    this.filters = {
      ...this.filters,
      time: {
        ...this.filters.time,
        [type]: {
          ...this.filters.time[type],
          from: values[0],
          to: values[1],
        },
      },
    };

    this.setFilters();
  };

  @action
  setTravelPolicyFilter = (value: string): void => {
    this.filters = {
      ...this.filters,
      selectedTravelPolicy: value,
    };

    this.setFilters();
  };

  @action
  setTransfersFilter = ({ transfers, value }: ITransfersFilter): void => {
    this.filters = {
      ...this.filters,
      transfers: {
        ...this.filters.transfers,
        [transfers]: {
          ...this.filters.transfers[transfers],
          selected: value,
        },
      },
    };

    this.setFilters();
  };

  @action
  deleteFilterTag = ({ filter, key }: ITag): void => {
    let value = null;

    if (filter === FilterType.PRICE) {
      value = this.updatePriceFilter([this.filters.price.border.from, this.filters.price.border.to]);
    }

    if (filter === FilterType.TIME) {
      value = {
        ...this.filters.time,
        [key]: {
          // @ts-ignore
          ...this.filters.time[key as keyof typeof this.filters.time],
          // @ts-ignore
          from: this.filters.time[key as keyof typeof this.filters.time].border.from,
          // @ts-ignore
          to: this.filters.time[key as keyof typeof this.filters.time].border.to,
        },
      };
    }

    if (filter === FilterType.TRAIN_NUMBER_OR_NAME) {
      value = '';
    }

    if (filter === FilterType.IS_SAPSAN) {
      value = false;
    }

    if (filter === FilterType.TYPE) {
      value = {
        ...this.filters.type,
        [key]: false,
      };
    }

    if (filter === FilterType.NUMBER) {
      value = '';
    }

    if (filter === FilterType.FAVORITE_ID) {
      value = false;
    }

    if (filter === FilterType.SELECTED_TRAVEL_POLICY) {
      if (!this.unavailableTravelPolicy) {
        value = TRAVELPOLICYFILTER.NOTAPPLIED;
      }
    }

    if (filter === FilterType.TRANSFERS) {
      value = {
        ...this.filters.transfers,
        [key]: {
          ...this.filters.transfers[key],
          selected: false,
        },
      };
    }

    this.filters = {
      ...this.filters,
      [filter]: value,
    };

    this.setFilters();
  };

  @action
  resetFilters = (): void => {
    const type: ITypeCar = {};
    Object.keys(this.filters.type).forEach((field) => {
      type[field] = false;
    });

    let selectedTravelPolicy = this.filters.selectedTravelPolicy;

    if (!this.unavailableTravelPolicy) {
      selectedTravelPolicy = TRAVELPOLICYFILTER.NOTAPPLIED;
    }

    this.filters = {
      ...this.filters,
      type,
      price: {
        ...this.filters.price,
        from: this.filters.price.border.from,
        to: this.filters.price.border.to,
      },
      time: {
        ...this.filters.time,
        departure: {
          ...this.filters.time.departure,
          from: this.filters.time.departure.border.from,
          to: this.filters.time.departure.border.to,
        },
        arrival: {
          ...this.filters.time.arrival,
          from: this.filters.time.arrival.border.from,
          to: this.filters.time.arrival.border.to,
        },
      },
      number: '',
      favoriteId: false,
      travelPolicyList: this.filters.travelPolicyList,
      selectedTravelPolicy,
    };

    this.setFilters();
  };

  @action
  setSortBy = (value: SortBy): void => {
    this.sortBy = value;
    this.tickets = this.sortedTickets;
    this.ticketsWithTransfer = this.sortedWithTransferTickets;
  };

  @action
  setFavorite = (id: number, favoriteId: string) => {
    const train = this.sources.find(item => item.TrainId === id);

    if (train) {
      train.FavoriteId = favoriteId;
    }

    this.isAnyFavorite = this.sources.some(item => item.FavoriteId);
  };

  @action
  setFavoritesWithTransfer = (trainId: number, favoriteId: string): void => {
    this.sourcesWithTransfer.forEach(items => items.forEach(({ Trains }) => {
      const train = Trains.find(({ TrainId }) => TrainId === trainId);

      if (train) {
        train.FavoriteId = favoriteId;
      }
    }));

    this.setFavorite(trainId, favoriteId);

    this.isAnyFavorite = this.isAnyFavorite ||
      this.sourcesWithTransfer.some(items => items.some(({ Trains }) => Trains.every(({ FavoriteId }) => FavoriteId)));
  };
}

const TrainTicketsStore = new Store();

export { TrainTicketsStore };
