import React from 'react';
import { Text } from 'new-ui';

import { STATUS_1S_RU, Status1S } from '../../bi/constants/expenseReport';
import { TRIPSTATUSES, TRIPSTATUS } from '../../bi/constants/trips';

import styles from './styles/index.module.css';

type TripStatus = 'booked' | 'cancelled' | 'awaitingTicketing';

interface IStatus {
  [key: number]: string
}

const STATUS_1S_TO_TRIP: IStatus = {
  [Status1S.CREATED]: TRIPSTATUS.AWAITINGTICKETING,
  [Status1S.DELIVERED]: TRIPSTATUS.BOOKED,
  [Status1S.ERROR_SEND_1C]: TRIPSTATUS.CANCELLED,
  [Status1S.WAITING_SEND_1C]: TRIPSTATUS.AWAITINGTICKETING,
  [Status1S.WAITING_APPROVE]: TRIPSTATUS.AWAITINGTICKETING,
  [Status1S.APPROVE]: TRIPSTATUS.BOOKED,
  [Status1S.DECLINED]: TRIPSTATUS.CANCELLED,
  [Status1S.CREATED_EVENT]: TRIPSTATUS.CREATED_EVENT,
  [Status1S.DRAFT]: TRIPSTATUS.DRAFT,
};

export interface IStatusMarker {
  status: string | number,
  rightIcon?: boolean,
  isExpense?: boolean,
  qaAttr?: {
    booked?: string,
    cancelled?: string,
    awaitingTicketing?: string,
  },
}

const StatusMarker = ({
  status,
  rightIcon = false,
  isExpense = false,
  qaAttr,
}: IStatusMarker) => {
  const wrapperClassNames = [styles.wrapper];

  if (rightIcon) {
    wrapperClassNames.push(styles.right);
  }

  const validStatuses = ['booked', 'cancelled', 'awaitingTicketing'];

  const isTripStatus = (key: string): key is TripStatus => validStatuses.includes(key);

  const qaAttrStatus = () => {
    if (typeof status === 'string') {
      const statusAttr = status.charAt(0).toLowerCase() + status.slice(1);

      if (isTripStatus(statusAttr) && qaAttr && qaAttr[statusAttr]) {
        return qaAttr[statusAttr];
      }
    }

    return '';
  };

  const statusContent = isExpense ? STATUS_1S_RU[status as number] : TRIPSTATUSES.get(status as string);

  const content = (
    <Text
      type='NORMAL_16'
      className={ styles.text }
      qaAttr={ qaAttrStatus() }
    >
      { statusContent }
    </Text>
  );

  const leftText = rightIcon && (content);
  const rightText = !rightIcon && (content);

  const statusClassName = isExpense ? STATUS_1S_TO_TRIP[status as number] : status;

  return (
    <div className={ wrapperClassNames.join(' ') }>
      { leftText }
      <div className={ `${styles.label} ${styles[statusClassName]}` } />
      { rightText }
    </div>
  );
};

export { StatusMarker };
