import { getText, getTextArray } from '../../../i18n';

const FLYCLASS = {
  Econom: getText('constants:airline.flyClass.econom'),
  Business: getText('constants:airline.flyClass.business'),
  First: getText('constants:airline.flyClass.first'),
};

const TECHNICAL_LANDINGS = {
  FIRST: 'AA',
  LAST: 'AD',
};

const FLY_CLASS_ENUM = {
  ECONOM: 'Econom',
  BUSINESS: 'Business',
  FIRST: 'First',
};

// locale question (???)
const AIRLINENAMES = {
  POBEDA: getText('constants:airline.airlineNames.pobeda'),
  RUSLINE: getText('constants:airline.airlineNames.rusline'),
  SSEVEN: getText('constants:airline.airlineNames.sseven'),
  AEROFLOT: getText('constants:airline.airlineNames.aeroflot'),
};

const VALUETICKET = {
  INCLUDED: 'Included',
  CHARGE: 'Charge',
  NOTOFFERED: 'NotOffered',
  NOTDEFINED: 'NotDefined',
  NOTPENALTY: 'NotPenalty',
};

const REFUNDTICKET = {
  Included: getText('constants:airline.refundTicket.included'),
  NotOffered: getText('constants:airline.refundTicket.notOffered'),
  Charge: getText('constants:airline.refundTicket.charge'),
};

const CHANGETICKET = {
  Included: getText('constants:airline.changeTicket.included'),
  NotOffered: getText('constants:airline.changeTicket.notOffered'),
  Charge: getText('constants:airline.changeTicket.charge'),
};

const BAGGAGE = {
  Included: getText('constants:airline.baggage.included'),
  NotOffered: getText('constants:airline.baggage.notOffered'),
  Charge: getText('constants:airline.baggage.charge'),
};

const TARIFFS = {
  NOTOFFERED: getText('constants:airline.tariffs.notOffered'),
  CHARGE: getText('constants:airline.tariffs.charge'),
  INCLUDED: getText('constants:airline.tariffs.included'),
};

const AIRLINETYPES: { MARKETINGAIRLINE: 'MarketingAirline', OPERATINGAIRLINE: 'OperatingAirline', AIRLINE: 'Airline' } = {
  MARKETINGAIRLINE: 'MarketingAirline',
  OPERATINGAIRLINE: 'OperatingAirline',
  AIRLINE: 'Airline',
};

const OPERATINGEXCEPTIONS = {
  FV: 'SU',
};

const economAirClass = getText('constants:airline.airlineClassesMap.econom');
const businessAirClass = getText('constants:airline.airlineClassesMap.business');
const firstAirClass = getText('constants:airline.airlineClassesMap.first');

const AIRLINE_CLASSES_MAP = new Map([
  ['Econom', economAirClass],
  ['ECONOMY', economAirClass],
  ['Business', businessAirClass],
  ['BUSINESS', businessAirClass],
  ['First', firstAirClass],
  ['FIRST', firstAirClass],
]);

const BANKRUPTCY_AIRLINES = ['UT', 'U6', 'N4'];

const ADDITIONAL_SERVICES = {
  Seat: 0,
  Baggage: 2,
};

const ADDITIONAL_SERVICES_TYPE = {
  SEAT: 'seat',
  BAGGAGE: 'baggage',
};

const AIRLINE_PROVIDERS = {
  POBEDA: 'Pobeda',
  S7: 'S7',
  AMADEUS: 'Amadeus',
  SIRENA: 'Sirena',
  PORTBILET: 'Portbilet',
  AVIACENTR: 'aviacentr',
  AEROFLOT: 'Aeroflot',
  MIXVEL: 'Mixvel',
};

const AIRLINE_IDS = {
  POBEDA: 'DP',
  S7: 'S7',
  GLOBUS: 'GH',
  GLOBUS_CITRUS: 'XT',
};

const AIRLINE_FARES = {
  FLEXECONOMY: getText('constants:airline.airlineFares.flexEconomy'),
  PLUSECONOMY: getText('constants:airline.airlineFares.plusEconomy'),
  STANDARDEBUSINESS: getText('constants:airline.airlineFares.standardBusiness'),
  PLUSEBUSINESS: getText('constants:airline.airlineFares.plusBusiness'),
};

const AIRLINE_IDS_W_CUSTOM_REFUND = ['SZ', 'HU', 'MU', 'CA', 'AH', 'CZ', 'LY'];

const ACCOUNT_IDS_WITH_S7CORPORATE_AND_SEATMAPS = [3494];

const TRAVELLERS = getTextArray('constants:airline.travellersDeclines');

const TRANSFER = getTextArray('constants:airline.transferDeclines');

const WITHOUT_TRANSFER = getText('constants:airline.withoutTransfers');

const TICKETS = getTextArray('constants:airline.ticketDeclines');

const PRICE_AIR_CERTIFICATE = 350;

const FIELDS_FILTERS = {
  TRANSFER_AIRPORTS: 'transferAirports',
  TRAVEL_TIME: 'travel.time',
  TRAVEL_TIME_FIELD: 'duration',
};

const SORT_VALUE = {
  RECOMMENDED: 'recommended_down',
  PRICE_DOWN: 'price_down',
  DISTANCE_DOWN: 'distance_down',
  DEPARTURE_TIME_DOWN: 'departureTime_down',
  TRANSFER_TIME_DOWN: 'transferTime_down',
};

const CHANGE_AIR_TRIP_FIELDS = {
  SEARCH_PAGING: 'paging',
  SEARCH_FILTERS: 'filters',
  SEARCH_SORT: 'sort',
};

const FIELDS_SEARCH_MENU = {
  ADULT: 'adult',
  FLIGHT_CLASS: 'flightClass',
  ADULT_CHILD: 'child',
};

const CODE_IMAGE_BIG = {
  S7: 'S7',
  SU: 'SU',
};

const AIRLINE_NAMES = {
  S7: 'S7',
  U6: 'U6',
};

const CANCEL_RESERVED = {
  FV: 'FV',
  SU: 'SU',
  S7: 'S7',
};

export {
  FLYCLASS as default,
  FLY_CLASS_ENUM,
  AIRLINENAMES,
  VALUETICKET,
  REFUNDTICKET,
  CHANGETICKET,
  BAGGAGE,
  TARIFFS,
  AIRLINETYPES,
  OPERATINGEXCEPTIONS,
  AIRLINE_CLASSES_MAP,
  BANKRUPTCY_AIRLINES,
  ADDITIONAL_SERVICES,
  ADDITIONAL_SERVICES_TYPE,
  AIRLINE_PROVIDERS,
  AIRLINE_FARES,
  TRAVELLERS,
  TRANSFER,
  WITHOUT_TRANSFER,
  TICKETS,
  PRICE_AIR_CERTIFICATE,
  AIRLINE_IDS,
  ACCOUNT_IDS_WITH_S7CORPORATE_AND_SEATMAPS,
  AIRLINE_IDS_W_CUSTOM_REFUND,
  FIELDS_FILTERS,
  TECHNICAL_LANDINGS,
  SORT_VALUE,
  FIELDS_SEARCH_MENU,
  CODE_IMAGE_BIG,
  AIRLINE_NAMES,
  CHANGE_AIR_TRIP_FIELDS,
  CANCEL_RESERVED,
};
