import { observable, action, makeObservable } from 'mobx';

import { ICarPlacesDetails, IInternalTrainDetailsClientData } from '../../../types/trains';

export type TTrainStore = typeof TrainStore;

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable loading = true;
  @observable loadingCarDetails = true;
  @observable train: IInternalTrainDetailsClientData | null = null;

  @observable car: ICarPlacesDetails | null = null;

  @action
  setLoading = (value: boolean) => {
    this.loading = value;
  };

  @action
  setLoadingCarDetails = (value: boolean) => {
    this.loadingCarDetails = value;
  };

  @action
  setTrainInfo = (train: IInternalTrainDetailsClientData) => {
    this.train = train;
  };

  @action
  setPlaceDetails = (car: ICarPlacesDetails) => {
    this.car = car;
    this.setLoadingCarDetails(false);
  };

  @action
  setTrainFavorite = (value: string) => {
    // @ts-ignore
    this.train = {
      ...this.train,
      FavoriteId: value,
    };
  };
}

const TrainStore = new Store();

export { TrainStore };
