import React from 'react';
import { useLocalStore } from 'mobx-react';

import { MOBX_STORES, MOBX_STORES_TYPES } from './stores';

import { ChartsAnalyticsSmartSummaryStore, chartsAnalyticsSmartSummaryStore } from '../services/chartsAnalytics/stores/smart/summary';
import { AirlineAdditionalServicesStore, airlineAdditionalServicesStore } from '../services/airlineAdditionalServices/store';
import { ChartsAnalyticsGeneralStore, chartsAnalyticsGeneralStore } from '../services/chartsAnalytics/stores/generalStore';
import { ChartsAnalyticsSummaryStore, chartsAnalyticsSummaryStore } from '../services/chartsAnalytics/stores/summary';
import { ChartsAnalyticsStore, chartsAnalyticsStore } from '../services/chartsAnalytics/stores/chartsAnalyticsStore';
import { ExpenseReportApproveStore, expenseReportApproveStore } from '../services/expenseReports/stores/approve';
import { TravelApprovalsStore, travelApprovalsStore } from '../services/travelApproval/stores/travelApprovals';
import { ExpenseReportSingleStore, expenseReportStore } from '../services/expenseReports/stores/expenseReport';
import { TravelApprovalStore, travelApprovalStore } from '../services/travelApproval/stores/travelApproval';
import { SimpleSignatureStoreType, SimpleSignatureStore } from '../services/employee/store/simpleSignature';
import { ExpenseReportsStore, expenseReportsStore } from '../services/expenseReports/stores/expenseReports';
import { SettingSchemeStore, settingSchemeStore } from '../services/approvalSchemes/stores/settingScheme';
import { ApprovalSchemesStore, approvalSchemesStore } from '../services/approvalSchemes/stores/schemes';
import { ApprovalERSchemesStore, approvalERSchemesStore } from '../services/approvalERSchemes/store';
import { ApproveReportStore, approveReportStore } from '../services/report/approveReportStore';
import { EnhancedSignatureStore, enhancedSignatureStore } from '../services/signature/store';
import { AirlineBaggageStore, airlineBaggageStore } from '../services/airlineBaggage/store';
import { ApprovesStore, approvesStore } from '../services/travelApproval/stores/approves';
import { AirlineSeatsStore, airlineSeatsStore } from '../services/airlineSeats/store';
import { AeroexpressStore, aeroexpressStore } from '../services/aeroexpress/store';
import { SmartdeskStoreType, SmartdeskStore } from '../services/smartdesk/store';

import type { ITravelPolicyStore } from '../services/travelPolicy/travelPolicyStore';
import type { ITravelPoliciesListStore } from '../services/travelPolicy/travelPoliciesListStore';
import type { IDepartmentsStore } from '../services/departments/store';
import type { ISidePanelStore } from '../services/sidePanel/store';
import type { ICloudPaymentsStore } from '../services/payment/store';
import type { IBonusProgramStore } from '../services/bonusProgram/store';
import type { ITripCommentsStore } from '../services/tripComments/store';
import type { IUiSettingsStore } from '../services/uiSettings/store';
import type { TTrainSearchStore } from '../services/trains/store/search';
import type { TTrainTicketsStore } from '../services/trains/store/tickets';
import type { TTrainStore } from '../services/trains/store/train';
import type { TTrainSavedTicketStore } from '../services/trains/store/savedTicket';
import type { TTrainSavedTicketsStore } from '../services/trains/store/savedTicketsWithTransfer';
import type { ISettingsStore } from '../services/settings/stores/settings';
import type { ITaxiStore } from '../services/taxi/store';
import type { IRequestDialogStore } from '../services/report/requestDialogStore';
import type { IContractHotelStore } from '../services/hotels/store/contractHotelStore';
import type { IBannersStore } from '../services/banners/store';
import type { INewHotelsStore } from '../services/newHotels/store';
import type { ITransferSearchStore } from '../services/transfer/store/search';
import type { ITransferStore } from '../services/transfer/store/transfer';
import type { IBreadCrumbsStore } from '../services/breadCrumbs/store';
import type { IVipHallStore } from '../services/vipHall/store/vipHall';
import type { IVipHallSearchStore } from '../services/vipHall/store/search';
import type { IPopupsStore } from '../services/popups/store';
import type { IQualityStore } from '../services/quality/store';
import { IFiltersStore } from '../types/filters';
import { IEventStore } from '../services/events/type';
import type { IFavoritesStore } from '../services/favorites/store';
import type { ITripTagsStore } from '../services/tripTags/store';
import type { EmployeePaymentStoreType } from '../services/employeePayment/store';
import type { BookingStoreType } from '../services/booking/analogStore';
import type { CloudPaymentStoreType } from '../services/cloudPayment/store';
import type { IInsuranceStore } from '../services/insurance/store';

import { TravelPolicyStore } from '../services/travelPolicy/travelPolicyStore';
import { TravelPoliciesListStore } from '../services/travelPolicy/travelPoliciesListStore';
import { DepartmentsStore } from '../services/departments/store';
import { SidePanelStore } from '../services/sidePanel/store';
import { CloudPaymentsStore } from '../services/payment/store';
import { BonusProgramStore } from '../services/bonusProgram/store';
import { TripCommentsStore } from '../services/tripComments/store';

import { UiSettingsStore } from '../services/uiSettings/store';
import { TrainSearchStore } from '../services/trains/store/search';
import { TrainTicketsStore } from '../services/trains/store/tickets';
import { TrainStore } from '../services/trains/store/train';
import { TrainSavedTicketStore } from '../services/trains/store/savedTicket';
import { TrainSavedTicketsStore } from '../services/trains/store/savedTicketsWithTransfer';
import { SettingsStore } from '../services/settings/stores/settings';
import { TaxiStore } from '../services/taxi/store';
import { VipHallSearchStore } from '../services/vipHall/store/search';
import { VipHallStore } from '../services/vipHall/store/vipHall';
import { RequestDialogStore } from '../services/report/requestDialogStore';
import { ContractHotelStore } from '../services/hotels/store/contractHotelStore';
import { BannersStore } from '../services/banners/store';
import { NewHotelsStore } from '../services/newHotels/store';
import { TransferSearchStore } from '../services/transfer/store/search';
import { TransferStore } from '../services/transfer/store/transfer';
import { BreadCrumbsStore } from '../services/breadCrumbs/store';
import { PopupsStore } from '../services/popups/store';
import { FiltersStore } from '../services/settings/stores/filters';
import { QualityStore } from '../services/quality/store';
import { EventStore } from '../services/events/stores/events';
import { FavoritesStore } from '../services/favorites/store';
import { TripTagsStore } from '../services/tripTags/store';
import { EmployeePaymentStore } from '../services/employeePayment/store';
import { BookingStore } from '../services/booking/analogStore';
import { PaymentMethodsStore } from '../services/cloudPayment/store';
import { InsuranceStore } from '../services/insurance/store';

export interface UseStoresInterface {
  travelPolicyStore: ITravelPolicyStore,
  travelPoliciesListStore: ITravelPoliciesListStore,
  departmentsStore: IDepartmentsStore,
  sidePanelStore: ISidePanelStore,
  cloudPaymentsStore: ICloudPaymentsStore,
  bonusProgramStore: IBonusProgramStore,
  tripCommentsStore: ITripCommentsStore,
  smartdeskStore: SmartdeskStoreType,
  uiSettingsStore: IUiSettingsStore,
  trainSearchStore: TTrainSearchStore,
  trainTicketsStore: TTrainTicketsStore,
  trainStore: TTrainStore,
  trainSavedTicketStore: TTrainSavedTicketStore,
  trainSavedTicketsStore: TTrainSavedTicketsStore,
  settingsStore: ISettingsStore,
  taxiStore: ITaxiStore,
  requestDialogStore: IRequestDialogStore,
  contractHotelStore: IContractHotelStore,
  bannersStore: IBannersStore,
  newHotelsStore: INewHotelsStore,
  transferSearchStore: ITransferSearchStore,
  transferStore: ITransferStore,
  breadCrumbsStore: IBreadCrumbsStore,
  vipHallStore: IVipHallStore,
  vipHallSearchStore: IVipHallSearchStore,
  popupsStore: IPopupsStore,
  simpleSignatureStore: SimpleSignatureStoreType,
  qualityStore: IQualityStore,
  filterStore: IFiltersStore,
  eventStore: IEventStore,
  insuranceStore: IInsuranceStore,
  favoritesStore: IFavoritesStore,
  tripTagsStore: ITripTagsStore,
  travelPolicyListStore: ITravelPoliciesListStore,
  airlineSeatsStore: AirlineSeatsStore,
  airlineBaggageStore: AirlineBaggageStore,
  airlineAdditionalServicesStore: AirlineAdditionalServicesStore,
  approvalSchemesStore: ApprovalSchemesStore,
  travelApprovalStore: TravelApprovalStore,
  travelApprovalsStore: TravelApprovalsStore,
  approvesStore: ApprovesStore,
  settingSchemeStore: SettingSchemeStore,
  expenseReportsStore: ExpenseReportsStore,
  approveStore: ExpenseReportApproveStore,
  expenseReportStore: ExpenseReportSingleStore,
  chartsAnalyticsSmartSummaryStore: ChartsAnalyticsSmartSummaryStore,
  chartsAnalyticsGeneralStore: ChartsAnalyticsGeneralStore,
  chartsAnalyticsStore: ChartsAnalyticsStore,
  chartsAnalyticsSummaryStore: ChartsAnalyticsSummaryStore,
  approveReportStore: ApproveReportStore,
  aeroexpressStore: AeroexpressStore,
  approvalERSchemesStore: ApprovalERSchemesStore,
  employeePaymentStore: EmployeePaymentStoreType,
  paymentMethodStore: CloudPaymentStoreType
  enhancedSignatureStore: EnhancedSignatureStore,
  bookingStore: BookingStoreType,
}

const storesContext = React.createContext<UseStoresInterface | null>(null);

// register new stores below
const StoreProvider = ({ children }: React.PropsWithChildren<object>) => {
  const store = useLocalStore(() => (
    {
      [MOBX_STORES.TRAVEL_POLICY]: TravelPolicyStore,
      [MOBX_STORES.TRAVEL_POLICY_LIST]: TravelPoliciesListStore,
      [MOBX_STORES.DEPARTMENTS]: DepartmentsStore,
      [MOBX_STORES.SIDE_PANEL]: SidePanelStore,
      [MOBX_STORES.CLOUD_PAYMENTS]: CloudPaymentsStore,
      [MOBX_STORES.BONUS_PROGRAM]: BonusProgramStore,
      [MOBX_STORES.TRIP_COMMENTS]: TripCommentsStore,
      [MOBX_STORES.SMARTDESK]: SmartdeskStore,
      [MOBX_STORES.UI_SETTINGS]: UiSettingsStore,
      [MOBX_STORES.TRAIN_SEARCH]: TrainSearchStore,
      [MOBX_STORES.TRAIN_TICKETS]: TrainTicketsStore,
      [MOBX_STORES.TRAIN]: TrainStore,
      [MOBX_STORES.TRAIN_SAVED_TICKET]: TrainSavedTicketStore,
      [MOBX_STORES.TRAIN_SAVED_TICKETS]: TrainSavedTicketsStore,
      [MOBX_STORES.SETTINGS_STORE]: SettingsStore,
      [MOBX_STORES.FILTERS_STORE]: FiltersStore,
      [MOBX_STORES.TAXI_STORE]: TaxiStore,
      [MOBX_STORES.VIP_SEARCH_STORE]: VipHallSearchStore,
      [MOBX_STORES.VIP_STORE]: VipHallStore,
      [MOBX_STORES.REQUEST_DIALOG_STORE]: RequestDialogStore,
      [MOBX_STORES.CONTRACT_HOTEL_STORE]: ContractHotelStore,
      [MOBX_STORES.BANNER_STORE]: BannersStore,
      [MOBX_STORES.NEW_HOTELS_STORE]: NewHotelsStore,
      [MOBX_STORES.TRANSFER_SEARCH_STORE]: TransferSearchStore,
      [MOBX_STORES.TRANSFER_STORE]: TransferStore,
      [MOBX_STORES.BREAD_CRUMBS_STORE]: BreadCrumbsStore,
      [MOBX_STORES.POPUPS_STORE]: PopupsStore,
      [MOBX_STORES.SIMPLE_SIGNATURE_STORE]: SimpleSignatureStore,
      [MOBX_STORES.QUALITY_STORE]: QualityStore,
      [MOBX_STORES.EVENT_STORE]: EventStore,
      [MOBX_STORES.FAVORITES_STORE]: FavoritesStore,
      [MOBX_STORES.TRIP_TAGS_STORE]: TripTagsStore,
      [MOBX_STORES.PAYMENT_METHOD]: PaymentMethodsStore,
      [MOBX_STORES.EMPLOYEE_PAYMENT]: EmployeePaymentStore,
      [MOBX_STORES.EXPENSE_REPORTS_STORE_APPROVE]: expenseReportApproveStore,
      [MOBX_STORES.TRAVEL_POLICY_LIST]: TravelPoliciesListStore,
      [MOBX_STORES.INSURANCE_STORE]: InsuranceStore,
      [MOBX_STORES.BOOKING]: BookingStore,
      expenseReportsStore,
      expenseReportStore,
      chartsAnalyticsSmartSummaryStore,
      chartsAnalyticsGeneralStore,
      chartsAnalyticsStore,
      chartsAnalyticsSummaryStore,
      aeroexpressStore,
      approvalERSchemesStore,
      approvalSchemesStore,
      settingSchemeStore,
      travelApprovalStore,
      travelApprovalsStore,
      approvesStore,
      airlineSeatsStore,
      airlineBaggageStore,
      airlineAdditionalServicesStore,
      enhancedSignatureStore,
      approveReportStore,
    }),
  );

  return (<storesContext.Provider value={ store as unknown as UseStoresInterface }>{children}</storesContext.Provider>);
};

// use it with functional components
const useStores = (stores: Array<MOBX_STORES_TYPES> = []): UseStoresInterface => {
  const contextStores = React.useContext(storesContext);

  if (!contextStores) {
    throw new Error('useStore must be used within a StoreProvider.');
  }

  if (!stores.length) {
    return contextStores;
  }

  return stores.reduce((r, k) => {
    const store = contextStores[k as keyof UseStoresInterface];

    if (!store) {
      throw new Error(`store ${k} was not found in StoreProvider.`);
    }

    return {
      ...r,
      [k]: store,
    };
  }, {}) as UseStoresInterface;
};

// use it with class-based components
const withStores = <Klass extends React.ComponentClass<any, any>>(stores: MOBX_STORES_TYPES[] = []) => (klass: Klass) => ((props: any[]) => {
  const Component = klass as unknown as React.FC<any>;

  return <Component { ...props } stores={ useStores(stores) } />;
}) as unknown as Klass;

export { StoreProvider, useStores, withStores };
