import React, { useLayoutEffect } from 'react';
import { observer } from 'mobx-react';
import { DotLoading, Price, RadioButton, Text } from 'new-ui';

import { getText } from '../../../i18n';

import { useStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';
import { useServices } from '../../bi/context/services';

import { CommissionType, PAYMENT_METHODS, PaymentMethodType } from '../../bi/types/paymentMethod';

import wallet_icon from './images/wallet.png';

import styles from './index.module.css';

const LABELS = {
  PAYMENT_METHOD_LABEL: getText('payment:paymentMethod'),
  COMMISSION_INFO: getText('payment:commissionInfo'),
  TRANSFER_FEE: getText('payment:commissionForCurrentPurchase'),
  SERVICES: getText('payment:services'),
  AMOUNT_TO_PAY: getText('payment:amountToPay'),
  METHODS: {
    ORGANIZATION_ACCOUNT: getText('payment:method.organizationAccount'),
    EMPLOYEE_CARD: getText('payment:method.employeeCard'),
    EMPLOYEE_PERSONAL_ACCOUNT: getText('payment:method.employeePersonalAccount'),
  },
  CHECKBOX: {
    ONE: getText('checkout:finish.checkbox.one'),
    TWO: getText('checkout:finish.checkbox.two'),
  },

};

interface PaymentMethodProps {
  children: React.ReactElement,
  price: number,
  canPayWithPersonalFunds: boolean,
}

const PaymentMethod = observer(({
  children,
  price,
  canPayWithPersonalFunds,
}: PaymentMethodProps) => {
  const {
    employeePaymentServiceService,
    cloudPaymentService: { setPaymentMethod, networkStatesStore },
    cartService,
  } = useServices(['EmployeePaymentService', 'CloudPayment', 'Cart']);

  const isLoading = networkStatesStore.getIsStatus('setPaymentType', networkStatesStore.loadingStatuses.LOADING);

  if (!canPayWithPersonalFunds) {
    return null;
  }

  const {
    paymentMethodStore: { paymentMethod },
    employeePaymentStore: { commission },
  } = useStores([MOBX_STORES.PAYMENT_METHOD, MOBX_STORES.EMPLOYEE_PAYMENT]);

  const handleChangePaymentType = (type: PaymentMethodType) => () => {
    const cartId: number = cartService.get()?.carts[0]?.id;

    setPaymentMethod(cartId, type);
  };

  useLayoutEffect(() => {
    employeePaymentServiceService.getCommissionFee(price, CommissionType.card);
  }, [price]);

  const paymentMethodIsEmployeeCart = paymentMethod === PAYMENT_METHODS.EMPLOYEE_CARD;
  const finallyPrice = paymentMethodIsEmployeeCart ? commission + price : price;

  const renderAccountSelection = () => {
    const infoCommissionHtml = paymentMethodIsEmployeeCart ? (
      <div className={ styles.info_commission }>
        <Text type='NORMAL_14'>
          { LABELS.COMMISSION_INFO }
        </Text>
      </div>
    ) : null;

    const transferCommissionHtml = paymentMethodIsEmployeeCart ? (
      <div className={ styles.flex_row }>
        <Text type='NORMAL_14'>{LABELS.TRANSFER_FEE}</Text>
        <Price
          value={ commission }
          type='NORMAL_14'
          typeCurrency='NORMAL_14'
        />
      </div>
    ) : null;

    const servicesPrice = paymentMethodIsEmployeeCart ? (
      <div className={ styles.flex_row }>
        <Text type='NORMAL_14'> {LABELS.SERVICES} </Text>
        <Price
          value={ price }
          type='NORMAL_14'
          typeCurrency='NORMAL_14'
        />
      </div>
    ) : null;

    const infoPaymentHtml = (
      <div className={ styles.prices_info }>
        { servicesPrice }
        { transferCommissionHtml }
        <div className={ styles.flex_row }>
          <Text type='bold_16'>{LABELS.AMOUNT_TO_PAY}</Text>
          <Price
            value={ finallyPrice }
            type='bold_16'
            typeCurrency='bold_16'
          />
        </div>
      </div>
    );

    return (
      <div className={ styles.payments_method }>
        <RadioButton
          disabled={ isLoading }
          value={ PaymentMethodType.organizationAccount }
          checked={ paymentMethod === PaymentMethodType.organizationAccount }
          onChange={ handleChangePaymentType(PaymentMethodType.organizationAccount) }
        >
          { LABELS.METHODS.ORGANIZATION_ACCOUNT }
        </RadioButton>
        <RadioButton
          disabled={ isLoading }
          value={ PaymentMethodType.employeeCard }
          checked={ paymentMethod === PaymentMethodType.employeeCard }
          onChange={ handleChangePaymentType(PaymentMethodType.employeeCard) }
        >
          { LABELS.METHODS.EMPLOYEE_CARD }
        </RadioButton>
        <RadioButton
          disabled={ isLoading }
          value={ PaymentMethodType.employeePersonalAccount }
          checked={ paymentMethod === PaymentMethodType.employeePersonalAccount }
          onChange={ handleChangePaymentType(PaymentMethodType.employeePersonalAccount) }
        >
          { LABELS.METHODS.EMPLOYEE_PERSONAL_ACCOUNT }
        </RadioButton>
        { infoCommissionHtml }
        { infoPaymentHtml }
      </div>
    );
  };

  const renderChildrenContent = isLoading ? <DotLoading /> : children;

  return (
    <div className={ styles.payments_method_container }>
      <div className={ styles.header }>
        <img src={ wallet_icon } alt='wallet_icon' />
        <Text type='bold_20'>{ LABELS.PAYMENT_METHOD_LABEL } </Text>
      </div>
      <div className={ styles.payments_info }>
        { renderAccountSelection() }
      </div>
      <div className={ styles.final_step }>
        { renderChildrenContent }
      </div>
    </div>
  );
});

export { PaymentMethod };
