import { COMPANY_DEPARTMENT_DIVIDER } from '../constants/cart';
import { withoutMentionSmartway } from '../constants/companiesRules';

interface CompanyWithDepartmentBody {
  CompanyId: string,
  DepartmentId: string | null;
}

const prepareAddEmployeeCompanyData = (value: string): CompanyWithDepartmentBody => {
  const splitedCompanyId = String(value).split(COMPANY_DEPARTMENT_DIVIDER);
  const isMulti = splitedCompanyId.length > 1;

  return {
    CompanyId: isMulti ? splitedCompanyId[0] : value,
    DepartmentId: isMulti ? splitedCompanyId[1] : null,
  };
};

const addFirstChars = (inputString: string, value: number, additionalText: string = '...') => {
  const uppercaseCount = Array.from(inputString).filter(char => char === char.toUpperCase() && isNaN(Number(char))).length;
  const adjustedValue = Math.max(value - Math.floor(uppercaseCount * 0.26), 0);

  if (inputString.length > value) {
    return inputString.substring(0, adjustedValue) + additionalText;
  }

  return inputString;
};

const isWithoutMentionSmartway = (companyName: string) => withoutMentionSmartway.includes(companyName);

export { prepareAddEmployeeCompanyData, isWithoutMentionSmartway, addFirstChars };
