import React from 'react';
import { Button, Dialog, Text } from 'new-ui';
import { getText } from '../../../i18n';

import CartAir from './components/cartAir';
import CartTrain from './components/cartTrain';
import CartHotel from './components/cartHotel';
import TripAir from './components/tripAir';
import TripTrain from './components/tripTrain';
import TripHotel from './components/tripHotel';
import DuplicateBookings from './components/DuplicateBookings';

import { getEmployeeFullNameWithSimplicity } from '../../bi/utils/employees';
import parseJsonString from '../../bi/utils/parseJsonString';

import { CHECKOUT } from '../../bi/constants/checkout';
import { SERVICETYPE, SERVICETYPERU } from '../../bi/constants/serviceType';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';

import { IEmployee } from '../../bi/services/settings/types';
import { ServiceTypes } from '../../bi/types/trips';
import { ISourcesItem, IConflicts } from '../../bi/services/checkout/types';

import styles from './styles/index.module.css';

export interface CartConflicts {
  Data: string,
  Employees: IEmployeeInCartConflict[],
  Id: number,
  ServiceType: ServiceTypes,
}

interface IEmployeeInCartConflict extends IEmployee {
  Employee: IEmployeeName,
}

interface IEmployeeName {
  Surname: string;
  Name?: string;
  Patronymic?: string;
  LastName: string;
}
export interface TripConflicts {
  id: number,
  serviceType: string,
  jsonData: string,
  Data: string,
  ServiceType: ServiceTypes,
}

interface DuplicateDialogTypeProps {
  conflicts: IConflicts,
  sources: ISourcesItem[];
  onClose: () => void,
  onBack: () => void,
  show: boolean,
  isConflict: boolean | null;
}

type FieldType = 'Cart' | 'Trip' | 'DuplicateCarts';

const LABELS = {
  BACK: getText('components:duplicateDialog.edit'),
  CANCEL: getText('components:duplicateDialog.cancel'),
  INFO: getText('components:duplicateDialog.info'),
  DISALLOWED_INFO: getText('components:disallowedInfo'),
  RESERVATION: (userName: string) => getText('constants:checkout.reservation', { userName }),
};

const FIELD = {
  CART: 'Cart',
  TRIP: 'Trip',
  DUPLICATECARTS: 'DuplicateCarts',
};

const getCartItem = {
  [SERVICETYPE.AIR]: CartAir,
  [SERVICETYPE.TRAIN]: CartTrain,
  [SERVICETYPE.HOTEL]: CartHotel,
};

const getTripItem = {
  [SERVICETYPE.AIR]: TripAir,
  [SERVICETYPE.TRAIN]: TripTrain,
  [SERVICETYPE.HOTEL]: TripHotel,
};

const tripHeader = (tripConflicts: TripConflicts[]) => (
  tripConflicts.length === 1
    ? CHECKOUT.DUBLICATE.HEADER.TRIP
    : CHECKOUT.DUBLICATE.HEADER.TRIPS
);

const serviceTypeRu = (type: string) => {
  const preparedType = SERVICETYPERU[type];

  return preparedType[0].toUpperCase() + preparedType.slice(1);
};

const DuplicateDialog = ({
  conflicts: {
    allowed: {
      cartConflicts: allowedCartConflicts,
      tripConflicts: allowedTripConflicts,
      duplicateCartTrips: allowedDuplicateCartTrips,
      titleTrip: allowedTitleTrip,
    },
    disallowed: {
      cartConflicts: disallowedCartConflicts,
      tripConflicts: disallowedTripConflicts,
      duplicateCartTrips: disallowedDuplicateCartTrips,
      titleTrip: disallowedTitleTrip,
    },
    duplicateBookings,
  },
  onClose,
  onBack,
  show,
  isConflict,
  sources,
}: DuplicateDialogTypeProps) => {
  const cartConflicts = [...allowedCartConflicts, ...disallowedCartConflicts];

  const tripConflicts = [...allowedTripConflicts, ...disallowedTripConflicts];

  const duplicateCartTrips = [
    ...allowedDuplicateCartTrips,
    ...disallowedDuplicateCartTrips,
  ];

  const renderTravellers = ({ jsonData }: { jsonData: string }) => {
    const data = parseJsonString(jsonData);

    if (!data) return null;

    const { VoucherTravellers } = data;

    const html = VoucherTravellers.map((traveller: IEmployeeName, index :number) => {
      const comma = index > 0 ? ', ' : '';

      return (
        <Text
          key={ `trip_traveller_${index}_${traveller.LastName}` }
          type='NORMAL_14'
          color='gray'
        >
          { comma }{ getEmployeeFullNameWithSimplicity(traveller) }
        </Text>
      );
    });

    return (
      <span>{ html }</span>
    );
  };

  const renderTripItem = (item: TripConflicts) => {
    const { serviceType } = item;

    return getTripItem[serviceType](
      item,
      renderTravellers(item),
      serviceTypeRu(serviceType),
    );
  };

  const renderCartItem = (item: CartConflicts | ISourcesItem) => {
    const { ServiceType, Employees } = item;
    const employees = Employees.map(({ Employee }) => getEmployeeFullNameWithSimplicity(Employee)).join(', ');

    return getCartItem[ServiceType](
      item as unknown as ISourcesItem,
      employees,
      serviceTypeRu(ServiceType),
    );
  };

  const renderItems = (
    items: CartConflicts[] | TripConflicts[],
    field: FieldType,
    titleTrip: string,
  ) => {
    const isCart = field !== FIELD.TRIP;
    const titleText = isCart
      ? CHECKOUT.DUBLICATE.TITLES[field as keyof typeof CHECKOUT.DUBLICATE.TITLES]
      : titleTrip;

    const renderInfo = (item: CartConflicts | TripConflicts) => (
      isCart
        ? renderCartItem(item as CartConflicts)
        : renderTripItem(item as TripConflicts)
    );

    return (
      <div className={ styles.items }>
        <Text className={ styles.title } type='NORMAL_18'>{ titleText }</Text>
        { items.map((i) => (
          <div className={ styles.item } key={ 'id' in i ? i.id : i.Id }>
            { renderInfo(i) as unknown as React.ReactNode }
          </div>
        )) }
      </div>
    );
  };

  const isDisallowed = () =>
    disallowedCartConflicts.length > 0
    || disallowedTripConflicts.length > 0
    || disallowedDuplicateCartTrips.length > 0
    || duplicateBookings.length > 0;

  const renderCancellationInfo = () => {
    if (!isConflict) return null;

    return (
      <Text type='NORMAL_18'>
        { LABELS.INFO }
      </Text>
    );
  };

  const renderDisallowedInfo = () => {
    if (!isDisallowed()) return null;

    return (
      <Text type='NORMAL_18'>
        { LABELS.DISALLOWED_INFO }
      </Text>
    );
  };

  const renderCancelButton = () => {
    if (isDisallowed()) return null;

    return (
      <Button
        className={ styles.cancel }
        type='textual-medium'
        onClick={ onClose }
        qaAttr={ QA_ATTRIBUTES.cart.duplicate.buttons.close }
      >
        { LABELS.CANCEL }
      </Button>
    );
  };

  const renderDuplicateBookings = () => {
    if (!duplicateBookings.length) return null;

    return (
      <>
        <DuplicateBookings
          duplicateBookings={ duplicateBookings }
          sources={ sources }
          renderItem={ renderCartItem }
        />
      </>
    );
  };

  const cartItemsHtml = cartConflicts.length > 0 && renderItems(
    cartConflicts,
    FIELD.CART as FieldType,
    allowedTitleTrip || disallowedTitleTrip,
  );

  const tripItemsHtml = tripConflicts.length > 0 && renderItems(
    tripConflicts,
    FIELD.TRIP as FieldType,
    allowedTitleTrip || disallowedTitleTrip,
  );

  const duplicateItemsHtml = duplicateCartTrips.length > 0 && renderItems(
    duplicateCartTrips,
    FIELD.DUPLICATECARTS as FieldType,
    allowedTitleTrip || disallowedTitleTrip,
  );

  const headerText = tripItemsHtml && !cartItemsHtml
    ? tripHeader(tripConflicts)
    : CHECKOUT.DUBLICATE.HEADER.CART;

  const borderHtml = tripItemsHtml && cartItemsHtml && <div className={ styles.border } />;

  const borderHtmlForBooking = duplicateBookings.length
    ? borderHtml
    : null;

  const isDuplicateCartHeader = headerText === CHECKOUT.DUBLICATE.HEADER.CART;

  const headerClassName = `${styles.header}${
    isDuplicateCartHeader
      ? ` ${styles.error}`
      : ''
  }`;

  return (
    <Dialog
      show={ show }
      onChange={ onClose }
      showClosing={ !isDisallowed() }
      qaAttrWrapper={ QA_ATTRIBUTES.cart.duplicate.wrapper }
      qaAttr={ QA_ATTRIBUTES.cart.duplicate.close }
      outsideClickClosing={ false }
    >
      <div className={ styles.wrap }>
        <Text
          qaAttr={ QA_ATTRIBUTES.cart.duplicate.header }
          className={ headerClassName }
          type='bold_20'
        >
          { headerText }
        </Text>
        { tripItemsHtml }
        { duplicateItemsHtml }
        { borderHtml }
        { cartItemsHtml }
        { renderCancellationInfo() }
        { borderHtmlForBooking }
        { renderDuplicateBookings() }
        { renderDisallowedInfo() }
        <div className={ styles.action }>
          <Button
            type='primary'
            onClick={ onBack }
            qaAttr={ QA_ATTRIBUTES.cart.duplicate.buttons.back }
          >
            { LABELS.BACK }
          </Button>
          { renderCancelButton() }
        </div>
      </div>
    </Dialog>
  );
};

export default DuplicateDialog;
