const FLOATREGEXP = /^([0-9]+([.,][0-9]{0,2})?)$/;
const COMMAREGEXP = /[,]/;
const DOTREGEXP = /[.]/;
const THREEDIGITS = /(\d)(?=(\d\d\d)+([^\d]|$))/g;
const WHITESPACES = / /g;
const SPECIALANDCYRCHARS = /[^a-zA-Z]/g;
const SPECIALCHARSBYGROUPS = /([-!$%^&\s*()_+@|~=`\\#{}[\]:";'<>?,./])/ig;
const ESCAPECHARS = /[-/\\^$*+?.()|[\]{}]/g;
const SPACE_OR_HYPHEN = /[-|\s]/g;
const DIGITSANDPLUS = /[^+\d]/g;

const ONLYRUCHARS = /^[А-ЯЁа-яё]*$/;
const ONLYENCHARS = /^[A-Za-z]*$/;
const ONLYANYHARS = /[\w\S]/i;

const RUCHARSDOUBLESPACEHYPHEN = /^[А-ЯЁа-яё]+([\s|-]?[А-ЯЁа-яё])*[\s|-]?[А-ЯЁа-яё]*$/;
const ENCHARSDOUBLESPACEHYPHEN = /^[A-Za-z]+([\s|-]?[A-Za-z])*[\s|-]?[A-Za-z]*$/;
const CHARSDOUBLESPACEHYPHEN = /^[A-Za-zА-ЯЁа-яё]+([\s|-]?[A-Za-zА-ЯЁа-яё])*[\s|-]?[A-Za-zА-ЯЁа-яё]*$/;

const RUCHARS = /[А-ЯЁа-яё]/g;

const INTEGER_NUMBER_SIX_SYBMOL = /[0-9]{6}/;
const NUMBER_LENGTH = /^(([0-9]{9})|(''))?$/;

const PRICE_FLOAT_REG_EXP = /^(?:\d{1,6}([.]\d{1,2})?)$/;
const PRICE_FLOAT_REG_EXP_NO_OGR = /^(?:\d+([.]\d{1,2})?)$/;

const LINE_FEED_OR_CARRIAGE_RETURN = /(?:\r\n|\r|\n)/g;

const CAPITAL_EN_CHARS = /[A-Z]/;
const LOWERCASE_EN_CHARS = /[a-z]/;
const INTEGER_NUMBER = /\d/;
const STARTS_WITH_NUMBER = /^\d/;
const SYMBOLS = /[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/;
const ONLY_NUMBERS = /[^\d]/g;
const ONLY_NUMBERS_OR_EMPTY = /^\d*$/;
const NOT_NUMBERS = /\D/g;
const GROUP_NOT_NUMBERS = /(\D)/g;
const FOUR_GROUPS_FOUR_NUMBERS = /(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})/;
const FIRST_LETTER = /\b\w/g;
const ONLY_VALID_NUMBERS = /^(?!0\d)\d+$/;
const PRICE_FLOAT_REG_EXP_BIG = /^(?:\d{1,12}([.]\d{1,2})?)$/;
const PRICE_FLOAT_REG_EXP_NO_OGR_BIG = /^(?:\d+([.]\d{1,2})?)$/;
const EACH_THREE_CHAR_SPACE = /(.{3})/g;
const REPLACE_COMAS_WITH_DOTS = /[,]/g;
const MORE_ONE_SPACE = /\s+/g;
const INTEGER = /^[1-9]\d*$/;

export {
  FLOATREGEXP,
  COMMAREGEXP,
  DOTREGEXP,
  THREEDIGITS,
  WHITESPACES,
  SPECIALANDCYRCHARS,
  SPECIALCHARSBYGROUPS,
  ESCAPECHARS,
  SPACE_OR_HYPHEN,
  DIGITSANDPLUS,
  ONLYRUCHARS,
  ONLYENCHARS,
  ONLYANYHARS,
  RUCHARSDOUBLESPACEHYPHEN,
  ENCHARSDOUBLESPACEHYPHEN,
  CHARSDOUBLESPACEHYPHEN,
  RUCHARS,
  NUMBER_LENGTH,
  INTEGER_NUMBER_SIX_SYBMOL,
  LINE_FEED_OR_CARRIAGE_RETURN,
  PRICE_FLOAT_REG_EXP,
  CAPITAL_EN_CHARS,
  LOWERCASE_EN_CHARS,
  INTEGER_NUMBER,
  STARTS_WITH_NUMBER,
  SYMBOLS,
  ONLY_NUMBERS,
  PRICE_FLOAT_REG_EXP_NO_OGR,
  FIRST_LETTER,
  ONLY_VALID_NUMBERS,
  PRICE_FLOAT_REG_EXP_BIG,
  PRICE_FLOAT_REG_EXP_NO_OGR_BIG,
  EACH_THREE_CHAR_SPACE,
  REPLACE_COMAS_WITH_DOTS,
  ONLY_NUMBERS_OR_EMPTY,
  NOT_NUMBERS,
  FOUR_GROUPS_FOUR_NUMBERS,
  MORE_ONE_SPACE,
  GROUP_NOT_NUMBERS,
  INTEGER,
};
