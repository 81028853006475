import React from 'react';
import { Text, TextType } from 'new-ui';

import styles from './index.module.css';

interface IFieldLabelProps {
  text: React.ReactNode
  mustHave?: boolean
  type?: TextType,
}

export const FieldLabel = ({ text, type = 'NORMAL_16', mustHave = false }: IFieldLabelProps) => (
  <Text className={ styles.label } type={ type }>
    { text }
    { mustHave && <span className={ styles.must }>*</span> }
  </Text>
);
