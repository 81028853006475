import { Segment } from '../services/vipHall/types';
import { ICartItem } from './insurance';
import { ICartWpResult, IItem } from './cart';

interface IBookingService {
  getState: () => IBookingStore,
}

interface IBookingStore {
  statuses: IStatus[],
}

interface IReservationStore {
  progress: number;
  bookingId: null;
  services: null;
  errors: { CartItem: IBookingItem, Message: string }[];
  status: EBookingItemStatus;
  statuses: IItemStatus[];
  trip: IBookSuccess;
  trips: IBookSuccess[];
  startDate: string;
  cart: ICartWpResult;
  cartItemByAnalogRooms: {
    [cartItemId: number | string]: { Guid: string }[];
  },
  errorMessages: string[];
  ShowTravelHelperButton: boolean;
}

interface IData {
  hotel: {
    Name: string,
  }
  Routes: {
    Segments: Segment[]
  } [],
  StationDepart: string,
  StationArrive: string,
  StartPlace: { Address: string },
  EndPlace: { Address: string },
}

type ResType = string | IResultGotten;

interface IResultGotten {
  BookId: string,
  ErrorMessages: string[],
  OrderId?: string,
  ShowTravelHelperButton?: boolean,
}

interface IError {
  status: number,
  message: string,
}

interface ICart {
  Id: number,
  BookId: null | number,
  Approve: any[],
  IsOffline: boolean,
  Items: ICartItem[],
  MiceCartLink: null | string,
  Name: string,
  ServerTime: string,
  ShowPurchaseButton: boolean,
  Status: string,
  UserAnalytics: number[],
}

interface IEmployee {
  Employee: {
    Surname: string;
    Name: string;
  }
}

interface IService {
  ServiceType: string;
  Data: string;
  Employees: IEmployee[];
}

interface IRoute {
  Segments: {
    DepartureCity: string;
    ArrivalCity: string;
  }[]
}

export interface IStatus {
  AdditionalPrice: number,
  AnalogRooms: any[],
  BookingStep: string,
  CartItemId: number,
  Changes: string,
  Error: any | null,
  NewPrice: number,
  PriceOrigin: number,
}

interface IBookingStatus {
  Changes: EBookingItemStatusChanges;
  BookingStep: BOOKINGSTEP;
  NewPrice: number;
  PriceOrigin: number;
}

interface IBookingItem {
  bookingStatus: IBookingStatus,
  ServiceType: string,
  Data: string,
}

enum BOOKINGSTEP {
  Unknown = 'Unknown',
  Metadata = 'Metadata',
  PrepareToReserve = 'PrepareToReserve',
  Reservation = 'Reservation',
  Reserved = 'Reserved',
  ReservationError = 'ReservationError',
  PrepareToBooking = 'PrepareToBooking',
  Booking = 'Booking',
  Booked = 'Booked',
  BookingError = 'BookingError',
  ReadyToBook = 'ReadyToBook',
}

export interface IBookingCheckStatusResult {
  Success: boolean;
  CartId: number;
  CartBookingStatus: EBookingItemStatus;
  Items: IItemStatus[];
  Errors: string[];
  Trip: IBookSuccess;
  Trips: IBookSuccess[];
  StartDate: string;
  Date: string;
  WaitingToContinue: boolean;
}

export interface IBookSuccess {
  TripId: number;
  InvoiceId: number[];
  InvoiceNumber: string[];
}

export interface IItemStatus {
  BookingStep: EBookingItemStatus;
  CartItemId: number;
  ProviderName: string;
  Changes: EBookingItemStatusChanges;
  PriceOrigin: number;
  NewPrice: number;
  AdditionalPrice: number;
  Error: string;
  AnalogRooms: string[];
}

export enum EBookingItemStatus {
  Unknown = 'Unknown', // На случай какого-то факапа
  Metadata = 'Metadata', // По услуге созданы метаданные
  PrepareToReserve = 'PrepareToReserve', // Подготовка к резервированию
  Reservation = 'Reservation', // Резервирование
  Reserved = 'Reserved', // Зарезервирована
  ReservationError = 'ReservationError', // Ошибка на этапе резервирования
  PrepareToBooking = 'PrepareToBooking', // Подготовка к бронированию
  Booking = 'Booking', // Бронирование/выписка
  Booked = 'Booked', // Забронирована/выписана
  BookingError = 'BookingError', // Ошибка на этапе бронирования/выписки
  ReadyToBook = 'ReadyToBook', // Готова к бронированию
}

export enum EBookingItemStatusChanges {
  NoChanges = 'NoChanges',
  PriceChanges = 'PriceChanges',
  NotEnoughPlaces = 'NotEnoughPlaces',
  SoldOut = 'SoldOut',
}

export interface IStartBookingpayload {
  bookId: string;
  errorMessages: string[];
  ShowTravelHelperButton?: boolean;
  OrderId?: string;
  ErrorMessages?: string[];
}

export interface ICancellationPenalty {
  From: string; // Время начала действия штрафика
  Base: number; // Размер штрафика от поставщика
  MF: number;
  Total: number; // Размер штрафика для клиента
  Additional: boolean; // true - штраф как у провайдера(для нас, клиент не видит)
}

interface ICancellationPolicy {
  Refundable: boolean; // Отменяемый?
  DeadLine: string; // Дата бесплатной отмены
  Penalties: ICancellationPenalty[];// Штрафики за отмену
}

export interface IAnalogRateResponse {
  Guid: string;
  RoomName: string;
  Price: number;
  CancellationPolicy: ICancellationPolicy;
  Breakfast: boolean;
}

export interface IPreparedAnalogRateResponse extends IAnalogRateResponse {
  checked: boolean;
}

export interface IErrors {
  Message: string;
  CartItem?: IItem;
}

export { BOOKINGSTEP };

export type {
  IBookingService,
  IReservationStore,
  IService,
  IRoute,
  IData,
  ResType,
  IError,
  ICart,
  IBookingItem,
};
