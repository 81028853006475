import { currentLng, getText } from '../../../i18n';

import { isLastochka } from '../services/trains/corporateTrains/lastochka';
import { isSapsan } from '../services/trains/corporateTrains/sapsan';
import { isStrij } from '../services/trains/corporateTrains/strij';
import { isNevskij } from '../services/trains/corporateTrains/nevskij';

import {
  TRAINNAMES,
  LETTERPLACE,
  TRAIN_LENGTHS,
  INDEX_NUMBER,
  SHORT_TYPE_CAR_EN,
  SEAT_TYPES,
  TRAIN_TARIFFS,
} from '../constants/train';
import { TRAINNUMBERSANDNAMES } from '../constants/trainNames';
import { QA_ATTRIBUTES } from '../constants/attributesForTests';

import { secondsToLabel } from './time';
import {
  IArrSourcesWithTransfer, ICar,
  ICompartSection,
  IFreePlace,
  IInternalSearchResult, IInternalSearchTrain,
  IInternalTrainDetailsClientData, IPlacesByCompartmentPriceClientData,
  IPlacesInfo,
  ISearchResult, ISearchTrain,
  ISection,
  ISelectedPlace,
  IShemeSection,
  TrainDetails,
} from '../types/trains';

const LABELS = {
  TRANSFER: getText('utils:train.transfer'),
  UPPER_TRANSFER: getText('utils:train.upperTransfer'),
  PLACE_POSITION_UP: getText('components:trainItem.placePositions.up'),
  PLACE_POSITION_DOWN: getText('components:trainItem.placePositions.down'),
  MAIN_KUPEK: getText('trains:car.chooseEntireCompartment.mainKupek'),
  MAIN_SINGLE: getText('trains:car.chooseEntireCompartment.mainSingle'),
  MAIN_BP: getText('trains:car.chooseEntireCompartment.mainBP'),
  TOOLTIP_KUPEK: getText('trains:car.chooseEntireCompartment.tooltipKupek'),
  TOOLTIP_SV: getText('trains:car.chooseEntireCompartment.tooltipSV'),
  TOOLTIP_SAPSAN: getText('trains:car.chooseEntireCompartment.tooltipSapsan'),
};

const prepareUrl = ({
  searchId,
  trainId,
  requestId,
  tripId = '',
  isBack = false,
}: {
  searchId: string,
  trainId?: number,
  requestId: number,
  tripId?: string,
  isBack: boolean,
}) => {
  const baseUrl = `/search/train/${searchId}/${trainId}${requestId > 0 ? '?is_request=true' : ''}`;
  const constructTripId = tripId ? `tripId=${tripId}` : '';
  const tripIdToUrl = isBack ? `&${constructTripId}` : `?${constructTripId}`;
  const isBackUrl = isBack ? '?is_back=true' : '';

  return `${baseUrl}${isBackUrl}${tripIdToUrl}`;
};

const getTrainNameByNumber = (
  // train: ITrain | ITrainStoreType | null = null,
  train: IInternalTrainDetailsClientData | IInternalSearchTrain | null = null,
  number = '',
) => {
  let trainNumber = '';

  if (number) {
    trainNumber = number;
  } else if (train) {
    // @ts-ignore
    if (train.TrainNumber) {
      // @ts-ignore
      trainNumber = train.TrainNumber;
      // @ts-ignore
    } else if (train.Number) {
      // @ts-ignore
      trainNumber = train.Number;
    }
  }

  if (isLastochka({ Number: trainNumber })) {
    return TRAINNAMES.LASTOCHKA;
  }

  if (isSapsan({ Number: trainNumber })) {
    return TRAINNAMES.SAPSAN;
  }

  if (isStrij({ Number: trainNumber })) {
    return TRAINNAMES.STRIJ;
  }

  if (isNevskij({ Number: trainNumber })) {
    return TRAINNAMES.NEVSKIJ;
  }

  return TRAINNUMBERSANDNAMES[trainNumber as keyof typeof TRAINNUMBERSANDNAMES];
};

const checkLetterInPlace = (place: string) => {
  if (typeof place === 'string' && isNaN(place as unknown as number)) {
    let letter: string;
    let description: string;

    const lastChart = place.charAt(place.length - 1);
    const info = LETTERPLACE[lastChart as keyof typeof LETTERPLACE];

    if (info) {
      letter = lastChart;
      description = info;

      return {
        letter, description,
      };
    }

    return null;
  }

  return null;
};

const getMinMaxCostsByCars = (cars: ICar[]) => {
  const startedCost = cars[0].MinimalCost.Total;
  const startedAcc = { minCost: startedCost, maxCost: startedCost };

  return cars.reduce((acc, { MinimalCost: { Total } }) => {
    const newRes = { ...acc };

    if (acc.minCost > Total) {
      newRes.minCost = Total;
    }

    if (acc.maxCost < Total) {
      newRes.maxCost = Total;
    }

    return newRes;
  }, startedAcc);
};

const preparePlaceNumber = (place: string) => !!place && (isNaN(place as unknown as number)
  ? `${parseInt(place.substring(0, place.length - 1), 10)}${place.charAt(place.length - 1)}`
  : parseInt(place, 10));

const filterByNumbers = (
  sources: IInternalSearchTrain[],
  train: IInternalSearchTrain,
  value: string,
  length: number,
) => {
  const mask = length < 2 ? `00${value}` : `0${value}`;
  const defaultMask = `${value}`;
  const trainsByNumberArr = sources.filter(({ TrainNumberLocal }) => TrainNumberLocal.includes(mask));

  if (trainsByNumberArr.length > 0) {
    return train.TrainNumberLocal.includes(mask);
  }

  return train.TrainNumberLocal.includes(defaultMask);
};

const filterByNumbersTransfer = (
  sources: IArrSourcesWithTransfer,
  train: ISearchTrain,
  value: string,
  length: number,
) => {
  const mask = length < 2 ? `00${value}` : `0${value}`;
  const defaultMask = `${value}`;
  const trainsByNumberArr =
    sources.filter(i => i.some(({ Trains }) => Trains.some(({ TrainNumber }) => TrainNumber.includes(mask))));

  if (trainsByNumberArr.length) {
    return train.TrainNumber.includes(mask);
  }

  return train.TrainNumber.includes(defaultMask);
};

const filterByWords = (
  train: ISearchTrain,
  words: string,
) => {
  const lowerCaseWords = words.toLowerCase();
  const firstLetter = lowerCaseWords[0];
  const { TrainName, TrainNumber } = train;

  const trainNameIdentifier = getTrainNameByNumber(null, TrainNumber);

  if (words.length > 1) {
    return TrainName.toLowerCase().includes(lowerCaseWords)
      || (trainNameIdentifier && trainNameIdentifier.toLowerCase().includes(lowerCaseWords));
  }

  return TrainName.toLowerCase()[0] === firstLetter
    || (TrainName && TrainName.toLowerCase()[0].includes(` ${firstLetter}`))
    || (trainNameIdentifier && trainNameIdentifier.toLowerCase()[0] === firstLetter)
    || (trainNameIdentifier && trainNameIdentifier.toLowerCase().includes(` ${firstLetter}`));
};

const checkTrainNumberOrName = (
  sources: IInternalSearchTrain[],
  train: IInternalSearchTrain,
  value: string,
) => {
  const parseOnlyNumbers = parseInt(value, 10);

  return !isNaN(parseOnlyNumbers)
    ? filterByNumbers(sources, train, value, value.length)
    : filterByWords(train, value);
};

const checkTrainNumberOrNameTransfer = (
  sources: IArrSourcesWithTransfer,
  train: ISearchTrain,
  value: string,
) => {
  const parseOnlyNumbers = parseInt(value, 10);

  return !isNaN(parseOnlyNumbers)
    ? filterByNumbersTransfer(sources, train, value, value.length)
    : filterByWords(train, value);
};

const getAdvantagesOfTime = (time: number, currentTime: number) => {
  const isAdvantages = currentTime > time;
  const diffTime = isAdvantages ? currentTime - time : time - currentTime;
  const textLength = isAdvantages ? TRAIN_LENGTHS.SHORTER : TRAIN_LENGTHS.LONGER;

  return {
    isAdvantages,
    text: `${textLength} ${secondsToLabel(diffTime)}`,
  };
};

const getAdvantagesOfPrice = (price: number, currentPrice: number) => {
  const isAdvantages = currentPrice > price;
  const diffPrice = isAdvantages ? currentPrice - price : price - currentPrice;
  const textLength = isAdvantages ? TRAIN_LENGTHS.CHEAPER : TRAIN_LENGTHS.MORE_EXPENSIVE;

  return {
    isAdvantages,
    text: `${textLength} ${diffPrice} ₽`,
  };
};

const getNamesOfTransfer = (count: number, index: number) => {
  if (count === 1) {
    return LABELS.UPPER_TRANSFER;
  }

  if (count < 4) {
    return `${INDEX_NUMBER[index as unknown as keyof typeof INDEX_NUMBER]} ${LABELS.TRANSFER}`;
  }

  return LABELS.TRANSFER;
};

const prevSegmentId = (index: number) => index - 1;

const nextSegmentId = (index: number) => index + 1;

const isDepartureChanged = (
  index: number,
  train: TrainDetails | IInternalSearchTrain,
  trains: TrainDetails[],
) => {
  if (prevSegmentId(index) >= 0) {
    const arrival = trains[prevSegmentId(index)].StationCodeTo;
    const departure = train.StationCodeFrom;

    return arrival !== departure;
  }

  return false;
};

const isArrivalChanged = (
  index: number,
  train: TrainDetails | IInternalSearchTrain,
  trains: TrainDetails[],
) => {
  if (nextSegmentId(index) < trains.length) {
    const arrival = train.StationCodeTo;
    const departure = trains[nextSegmentId(index)].StationCodeFrom;

    return arrival !== departure;
  }

  return false;
};

const getShortTypeText = (type: string, isShorter: boolean) => {
  const types = type.split(' + ');

  const substringText = (text: string) => {
    if (isShorter && types.length > 2) {
      return text.length === 2 ? text.toString().substring(0, 2) : text.toString().substring(0, 1);
    }

    return currentLng === 'ru' ? text.toString().substring(0, 4) : SHORT_TYPE_CAR_EN[text as keyof typeof SHORT_TYPE_CAR_EN];
  };

  return types.length > 1 ? types.map(t => substringText(t)).join(' + ') : substringText(type);
};

const getPositionPlace = (placesInfo: IPlacesInfo[], firstPlace: string) => {
  const currentPlaceInfo = placesInfo ? placesInfo.find(place => place.Number === firstPlace) : null;

  if (!currentPlaceInfo) return null;

  const placePosition = currentPlaceInfo.SeatType ? currentPlaceInfo.SeatType.toLowerCase() : null;

  switch (placePosition) {
    case SEAT_TYPES.UP:
      return LABELS.PLACE_POSITION_UP;

    case SEAT_TYPES.DOWN:
      return LABELS.PLACE_POSITION_DOWN;

    case SEAT_TYPES.UNDEF:
      return null;

    default:
      return null;
  }
};

const getSeatType = (
  places: string | string[],
  train: IInternalSearchTrain,
  placesInfo: string | IPlacesInfo[],
) => {
  // @ts-ignore
  const placeInfo = train ? train.Car.PlacesInfo : placesInfo;

  if (typeof places === 'string') return getPositionPlace(placeInfo as IPlacesInfo[], places);

  if (Array.isArray(places)) {
    const firstPlace = places[0];
    const lastPlace = places[places.length - 1];

    if (firstPlace === lastPlace) return getPositionPlace(placeInfo as IPlacesInfo[], firstPlace);
  }

  return null;
};

const modifySearchResponse = (res: ISearchResult): IInternalSearchResult => ({
  ...res,
  Trains: res.Trains.map((train) => ({
    ...train,
    SearchId: res.Id,
  })),
});

const combineSections = (
  sections: IShemeSection[],
  freePlaces: IFreePlace[],
  compartmentSections: ICompartSection[],
  differentTrains: boolean,
  twoFloors: boolean,
  updateLevelSections: (
    sections: IShemeSection[],
    freePlaces: IFreePlace[],
  ) => IShemeSection[],
) => {
  const updatedSections = updateLevelSections(sections, freePlaces);

  const combinedSection = updatedSections.map(section => {
    const matchingCompartments = compartmentSections.filter(compartment => compartment.CompartmentNumber && +compartment.CompartmentNumber === section.number);

    const modifiedSection = { ...section } as ISection;

    if (differentTrains || twoFloors) {
      modifiedSection.miniSections = compartmentSections;
    } else if (matchingCompartments.length > 0) {
      Object.assign(modifiedSection, ...matchingCompartments);
    }

    return modifiedSection;
  });

  return combinedSection;
};

const getPropertyValues = (
  initialSelectedPlace: ISelectedPlace[],
  differentTrains: boolean,
  foundItems?: IPlacesByCompartmentPriceClientData,
) => {
  if (initialSelectedPlace.length > 1) return null;

  switch (foundItems ? foundItems.SpecialTariff : null) {
    case TRAIN_TARIFFS.KUPEK:
      return {
        checkboxText: LABELS.MAIN_KUPEK,
        tooltipText: LABELS.TOOLTIP_KUPEK,
      };
    case TRAIN_TARIFFS.SINGLE:
      return {
        checkboxText: differentTrains
          ? LABELS.MAIN_BP
          : LABELS.MAIN_SINGLE,
        tooltipText: differentTrains
          ? LABELS.TOOLTIP_SAPSAN
          : LABELS.TOOLTIP_SV,
      };
    default:
      return null;
  }
};

const updateLevelSections = (
  sections: IShemeSection[],
  freePlaces: IFreePlace[],
) => {
  const readyFreePlaces: IFreePlace[] = [];
  freePlaces.forEach(({
    places,
    price,
    nonRefundable,
    readOnly,
    buyFull,
    buyFullPrices,
    buyFullAltPrices,
    travellers,
    hasAlternative,
    altPrice,
    agentFee,
    altFee,
  }) => {
    places?.forEach((place) => {
      const litter = (/[а-яА-ЯЁё]/.test(place)) ? place[place.length - 1] : '';
      const number = parseInt(place, 10);

      readyFreePlaces.push({
        litter,
        number,
        price,
        altPrice,
        nonRefundable,
        readOnly,
        buyFull,
        buyFullPrices,
        buyFullAltPrices,
        travellers,
        hasAlternative,
        agentFee,
        altFee,
      });
    });
  });

  if (readyFreePlaces.length) {
    readyFreePlaces[0].qaAttr = QA_ATTRIBUTES.train.current.availablePlace;
  }

  return sections.map(({ css, number: sectionNumber, places }) => {
    let litter = '';

    const updatedPlaces = places.map((place) => {
      const placeData = readyFreePlaces.find(({ number }) => number === place.number);

      if (!placeData) return place;

      const {
        litter: placeLitter,
        nonRefundable,
        number,
        price,
        agentFee,
        altFee,
        readOnly,
        buyFull,
        buyFullPrices,
        buyFullAltPrices,
        travellers,
        hasAlternative,
        altPrice,
        qaAttr,
      } = placeData as IFreePlace;

      if (placeLitter) litter = placeLitter;

      const buyFullPlaces = buyFull ? places.map(({ number: placeNumber }) => placeNumber) : null;

      return {
        ...place,
        nonRefundable,
        number,
        price,
        agentFee,
        altFee,
        altPrice,
        readOnly,
        buyFullPlaces,
        buyFullPrices,
        buyFullAltPrices,
        travellers,
        disabled: false,
        hasAlternative,
        qaAttr,
      };
    });

    return {
      css,
      number: sectionNumber,
      places: updatedPlaces,
      litter,
    };
  });
};

export {
  prepareUrl as default,
  prepareUrl,
  getTrainNameByNumber,
  checkLetterInPlace,
  getMinMaxCostsByCars,
  preparePlaceNumber,
  checkTrainNumberOrName,
  getAdvantagesOfTime,
  getAdvantagesOfPrice,
  getNamesOfTransfer,
  isDepartureChanged,
  isArrivalChanged,
  getShortTypeText,
  checkTrainNumberOrNameTransfer,
  getSeatType,
  modifySearchResponse,
  combineSections,
  getPropertyValues,
  updateLevelSections,
};
