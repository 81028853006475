import { getText } from '../../../i18n';
import { languages } from '../../../i18n/locales/consts';

import CONFIG from '../../../config';

import logoDecathlon from '../../../svg/logos/decathlon.svg';
import logoPlodorodie from '../../../svg/logos/plodorodie.svg';
import logoAKBarsGarant from '../../../svg/logos/aKBarsGarant.svg';
import logoCountourTravel from '../../../svg/logos/contourTravel.svg';
import logoPetrolCards from '../../../svg/logos/petrolcards.svg';
import logoVMZ from '../../../svg/logos/vmz.svg';
import logoAcrom from '../../../svg/logos/acron.svg';
import logoYadro from '../../../svg/logos/yadro.svg';
import logoCkr from '../../../svg/logos/ckr.svg';
import logoRusGidro from '../../../svg/logos/rusGidro.svg';
import logoHrLink from '../../../svg/logos/hRLink.svg';
import logoAlfaKom from '../../../svg/logos/alfa_kom.svg';

interface IconSizes {
  [size: string]: string;
}

export interface IconList {
  [key: string]: IconSizes;
}

const ACCOUNTS = {
  BOOKINGCOM: 'guestbooking@smartway.today',
};

const UI_SCHEMES = {
  19202: {
    brandColor: '#2eb2a0',
    companyName: 'petrolcards',
    showS7Program: false,
    showReports: false, // вкл/выкл раздел отчетность; раздача прав: доступ к финотчетности, получение счетов, получение закрывающих; вкл/выкл кнопки скачивания "Счет после покупки поездки"
    customHeroTitle: true,
    showPaymentButton: false, // На Смартдеске в разделе "Ваша задолженность" вкл/выкл Пополнение картой
    showPaymentScore: false, // В "Доступно" вкл/выкл ссылка "Пополнить счет"
    paymentRedirectUrl: null,
    showLastInvoice: false, // На Смартдеске в разделе "Ваша задолженность" вкл/выкл Последний счет
    showCompanyInfo: false, // В Настройках вкл/выкл вкладку/меню "Реквизиты компании"
    showChartsAnalytics: false,
    copyrightName: 'Smartway',
    headTitle: 'Smartway',
    phone: CONFIG.ABOUT.PHONE_PETROLCARDS,
    email: 'service@pprcard.ru',
    reportRedirectUrl: null,
    showTabReportsWithoutFinanceRights: false,
    showTaxi: false,
    emailToQuality: 'service@pprcard.ru',
  },
  20431: {
    brandColor: '#2eb2a0',
    companyName: 'contourTravel',
    showS7Program: false,
    showReports: false,
    customHeroTitle: false,
    showPaymentButton: false,
    showPaymentScore: true,
    paymentRedirectUrl: 'https://k-travel.kontur.ru/balance-and-operations',
    showLastInvoice: false,
    showCompanyInfo: false,
    showChartsAnalytics: false,
    copyrightName: 'Контур.Тревел',
    headTitle: 'Контур.Тревел',
    phone: CONFIG.ABOUT.PHONE_CONTOUR_TRAVEL,
    email: 'k.travel@kontur.ru',
    reportRedirectUrl: 'https://k-travel.kontur.ru',
    showTabReportsWithoutFinanceRights: true,
    showTaxi: false,
    emailToQuality: 'k.travel@kontur.ru',
  },
  32304: {
    brandColor: '#ff1d1d',
    companyName: 'aKBarsGarant',
    showS7Program: true,
    showReports: false,
    customHeroTitle: false,
    showPaymentButton: false,
    showPaymentScore: false,
    paymentRedirectUrl: null,
    showLastInvoice: false,
    showCompanyInfo: false,
    showChartsAnalytics: false,
    copyrightName: 'АК БАРС Гарант Трэвел',
    headTitle: 'АК БАРС Гарант Трэвел',
    phone: CONFIG.ABOUT.PHONE_AK_BARS_GARANT,
    email: 'info@abgarant.ru',
    tabReportRedirectUrl: null,
    reportRedirectUrl: null,
    showTabReportsWithoutFinanceRights: false,
    showTaxi: true,
    emailToQuality: '',
  },
  35748: {
    brandColor: '#ff1d1d',
    companyName: 'ckr',
    showS7Program: true,
    showReports: true,
    customHeroTitle: false,
    showPaymentButton: true,
    showPaymentScore: true,
    paymentRedirectUrl: null,
    showLastInvoice: true,
    showCompanyInfo: true,
    showChartsAnalytics: true,
    copyrightName: 'Smartway',
    headTitle: 'Smartway',
    phone: CONFIG.ABOUT.PHONEWITHHYPHENS,
    email: 'help@smartway.today',
    tabReportRedirectUrl: null,
    reportRedirectUrl: null,
    showTabReportsWithoutFinanceRights: false,
    showTaxi: true,
    emailToQuality: '',
  },
};

const SMARTWAY = 'smartway';

const ICON_LIST: IconList = {
  smartway: {
    '16x16': '/icons/smartway/favicon-16x16.png',
    '32x32': '/icons/smartway/favicon-32x32.png',
    '57x57': '/icons/smartway/apple-icon-57x57.png',
    '60x60': '/icons/smartway/apple-icon-60x60.png',
    '72x72': '/icons/smartway/apple-icon-72x72.png',
    '76x76': '/icons/smartway/apple-icon-76x76.png',
    '96x96': '/icons/smartway/favicon-96x96.png',
    '114x114': '/icons/smartway/apple-icon-114x114.png',
    '120x120': '/icons/smartway/apple-icon-120x120.png',
    '144x144': '/icons/smartway/apple-icon-114x114.png',
    '152x152': '/icons/smartway/apple-icon-152x152.png',
    '180x180': '/icons/smartway/apple-icon-180x180.png',
    '192x192': '/icons/smartway/android-icon-192x192.png',
  },
  contourTravel: {
    '16x16': '/icons/contourTravel/favicon-16x16.png',
    '32x32': '/icons/contourTravel/favicon-32x32.png',
    '180x180': '/icons/contourTravel/apple-icon-180x180.png',
    '192x192': '/icons/contourTravel/android-icon-192x192.png',
  },
  aKBarsGarant: {
    '16x16': '/icons/aKBarsGarant/favicon-16x16.png',
    '32x32': '/icons/aKBarsGarant/favicon-32x32.png',
    '180x180': '/icons/aKBarsGarant/apple-icon-180x180.png',
    '192x192': '/icons/aKBarsGarant/android-icon-192x192.png',
  },
  smartagent: {
    '16x16': '/icons/smartagent/favicon-16x16.png',
    '32x32': '/icons/smartagent/favicon-32x32.png',
    '96x96': '/icons/smartagent/favicon-96x96.png',
    '180x180': '/icons/smartagent/apple-icon-180x180.png',
    '192x192': '/icons/smartagent/android-icon-192x192.png',
  },
} as const;

const ACCOUNT_LANGUAGES = {
  RU: getText('settings:employees.employee.steps.rights.accountLanguages.ru'),
  EN: getText('settings:employees.employee.steps.rights.accountLanguages.en'),
};

const ACCOUNT_LANGUAGES_VALUES = [
  {
    value: languages.ru,
    label: ACCOUNT_LANGUAGES.RU,
  },
  {
    value: languages.en,
    label: ACCOUNT_LANGUAGES.EN,
  },
];

const COMPANY_LOGOS = {
  BY_IDS: {
    19778: logoDecathlon,
    32433: logoPlodorodie,
  },
  BY_NAME: {
    aKBarsGarant: logoAKBarsGarant,
    contourTravel: logoCountourTravel,
    petrolcards: logoPetrolCards,
    ckr: logoCkr,
  },
};

const AGREGATORS = {
  IDS: [
    19778,
    32433,
    35824,
    35824,
    41454,
    15390,
    14048,
    38980,
  ],
  NAMES: [
    'aKBarsGarant',
    'contourTravel',
    'petrolcards',
  ],
};

const ACCOUNT_LOGOS = {
  BY_IDS: {
    1393: logoVMZ,
    16824: logoYadro,
    35636: logoAcrom,
    35748: logoCkr,
    37844: logoRusGidro,
    37845: logoRusGidro,
    37511: logoHrLink,
    38980: logoRusGidro,
    39626: logoAlfaKom,
  },
};

const ACCOUNT_TYPE = {
  SMARTWAY: 'smartway',
  SMART_AGENT: 'smartagent',
};

const AGGREGATORS_ACCOUNT = {
  CKR: 35748,
  PPR: 19202,
};

export const CART_WARN_COMPANIES = [
  1393,
  42484,
];

export {
  ACCOUNTS,
  UI_SCHEMES,
  ACCOUNT_LANGUAGES_VALUES,
  ICON_LIST,
  SMARTWAY,
  COMPANY_LOGOS,
  ACCOUNT_LOGOS,
  AGREGATORS,
  ACCOUNT_TYPE,
  AGGREGATORS_ACCOUNT,
};
