import { Moment } from 'moment';
import { observable, action, makeObservable } from 'mobx';

import { DEFAULT_ANALYTICS_SMARTAGENT } from './const';

import { getMoment, getStartMonth, startOfDay } from '../../utils/formatDate';
import { addFirstChars } from '../../utils/companies';

import type { Trips, CalendarTrips, INotepadReservedBE, ReportData } from './types';
import { CompaniesType } from '../settings/types';
import { CommonChartsAnalyticsType } from '../chartsAnalytics/types';

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable trips: Trips = [];
  @observable tripsInCalendar: CalendarTrips = [];
  @observable loading = true;
  @observable isSearchSmartdesk = false;
  @observable selectedCompany: number = 0;
  @observable selectedIndexCompany: number = 0;
  @observable notepad = {
    air: 0,
    hotel: 0,
  };
  @observable isNotepad = false;
  @observable isTrips = false;
  @observable analytics: ReportData = DEFAULT_ANALYTICS_SMARTAGENT;
  @observable resAnalytics: CommonChartsAnalyticsType = {
    startDate: getStartMonth(getMoment()),
    endDate: getMoment(),
    minDate: null,
    maxDate: startOfDay(),
    selectedCompanies: [],
    companies: [],
  };
  @observable isErrorAnalytics = false;

  @action
  setLoading = (value: boolean): void => {
    this.loading = value;
  };

  @action
  setTrips = (trips: Trips): void => {
    this.trips = trips;
  };

  @action
  setTripsInCalendar = (trips: CalendarTrips): void => {
    this.tripsInCalendar = trips;
  };

  @action
  setSearchSmartdesk = (value: boolean): void => {
    this.isSearchSmartdesk = value;
  };

  @action
  getReservationNotepad = ({ Air, Hotel }: INotepadReservedBE) => {
    this.notepad.air = Air;
    this.notepad.hotel = Hotel;
  };

  @action
  setIsNotepad = (value: boolean) => {
    this.isNotepad = value;
  };

  @action
  setIsTrips = (value: boolean) => {
    this.isTrips = value;
  };

  @action
  getCompanies = (value: CompaniesType) => {
    this.resAnalytics.companies = value.map(({ CompanyId, ShortCompanyName, CompanyName }) => ({
      label: addFirstChars(ShortCompanyName || CompanyName, 36),
      value: CompanyId,
    }));

    this.selectedCompany = value[0].CompanyId;
    this.resAnalytics.selectedCompanies = [value[0].CompanyId];
  };

  @action
  selectCompany = (value: number) => {
    this.selectedCompany = value;

    this.selectedIndexCompany = this.resAnalytics.companies.findIndex(fund =>
      fund.CompanyId === value,
    );
  };

  @action
  selectIndexCompany = (value: number) => {
    this.selectedIndexCompany = this.resAnalytics.companies.findIndex(fund => fund.value === value);
  };

  @action
  setAnalyticsSmartagent = (value: ReportData) => {
    this.analytics = value;
  };

  @action
  changeSelectedCompanies = (value: []) => {
    this.resAnalytics.selectedCompanies = value;
  };

  @action
  changeStartDate = (value: Moment) => {
    if (value.isAfter(this.resAnalytics.endDate)) {
      this.resAnalytics.startDate = value;
      this.resAnalytics.endDate = value;
    } else {
      this.resAnalytics.startDate = value;
    }
  };

  @action
  changeEndDate = (value: Moment) => {
    this.resAnalytics.endDate = value;
  };

  @action
  setErrorAnalytics = (value: boolean) => {
    this.isErrorAnalytics = value;
  };
}

const SmartdeskStore = new Store();

export { SmartdeskStore, Store as SmartdeskStoreType };
