import moment, { DurationInputArg1, DurationInputArg2, Moment, MomentInput, unitOfTime } from 'moment';
import { getText } from '../../../i18n';
import { languages } from '../../../i18n/locales/consts';

import { DATEFORMATS, EnumDateLimits, PATTERN, UNIT_OF_TIME_BASE } from '../constants/dateFormats';

import { Place } from '../../page/Cart/components/MinimalFares/types';

import { getFormattedLocation } from './minimalFares';
import parseUnix from './parseDateTime';
import trimTimezone from './trimTimezone';

import { IDates } from '../types/insurance';

interface FormatRangeDateWithSimplicityOpts {
  locale?: string,
  withTime?: boolean,
  fullPattern?: string,
  monthPattern?: string,
  withoutSpaceBetweenDates?: boolean,
}

moment.updateLocale('ru', {
  invalidDate: '',
});
moment.updateLocale('en', {
  invalidDate: '',
});

const LABELS = {
  yesterday: (date: string): string => getText('services:format.yesterday', { date }),
  inDate: (date: string, twoDate: string): string => getText('services:format.dateIn', { date, twoDate }),
};

const defaultPattern = 'DD.MM.YYYY';
const fullFormPattern = 'YYYY-MM-DD HH:mm:ss';
const fullFormYearTime = 'YYYY-MM-DD HH:mm';
const fullFormYear = 'YYYY-MM-DD';
const hoursAndMinutesPattern = 'HH:mm';
const utcMoscow = '+3:00';
const defaultPatternWithTime = 'DD.MM.YYYY HH:mm';
const textualMonthPattern = 'DD MMMM YYYY';
const textualMonthPatternWithoutZero = 'D MMMM YYYY';
const textualMonthWithHoursAndMinutesPattern = `D MMMM YYYY, ${hoursAndMinutesPattern}`;

const textualMonthAndYearWithHoursAndMinutesPattern = `D MMMM YYYYг., ${hoursAndMinutesPattern}`;
const daysAndMounth = 'DD.MM';
const textualMonthWithHours = `D MMMM ${hoursAndMinutesPattern}`;
const MINUTESSECONDSPATTERN = 'mm:ss';
const MINUTE_SECONDS_PATTERN = 'm: ss';

const isCurrentYear = (value: MomentInput) => {
  const currentYear = moment().year();
  const dateObject = moment(value);

  return currentYear === dateObject.year();
};

const formatDate = (value: MomentInput, pattern: string = PATTERN.DAY_OF_MONTH_WITH_YEAR): string => {
  const dateObject = moment(value);

  if (isCurrentYear(value) && pattern === PATTERN.DAY_OF_MONTH_WITH_YEAR) {
    return dateObject.format(PATTERN.DAY_OF_MONTH);
  }

  return dateObject.format(pattern);
};

const getPatternDateWithMinutes = (value: MomentInput) => (
  isCurrentYear(value) ? PATTERN.DAY_OF_MONTH_WITH_TIME : PATTERN.DATE_TIME_WITH_YEAR
);

const formatDateWithMinutes = (value: MomentInput) => formatDate(value, getPatternDateWithMinutes(value));

const calendar = (value: MomentInput): string => moment(value).calendar();

const getCurrentLocale = (): string => moment.locale() || languages.ru;

const momentObject = (value?: MomentInput): Moment => moment(value);
const momentObjectUTC = (value: MomentInput): Moment => moment.utc(value);
const momentUtc = (): Moment => moment.utc();
const formatMomentUtc = (pattern: string = PATTERN.YEAR_MONTH_DAY_TIME): string => formatDate(moment.utc(), pattern);
const formatMomentUtcObject = (value: MomentInput, pattern: string = PATTERN.YEAR_MONTH_DAY_TIME): string => formatDate(moment.utc(value), pattern);
const addUtcOffset = (value: MomentInput): Moment => moment(value).utcOffset(utcMoscow);
const dateNowWithOffset = () => moment().utcOffset(utcMoscow).format();
const getLocalMomentObject = (value: MomentInput, format: string): string => momentObject(value).local().format(format);
const getMoment = (): Moment => moment();
const isValidDateObject = (value: MomentInput): boolean => moment(value).isValid();

const startOfMonth = (): Moment => getMoment().startOf('M');
const startOfDay = (): Moment => getMoment().startOf('d');
const startOfYear = (): Moment => getMoment().startOf('y'); // unitOfTime: unitOfTime.StartOf
const getStartOfYearValue = (value: MomentInput) => moment(value).startOf('y');

const lastDayPreviousMonths = () => moment().date(0);

const getEndOfYear = (value: MomentInput): Moment => momentObject(value).endOf('year');
const getStartOfYear = (value: number | string): string => moment().year(Number(value)).startOf('year').format(defaultPattern);

const getStartOfDay = (value: MomentInput): Moment => momentObject(value).startOf('day');

const getStartMonth = (value: MomentInput): Moment => momentObject(value).startOf('M');
const getEndOfMonth = (value: MomentInput): Moment => momentObject(value).endOf('M');
const getStartCurrentQuarter = (): Moment => getMoment().startOf('quarter');

const getStartPreviousYear = () : Moment => getMoment().subtract(1, EnumDateLimits.YEAR).startOf(EnumDateLimits.YEAR);

const getStartPreviousQuarter = (): Moment => getMoment().subtract(1, 'quarter').startOf('quarter');

const getStartPreviousMonth = (): Moment => getMoment().subtract(1, 'M').startOf('M');

const getEndPreviousMonth = (): Moment => getMoment().subtract(1, 'M').endOf('M');

const getReduceNumberDays = (value: string, days: number = 0) : string => moment(value).subtract(days, 'days').format();

const getReduceNumberHours = (value: string, hours: number) : string => moment(value).subtract(hours, 'hours').format();

const getMoscowTime = (value: MomentInput, pattern: string = PATTERN.DAY_OF_MONTH_WITH_YEAR): string => formatDate(moment(value).add(3, UNIT_OF_TIME_BASE.HOUR), pattern);

const hasDayCome = (date: MomentInput): boolean => {
  const today = moment().startOf('d').valueOf();

  return moment(date).startOf('d').valueOf() <= today;
};

const isMoment = (value: MomentInput): boolean => moment.isMoment(value);

const isValidMomentObject = (date: MomentInput): boolean => {
  if (isMoment(date)) return isValidDateObject(date);

  const dateToMoment = momentObject(date);

  return isValidMomentObject(dateToMoment);
};

const normalizedDate = (date: MomentInput): MomentInput | Moment => (isMoment(date) ? date : getMoment());

const isBeforeDate = (firstValue: MomentInput, secondValue: MomentInput, limit = EnumDateLimits.MILLISECOND): boolean => moment(firstValue).isBefore(moment(secondValue), limit);

const isAfterDate = (firstValue: MomentInput, secondValue: MomentInput): boolean => moment(firstValue).isAfter(moment(secondValue));

const isSameDate = (first: MomentInput, second: MomentInput, limit = EnumDateLimits.MILLISECOND): boolean => moment(first).isSame(moment(second), limit);

const isSameOrAfter = (first: MomentInput, second: MomentInput): boolean => moment(first).isSameOrAfter(moment(second));

const isSameOrBefore = (first: MomentInput, second: MomentInput): boolean => moment(first).isSameOrBefore(moment(second));

const isBetweenDate = (first: MomentInput, second: MomentInput, third: MomentInput, granularity: unitOfTime.StartOf = 'day', inclusivity: '()' | '[)' | '(]' | '[]' | undefined = '[]') =>
  moment(first).isBetween(second, third, granularity, inclusivity);

interface MergeOptionsType {
  locale: string,
  withTime: boolean
  withoutSpaceBetweenDates: boolean,
  fullPattern: string,
  monthPattern: string,
}

const mergeOptions = ({ locale, withTime, fullPattern, monthPattern, withoutSpaceBetweenDates }: any): MergeOptionsType => ({
  locale: locale || getCurrentLocale(),
  withTime: withTime || false,
  withoutSpaceBetweenDates: withoutSpaceBetweenDates || false,
  fullPattern: fullPattern || PATTERN.DAY_OF_MONTH_WITH_YEAR,
  monthPattern: monthPattern || PATTERN.DAY_OF_MONTH,
});

const formatRangeDateWithSimplicity = (
  start: MomentInput,
  end?: MomentInput,
  opts: FormatRangeDateWithSimplicityOpts = {
    locale: getCurrentLocale(),
    withTime: false,
    fullPattern: PATTERN.DAY_OF_MONTH_WITH_YEAR,
    monthPattern: PATTERN.DAY_OF_MONTH,
    withoutSpaceBetweenDates: false,
  },
) => {
  const options = mergeOptions(opts);

  const currentYear = moment().year();
  const mStart = moment(start).locale(options.locale);
  const mEnd = moment(end).locale(options.locale);

  let startDateFormat = options.fullPattern;
  let endDateFormat = options.fullPattern;

  if (mStart.year() === mEnd.year()) {
    startDateFormat = options.monthPattern;
  }

  if (mEnd.year() === currentYear) {
    endDateFormat = options.monthPattern;
  }

  if (mEnd.year() === mStart.year() && mStart.month() === mEnd.month()) {
    startDateFormat = 'D';
  }

  if (options.withTime) {
    endDateFormat = `${options.monthPattern} ${DATEFORMATS.TIME}`;
    startDateFormat = endDateFormat;
  }

  const rStart = mStart.format(startDateFormat);
  const rEnd = mEnd.format(endDateFormat);

  if (!end) {
    return rStart;
  }

  if (
    mStart
      .clone()
      .startOf('day')
      .isSame(mEnd.clone().startOf('day')) &&
    !options.withTime
  ) {
    return rEnd;
  }

  return options.withoutSpaceBetweenDates ? `${rStart}–${rEnd}` : `${rStart} – ${rEnd}`;
};

const formatLocationTemplate = (DeparturePlace: Place, ArrivalPlace: Place) => (
  `${getFormattedLocation(DeparturePlace)} - ${getFormattedLocation(ArrivalPlace)}`
);

const formatDateTemplate = (departureDate: string, arrivalDate: string, pattern = DATEFORMATS.TIME) => (
  `${getLocalMomentObject(departureDate, pattern)} - ${getLocalMomentObject(arrivalDate, pattern)}`
);

const formatDateRange = (start: Moment, end: Moment): string => {
  const startTime = start.format(textualMonthWithHoursAndMinutesPattern);

  if (isSameDate(start, end)) {
    return startTime;
  }

  if (end.isSame(start, 'day')) {
    return `${startTime} - ${end.format(hoursAndMinutesPattern)}`;
  }

  return `${startTime} - ${end.format(textualMonthWithHoursAndMinutesPattern)}`;
};

const addDays = (date: Moment, value = 1) => date.clone().add(value, 'd');

const dateWithoutCurrentYear = (value: MomentInput): string => {
  const currentYear = moment().year();
  const year = moment(value).year();

  let pattern = PATTERN.DAY_OF_MONTH_WITH_YEAR;

  if (currentYear === year) {
    pattern = PATTERN.DAY_OF_MONTH;
  }

  return formatDate(value, pattern);
};

const nextDateAfterCurrent = (startDate: MomentInput): Moment => (isSameOrAfter(moment(), startDate)
  ? moment().startOf('d')
  : moment(startDate).startOf('d'));

const dateWithoutMoment = (value: MomentInput, pattern = DATEFORMATS.DATE): string => formatDate(parseUnix(value), pattern);

const isCurrentDateAfterMoscow = (value: MomentInput): boolean => {
  const utcValue = moment.utc(value).format();
  const utcDate = moment.utc().add(3, UNIT_OF_TIME_BASE.HOUR).format();

  return momentObject(utcDate).isAfter(utcValue);
};

const endPreviousDateMoment = (): Moment => moment().subtract(1, 'd').endOf('d');

const endPreviousDateMomentWithTodayMax = (): Moment => moment.max(endPreviousDateMoment(), moment().startOf('d'));

const endPreviousDate = (value: MomentInput, pattern = PATTERN.FULL_TIME_WITH_DATE): string => moment(value).subtract(1, 'd').endOf('d').format(pattern);

const dateUtcFormat = (value: MomentInput, pattern = PATTERN.DAY_OF_MONTH_TIME) => moment.utc(value).format(pattern);

const dateFormat = (value: MomentInput, pattern = PATTERN.DAY_OF_MONTH_TIME) => moment(value).format(pattern);

const trimTimeZone = (date: string | number): string | number => {
  if (typeof date === 'string') {
    return trimTimezone(date);
  }

  return date;
};

const subPeriod = (value: DurationInputArg1, pattern: DurationInputArg2) => getMoment().subtract(value, pattern);

const getEarlyMonthFromCurrent = (current: MomentInput) => momentObject(current).startOf('month');

const endOfPeriod = (value: Moment, pattern: unitOfTime.StartOf) => value.endOf(pattern);

const momentSubPeriod = (current: MomentInput, value: DurationInputArg1, pattern: DurationInputArg2) => momentObject(current).subtract(value, pattern);

// TODO Проверить условие: momentValueMonth === getMoment().add(-1, 'M').month()
const isThisAndLastMonthDate = (value: MomentInput): boolean => {
  const momentValueMonth = momentObject(value).month();

  return momentValueMonth === getMoment().month()
    || momentValueMonth === getMoment().add(-1, 'M').month();
};

const minDate = (list: Moment[]): Moment => moment.min(list);

const maxDate = (list: Moment[]): Moment => moment.max(list);

const getYear = (value: number): Moment => getMoment().year(value);

const getCurrentMonth = (): number => getMoment().month();

const getCurrentYear = (): number => getMoment().year();

const getBeginOfTheYear = (currentYear: number): Moment => getMoment().year(currentYear).startOf('year');

const diffSeconds = (first: Moment, second: Moment): string => moment.duration(second.diff(first)).asSeconds().toFixed();
const diffMinutes = (first: Moment, second: Moment): string => moment.duration(second.diff(first)).asMinutes().toFixed();
const diffHours = (first: Moment, second: Moment): number => Math.floor(moment.duration(second.diff(first)).asHours());
const diffDays = (first: MomentInput, second: MomentInput): number => momentObject(second).startOf('day').diff(momentObject(first).startOf('day'), 'days');
const diffYears = (first: MomentInput, second: MomentInput): number => momentObject(second).diff(momentObject(first), 'years');

const isSameDay = (first: MomentInput, second: MomentInput): boolean => momentObject(first).isSame(momentObject(second), 'day');

const normalizeOurClusterLocalTime = (value: string | Moment | Date, format?: string): Moment | string => {
  const date = momentObject(`${value}Z`);

  if (format) {
    return date.format(format);
  }

  return date;
};

interface MonthsInPrepositionalCaseType { [n: string]: string }

const MonthsInPrepositionalCase: MonthsInPrepositionalCaseType = {
  January: 'январе',
  February: 'феврале',
  March: 'марте',
  April: 'апреле',
  May: 'мае',
  June: 'июне',
  July: 'июле',
  August: 'августе',
  September: 'сентябре',
  October: 'октябре',
  November: 'ноябре',
  December: 'декабре',
};

const getLastMonthInPrepositionalCase = (currentDate = getMoment(), locale = getCurrentLocale()): string | keyof MonthsInPrepositionalCaseType => {
  const lastMonth = currentDate.subtract(1, 'months').startOf('month').format('MMMM');

  if (locale === languages.ru) {
    return MonthsInPrepositionalCase[lastMonth];
  }

  return lastMonth;
};

const currentDate = (): Moment => getMoment().startOf('d');

// Возвращает 'позавчера/вчера/сегодня/завтра + HH:mm' или DD.MM.YYYY
const getDateDescriptor = (date: MomentInput): string => {
  const today = moment();
  const formattedDate = moment(date);

  if (today.diff(formattedDate, 'days') === 0) {
    return calendar(date);
  }

  if (today.diff(formattedDate, 'days') === 1) {
    return LABELS.yesterday(formatDate(date, DATEFORMATS.TIME));
  }

  return formatDate(date, DATEFORMATS.DATE);
};

const getDateDescriptorTime = (date: MomentInput): string => {
  const today = moment();
  const formattedDate = moment(date);

  if (today.diff(formattedDate, 'days') === 0) {
    return calendar(date);
  }

  if (today.diff(formattedDate, 'days') === 1) {
    return LABELS.yesterday(formatDate(date, DATEFORMATS.TIME));
  }

  return LABELS.inDate(formatDate(date, DATEFORMATS.DATE), formatDate(date, DATEFORMATS.TIME));
};

const getTimeOutMinutes = (date: MomentInput, value: number): number => momentObject(date).minutes() + value;

const getTimeOutMinute = (date: MomentInput, time: number): Moment => momentObject(date).set('minute', time).set('second', 0);

const noMoreValueDays = (startDate: Moment, endDate: MomentInput, days: DurationInputArg1): Moment => {
  const formattedDate = moment(endDate);
  const datePlusDays = startDate.add(days, 'd');

  if (formattedDate.isAfter(datePlusDays)) {
    return datePlusDays;
  }

  return formattedDate;
};

const getTimeFromFullFormYear = (value: string): string => value.split(' ')[1];

const getDateFromFullFormYear = (value: string): string => value.split(' ')[0];

const getDateFromTimezoneForm = (value: string): string => value.split('T')[0];

const getTimeFromTimezoneForm = (value: string): string => value.split('T')[1];

const prepareRealCheckoutDate = (value: MomentInput) => momentObject(value).set({ h: 12, m: 0 });

const setTimeInMoment = (
  date: MomentInput,
  hours = 0,
  minutes = 0,
  seconds = 0,
) => moment(date).set({
  [EnumDateLimits.HOUR]: hours,
  [EnumDateLimits.MINUTE]: minutes,
  [EnumDateLimits.SECOND]: seconds,
});

/**
 * Функция проверяет, что переданное время больше, чем текущее. Сначала проверка по дате, если текущая дата равна переданной дате (selectedDate), то далее идет сравнение по времени.
 * @param {*} item - Время, которое нужно проверить, может быть число H, или строка вида "Hh:mm".
 * @param {*} selectedDate - Дата, которая сначала будет сравниваться.
 * @param {*} [willBeAddHours] - Часы, которые прибавляются к текущему времени, если нужна сделать запас.
*/
const isTimeOver = (
  item: string | number,
  selectedDate: MomentInput,
  willBeAddHours = 0,
): boolean => {
  const isSame = isSameDate(selectedDate, moment(), EnumDateLimits.DAY);

  if (!isSame) return false;

  const currentDateHoursPlus = moment().hour() + willBeAddHours;
  const dateCurrent = moment().set(EnumDateLimits.HOUR, currentDateHoursPlus);
  let time = moment();

  if (typeof item === 'string') {
    const [hours, minutes] = item.split(':');

    time = setTimeInMoment(moment(), +hours, +minutes);
  }

  if (typeof item === 'number') {
    time = setTimeInMoment(moment(), item);
  }

  return isBeforeDate(time, dateCurrent);
};

const getLocaleTimeFromUtc = (value: string, pattern: string = PATTERN.LOCAL_TIME_PATTERN) => {
  const stillUtc = moment.utc(value).toDate();
  const local = moment(stillUtc).local().format(pattern);

  return local;
};

const checkDateForInsurance = (orderDate: IDates) => {
  const dateCurrent = moment();
  const datePlus = {
    min: 3,
    max: 90,
  };

  const minValidDate = moment().set(EnumDateLimits.DAY, dateCurrent.day() + datePlus.min);
  const maxValidDate = moment().set(EnumDateLimits.DAY, dateCurrent.day() + datePlus.max);

  const [minCheckin, maxCheckin] = orderDate;

  // обрезаем до даты
  const dateMin = moment(minCheckin).format(PATTERN.YEARMONTHDAY);
  const dateMax = moment(maxCheckin).format(PATTERN.YEARMONTHDAY);

  const condition =
    isAfterDate(moment(dateMin), moment(minValidDate)) &&
    isBeforeDate(moment(dateMax), moment(maxValidDate));

  return condition;
};

export {
  formatDate,
  getCurrentLocale,
  addUtcOffset,
  dateNowWithOffset,
  getMoment,
  momentObject,
  momentObjectUTC,
  startOfMonth,
  startOfDay,
  startOfYear,
  hasDayCome,
  fullFormPattern,
  fullFormYearTime,
  fullFormYear,
  hoursAndMinutesPattern,
  defaultPatternWithTime,
  defaultPattern,
  textualMonthPattern,
  textualMonthPatternWithoutZero,
  textualMonthWithHoursAndMinutesPattern,
  textualMonthWithHours,
  daysAndMounth,
  textualMonthAndYearWithHoursAndMinutesPattern,
  MINUTESSECONDSPATTERN,
  MINUTE_SECONDS_PATTERN,
  isMoment,
  normalizedDate,
  isValidMomentObject,
  isBeforeDate,
  isAfterDate,
  isSameDate,
  nextDateAfterCurrent,
  formatRangeDateWithSimplicity,
  formatDateRange,
  dateWithoutCurrentYear,
  isValidDateObject,
  dateWithoutMoment,
  isCurrentDateAfterMoscow,
  endPreviousDate,
  getEarlyMonthFromCurrent,
  getEndPreviousMonth,
  isSameOrAfter,
  isSameOrBefore,
  isBetweenDate,
  isThisAndLastMonthDate,
  subPeriod,
  endOfPeriod,
  dateUtcFormat,
  trimTimeZone,
  momentSubPeriod,
  minDate,
  maxDate,
  diffSeconds,
  diffMinutes,
  diffDays,
  diffHours,
  getMoscowTime,
  normalizeOurClusterLocalTime,
  addDays,
  getLastMonthInPrepositionalCase,
  diffYears,
  currentDate,
  isSameDay,
  getDateDescriptor,
  getTimeOutMinutes,
  getTimeOutMinute,
  noMoreValueDays,
  endPreviousDateMoment,
  getStartCurrentQuarter,
  getStartPreviousQuarter,
  getStartPreviousMonth,
  getStartOfYear,
  getStartMonth,
  getEndOfMonth,
  getStartOfDay,
  getCurrentYear,
  getCurrentMonth,
  getBeginOfTheYear,
  getYear,
  getEndOfYear,
  lastDayPreviousMonths,
  getStartOfYearValue,
  getTimeFromFullFormYear,
  getDateFromFullFormYear,
  getTimeFromTimezoneForm,
  getDateFromTimezoneForm,
  momentUtc,
  getLocalMomentObject,
  formatDateWithMinutes,
  formatMomentUtc,
  formatMomentUtcObject,
  prepareRealCheckoutDate,
  endPreviousDateMomentWithTodayMax,
  isCurrentYear,
  getPatternDateWithMinutes,
  calendar,
  isTimeOver,
  getDateDescriptorTime,
  formatLocationTemplate,
  formatDateTemplate,
  getLocaleTimeFromUtc,
  getStartPreviousYear,
  checkDateForInsurance,
  dateFormat,
  getReduceNumberDays,
  getReduceNumberHours,
};
