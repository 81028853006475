import React, { useEffect } from 'react';
import { CircleLoading, DoughnutChart, NoResults, Skeleton, StyledWrapper, Text } from 'new-ui';
import { Moment } from 'moment';

import { getText } from '../../../i18n';

import { useStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import Smartdesk from '../../bi/services/smartdesk';

import { ChartAnalyticsDates } from '../../page/ChartsAnalytics/components/dates';

import { COLORS_ANALYTICS_SA } from '../../bi/services/smartdesk/const';
import { DEFAULT_MIN_AMOUNT, TOTAL_AMOUNT_LENGTH } from './const';

import { formatCurrency, isTotalAmount } from '../../bi/utils/formatCurrency';
import { getMoment, getStartMonth } from '../../bi/utils/formatDate';
import { MainAnalytic } from '../../bi/utils/analytics';

import { ReportData } from '../../bi/services/smartdesk/types';

import styles from './styles/index.module.css';

const LABELS = {
  TITLE: getText('smartdesk:analyticsPanel.title'),
  NO_RESULT: getText('smartdesk:analyticsPanel.noResult'),
  PRICE: (totalAmount: string) => getText('smartdesk:analyticsPanel.price', { totalAmount }),
};

interface IAnalyticsPanel {
  analytics: ReportData,
  smartdeskService: Smartdesk,
  isErrorAnalytics: boolean,
}

const AnalyticsPanel = ({
  smartdeskService: {
    getAnalyticsSmartagentPanel,
    changeSelectedCompanies,
    changeStartDate,
    changeEndDate,
  },
  analytics: {
    donutChart,
    table,
    totalAmount,
  },
  isErrorAnalytics,
}: IAnalyticsPanel) => {
  const {
    smartdeskStore: {
      resAnalytics,
      selectedCompany,
    },
  } = useStores([MOBX_STORES.SMARTDESK]);

  useEffect(() => () => {
    changeStartDate(getStartMonth(getMoment()));
    changeEndDate(getMoment());
  }, []);

  const handleChangeStartDate = (value: Moment) => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMART_AGENT.REDISIGN.MAINANALYTICS_STARTDATE_CHOOSEN);

    changeStartDate(value);
  };

  const handleChangeEndDate = (value: Moment) => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMART_AGENT.REDISIGN.MAINANALYTICS_ENDDATE_CHOOSEN);

    changeEndDate(value);
  };

  if (!resAnalytics.companies) {
    return (
      <StyledWrapper className={ styles.skeleton }>
        <Skeleton className={ styles.skeleton }/>
      </StyledWrapper>
    );
  }

  const renderDoughnutChart = () => {
    if (!donutChart) {
      return null;
    }

    const { values, labels } = donutChart;

    if (!values?.length || !labels?.length) {
      return <CircleLoading size={ 150 }/>;
    }

    const value = isTotalAmount(totalAmount) ? values : [...values, DEFAULT_MIN_AMOUNT.VALUES];
    const label = isTotalAmount(totalAmount) ? labels : [...labels, DEFAULT_MIN_AMOUNT.LABELS];
    const typeAmount = totalAmount.length > TOTAL_AMOUNT_LENGTH.DEFAULT ? 'bold_14' : 'bold_18';

    return (
      <div className={ styles.wrapper_donut }>
        <div className={ styles.donut }>
          <DoughnutChart
            showTooltip={ isTotalAmount(totalAmount) }
            values={ value }
            barLabels={ label }
            colors={ COLORS_ANALYTICS_SA }
          />
        </div>
        <Text type={ typeAmount } className={ styles.text }>
          { LABELS.PRICE(formatCurrency(totalAmount)) }
        </Text>
      </div>
    );
  };

  const renderTable = () => {
    if (!donutChart || !table) {
      return null;
    }

    const { strings, columnNames } = table;
    const { values, labels } = donutChart;

    if (!values?.length || !labels?.length) {
      return null;
    }

    const renderColumn = () => (
      columnNames.map((item) => (
        <Text
          type='NORMAL_12'
          color='ligth-gray'
          key={ item }
          className={ styles.text }
        >
          { item }
        </Text>
      ))
    );

    const renderStrings = () => (
      strings.map(({ data }, indexRow) => (
        <div key={ indexRow } className={ styles.column }>
          <div
            className={ styles.cub }
            style={ { backgroundColor: COLORS_ANALYTICS_SA[indexRow] } }
          />
          { data.map((value, indexData) =>
            <Text type='NORMAL_14' key={ indexData } className={ styles.text }>
              { value }
            </Text>,
          )
        }
        </div>
      ))
    );

    return (
      <div className={ styles.wrapper_table }>
        <div className={ styles.header }>
          { renderColumn() }
        </div>
        <div className={ styles.border } />
        { renderStrings() }
      </div>
    );
  };

  const renderAnalyticsDate = () => {
    if (isErrorAnalytics) {
      return null;
    }

    return (
      <div className={ styles.wrapper_date }>
        <ChartAnalyticsDates
          isHeader={ false }
          getInfoSmartagent={ getAnalyticsSmartagentPanel }
          onSelectCompanies={ changeSelectedCompanies }
          onChangeStartDate={ handleChangeStartDate }
          onChangeEndDate={ handleChangeEndDate }
          common={ resAnalytics }
          selectedCompany={ selectedCompany }
        />
      </div>
    );
  };

  const renderContent = () => {
    if (isErrorAnalytics) {
      return (
        <NoResults
          alternativeDesign
          label={ LABELS.NO_RESULT }
        />
      );
    }

    return (
      <>
        <div className={ styles.wrapper_content }>
          { renderDoughnutChart() }
          { renderTable() }
        </div>
      </>
    );
  };

  return (
    <StyledWrapper className={ styles.wrapper }>
      <div className={ styles.wrapper_dates }>
        <Text type='bold_20' className={ styles.title }>
          { LABELS.TITLE }
        </Text>
        { renderAnalyticsDate() }
      </div>
      { renderContent() }
    </StyledWrapper>
  );
};

export default AnalyticsPanel;
