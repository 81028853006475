import { action, computed, makeObservable, observable } from 'mobx';
import { Moment } from 'moment';

import {
  currentDate,
  getMoment,
  isBeforeDate,
  isMoment,
  momentObject,
  nextDateAfterCurrent,
} from '../../../utils/formatDate';

import { IFromTo, ITrainHistoryItem, ITrainAutocompleteItem, IWidget } from '../../../types/trains';

export type TTrainSearchStore = typeof TrainSearchStore;

const DEFAULT_FROM_TO = {
  label: '',
  selected: {},
  suggests: [],
};

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable from: IFromTo = DEFAULT_FROM_TO;
  @observable to: IFromTo = DEFAULT_FROM_TO;
  @observable travellers: number = 1;
  @observable date: Moment | null = currentDate().clone();
  @observable dateBack: Moment | null = null;
  @observable minDate: Moment = currentDate().clone();
  @observable minDateBack: Moment = currentDate().clone();
  @observable immediate: boolean = false;
  @observable searchId: string = '';
  @observable historyList: ITrainHistoryItem[] = [];
  @observable firstSearch: boolean = false;
  @observable isTravelApproval: boolean = false;
  @observable schemeLoading: boolean = false;
  @observable textTemplateWarning: string = '';

  @action
  formatDefaultSuggests = (items: ITrainAutocompleteItem[], selectedItemCode?: string): ITrainAutocompleteItem[] => {
    const clonedItems = [...items];
    const indexToRemove = clonedItems.findIndex(suggest => suggest.Code === selectedItemCode);

    if (indexToRemove === -1) {
      clonedItems.splice(4, 1);
    } else {
      clonedItems.splice(indexToRemove, 1);
    }

    return clonedItems;
  };

  @action
  setFromSuggests = (query: string, items: ITrainAutocompleteItem[], loading: boolean = false) => {
    const suggests = query === ''
      ? this.formatDefaultSuggests(items, (this.to.selected as ITrainAutocompleteItem).Code)
      : items;

    this.from = {
      ...this.from,
      label: query,
      selected: {},
      suggests,
      loading,
    };
  };

  @action
  setFromSuggestsLoading = (loading: boolean) => {
    this.from = {
      ...this.from,
      loading,
    };
  };

  @action
  setToSuggestsLoading = (loading: boolean) => {
    this.to = {
      ...this.to,
      loading,
    };
  };

  @action
  setToSuggests = (query: string, items: ITrainAutocompleteItem[], loading: boolean = false) => {
    const suggests = query === ''
      ? this.formatDefaultSuggests(items, (this.from.selected as ITrainAutocompleteItem).Code)
      : items;

    this.to = {
      ...this.to,
      label: query,
      selected: {},
      suggests,
      loading,
    };
  };

  @action
  setFromSelected = (item: ITrainAutocompleteItem): void => {
    this.from = {
      ...this.from,
      label: item.Name,
      suggests: [],
      selected: item,
    };
  };

  @action
  setToSelected = (item: ITrainAutocompleteItem): void => {
    this.to = {
      ...this.to,
      label: item.Name,
      suggests: [],
      selected: item,
    };
  };

  @action
  setFromAndTo = (from: IFromTo, to: IFromTo) => {
    this.from = from;
    this.to = to;
  };

  @action
  setFlipFromTo = (): void => {
    const { from, to } = this;

    this.from = {
      ...to,
    };

    this.to = {
      ...from,
    };
  };

  @action
  setNewSearch = (): void => {
    this.from = DEFAULT_FROM_TO;

    this.to = DEFAULT_FROM_TO;

    this.travellers = 1;
    this.date = currentDate().clone();
    this.dateBack = null;
    this.minDate = currentDate().clone();
    this.minDateBack = currentDate().clone();
    this.immediate = false;
    this.isTravelApproval = false;
  };

  @action
  setSchemeLoading = (value: boolean): void => {
    this.schemeLoading = value;
  };

  @action
  setSearchFromApprovalRequest = ({ Destinations, Employees, StartDate, EndDate }: any): void => {
    const employeesLength = Employees.length;

    this.to = {
      label: Destinations.Name,
      selected: Destinations,
      suggests: [],
    };

    this.travellers = employeesLength > 4 ? 4 : employeesLength;
    this.date = nextDateAfterCurrent(StartDate);
    this.dateBack = momentObject(EndDate);
    this.minDate = currentDate().clone();
    this.minDateBack = currentDate().clone();
    this.immediate = false;
    this.isTravelApproval = true;
  };

  @action
  setDate = (value: Moment | null): void => {
    const newDateBack = this.dateBack && isBeforeDate(this.dateBack, value) && isMoment(value)
      ? (value as Moment).clone().add(1, 'd')
      : this.dateBack;

    this.date = value;
    this.dateBack = newDateBack;
    this.minDateBack = isMoment(value) ? (value as Moment).clone() : currentDate().clone();
  };

  @action
  setDateBack = (value: Moment | null): void => {
    this.dateBack = value || null;
  };

  @action
  setTravellers = (value: number): void => {
    this.travellers = value;
  };

  @action
  setHistoryInstance = ({
    StationFrom,
    StationTo,
    Travellers,
    DepartureDate,
  }: ITrainHistoryItem,
  searchId: string,
  ): void => {
    this.date = momentObject(DepartureDate);
    this.minDate = getMoment().startOf('d');
    this.minDateBack = this.date.clone();

    this.from = {
      ...this.from,
      label: StationFrom.City,
      selected: StationFrom,
      suggests: [],
    };

    this.to = {
      ...this.to,
      label: StationTo.City,
      selected: StationTo,
      suggests: [],
    };

    this.travellers = Travellers as number;
    this.searchId = searchId;
  };

  @action
  setImmediateSearch = (value: boolean): void => {
    this.immediate = value;
  };

  @action
  setFirstSearch = (value: boolean): void => {
    this.firstSearch = value;
  };

  @action
  setHistoryList = (list: ITrainHistoryItem[]): void => {
    this.historyList = list;
  };

  @action
  setWarningTemplate = (warningMessage: IWidget): void => {
    this.textTemplateWarning = warningMessage?.content?.text || '';
  };

  @computed
  get isValid(): boolean | '' {
    const isValidRoutes = this.from.selected
        && (this.from.selected as ITrainAutocompleteItem).Code
        && this.to.selected
        && (this.to.selected as ITrainAutocompleteItem).Code;
    const isValidDate = !!this.date
        && isMoment(this.date)
        && (this.date as Moment).isValid()
        && (this.date as Moment).isSameOrAfter(this.minDate);
    const isValidDateBack = this.dateBack
      ? (this.dateBack as Moment).isValid() && (this.dateBack as Moment).isSameOrAfter(this.minDateBack)
      : true;

    return isValidRoutes && isValidDate && isValidDateBack;
  }
}

const TrainSearchStore = new Store();

export { TrainSearchStore };
