import { getText } from '../../../i18n';

import {
  CHANGETICKET,
  REFUNDTICKET,
  VALUETICKET,
  BAGGAGE,
} from '../constants/airline';
import { AirlineFare, Conditions } from '../types/airline';

const LABELS = {
  INCLUDED: getText('utils:fares.included'),
  REFUND_AND_EXCHANGE: getText('utils:fares.refundAndExchange'),
  CHANGES: getText('utils:fares.changes'),
  REFUND: getText('utils:fares.refund'),
  RATE_UP: getText('utils:fares.rateUp'),
  BAGGAGE: {
    INCLUDED: getText('utils:fares.baggage.included'),
    NOT_DEFINED: getText('utils:fares.baggage.notDefined'),
  },
  CARRY_ON: {
    INCLUDED: getText('utils:fares.carryOn.included'),
    CHARGE: getText('utils:fares.carryOn.included'),
  },
  REFUNDABLE: {
    NOT_OFFERED: getText('utils:fares.refundable.notOffered'),
    INCLUDED: getText('utils:fares.refundable.included'),
    CHARGE: getText('utils:fares.refundable.charge'),
    NOT_DEFINED: getText('utils:fares.refundable.notDefined'),
    NOT_PENALTY: getText('utils:fares.refundable.noPenalty'),
  },
  CHANGEABLE: {
    NOT_OFFERED: getText('utils:fares.changeable.notOffered'),
    INCLUDED: getText('utils:fares.changeable.included'),
    CHARGE: getText('utils:fares.changeable.charge'),
  },
  SEATS: {
    INCLUDED: getText('utils:fares.seats.included'),
    CHARGE: getText('utils:fares.seats.charge'),
  },
  BUSINESS_HALL: {
    NOT_OFFERED: getText('utils:fares.businessHall.notOffered'),
    INCLUDED: getText('utils:fares.businessHall.included'),
    CHARGE: getText('utils:fares.businessHall.charge'),
  },
  REFUND_AFTER_DEPARTURE: {
    NOT_OFFERED: getText('utils:fares.refundAfterDeparture.notOffered'),
    INCLUDED: getText('utils:fares.refundAfterDeparture.included'),
    NO_PENALTY: getText('utils:fares.refundAfterDeparture.noPenalty'),
    CHARGE: getText('utils:fares.refundAfterDeparture.charge'),
  },
  CHANGEABLE_AFTER_DEPARTURE: {
    NOT_OFFERED: getText('utils:fares.changeableAfterDeparture.notOffered'),
    INCLUDED: getText('utils:fares.changeableAfterDeparture.included'),
    CHARGE: getText('utils:fares.changeableAfterDeparture.charge'),
  },
};

const CARRYONPLACESDEFAULTVALUE = LABELS.INCLUDED;

const hasRefundWithConditions = (conditions: Conditions = {}) =>
  Object.keys(conditions).some(key => conditions[key] === VALUETICKET.CHARGE || VALUETICKET.INCLUDED);

const prepareBaggage = (value: string, places: string) => {
  switch (value) {
    case VALUETICKET.INCLUDED:
      return `${LABELS.BAGGAGE.INCLUDED}${places ? `: ${places}` : ''}`;
    case VALUETICKET.NOTDEFINED:
      return '';
    default:
      return LABELS.BAGGAGE.NOT_DEFINED;
  }
};

const prepareCarryOn = (value: string, places: string) => {
  switch (value) {
    case VALUETICKET.INCLUDED:
      return `${LABELS.CARRY_ON.INCLUDED} ${places || CARRYONPLACESDEFAULTVALUE}`;
    case VALUETICKET.CHARGE:
      return LABELS.CARRY_ON.CHARGE;
    default:
      return '';
  }
};

const prepareRefundable = (value: string) => {
  switch (value) {
    case VALUETICKET.NOTOFFERED:
      return LABELS.REFUNDABLE.NOT_OFFERED;
    case VALUETICKET.INCLUDED:
      return LABELS.REFUNDABLE.INCLUDED;
    case VALUETICKET.CHARGE:
      return LABELS.REFUNDABLE.CHARGE;
    case VALUETICKET.NOTDEFINED:
      // return 'Возврат: уточните у Travel помощника возможность возврата';
      return LABELS.REFUNDABLE.NOT_DEFINED;
    case VALUETICKET.NOTPENALTY:
      return LABELS.REFUNDABLE.NOT_PENALTY;
    default:
      return '';
  }
};

const prepareChangeable = (value: string) => {
  switch (value) {
    case VALUETICKET.NOTOFFERED:
      return LABELS.CHANGEABLE.NOT_OFFERED;
    case VALUETICKET.INCLUDED:
      return LABELS.CHANGEABLE.INCLUDED;
    case VALUETICKET.CHARGE:
      return LABELS.CHANGEABLE.CHARGE;
    default:
      return '';
  }
};

const prepareRegistrationSeat = (value: string, description: string) => {
  const desc = description ? ` (${description})` : '';

  switch (value) {
    case VALUETICKET.INCLUDED:
      return `${LABELS.SEATS.INCLUDED}${desc}`;
    case VALUETICKET.CHARGE:
      return `${LABELS.SEATS.CHARGE}${desc}`;
    default:
      return '';
  }
};

const prepareBusinessHallIncluded = (value: string) => {
  switch (value) {
    case VALUETICKET.INCLUDED:
      return LABELS.BUSINESS_HALL.INCLUDED;
    case VALUETICKET.NOTOFFERED:
      return LABELS.BUSINESS_HALL.NOT_OFFERED;
    case VALUETICKET.CHARGE:
      return LABELS.BUSINESS_HALL.CHARGE;
    default:
      return '';
  }
};

const prepareRefundableAfterDeparture = (value: string) => {
  switch (value) {
    case VALUETICKET.INCLUDED:
      return LABELS.REFUND_AFTER_DEPARTURE.INCLUDED;
    case VALUETICKET.NOTOFFERED:
      return LABELS.REFUND_AFTER_DEPARTURE.NOT_OFFERED;
    case VALUETICKET.NOTPENALTY:
      return LABELS.REFUND_AFTER_DEPARTURE.NO_PENALTY;
    case VALUETICKET.CHARGE:
      return LABELS.REFUND_AFTER_DEPARTURE.CHARGE;
    default:
      return '';
  }
};

const prepareChangeableAfterDeparture = (value: string) => {
  switch (value) {
    case VALUETICKET.INCLUDED:
      return LABELS.CHANGEABLE_AFTER_DEPARTURE.INCLUDED;
    case VALUETICKET.CHARGE:
      return LABELS.CHANGEABLE_AFTER_DEPARTURE.CHARGE;
    case VALUETICKET.NOTOFFERED:
      return LABELS.CHANGEABLE_AFTER_DEPARTURE.NOT_OFFERED;
    default:
      return '';
  }
};

const preparedFares = ({
  IsBaggageIncluded,
  BaggagePlaces,
  IsCarryonIncluded,
  CarryonPlaces,
  IsTicketRefundable,
  IsTicketChangeable,
  CanRegistrationSeat,
  IsBusinessHallIncluded,
  IsTicketRefundableAfterDeparture,
  CanUpgradeRate,
  AirlineBonusInformation,
  IsTicketChangeableAfterDeparture,
  BaggageIncludedWithCondition,
  RefundTicketWithCondition,
  ChangeTicketWithCondition,
  SeatDescription,
  Note,
}: AirlineFare,
customRefundabilityAndExchangeability = false,
) => {
  const details: string[] = [];
  const baggage = prepareBaggage(IsBaggageIncluded, BaggagePlaces);
  const carryOn = prepareCarryOn(IsCarryonIncluded, CarryonPlaces);
  const refundable = hasRefundWithConditions(RefundTicketWithCondition) ? '' : prepareRefundable(IsTicketRefundable);
  const changeable = prepareChangeable(IsTicketChangeable);
  const registrationSeat = prepareRegistrationSeat(CanRegistrationSeat, SeatDescription);
  const businessHallIncluded = prepareBusinessHallIncluded(IsBusinessHallIncluded);
  const refundableAfterDeparture = prepareRefundableAfterDeparture(IsTicketRefundableAfterDeparture);
  const changeableAfterDeparture = prepareChangeableAfterDeparture(IsTicketChangeableAfterDeparture);

  if (baggage.length) {
    details.push(baggage);
  }

  if (carryOn.length) {
    details.push(carryOn);
  }

  if (refundable.length && !customRefundabilityAndExchangeability) {
    details.push(refundable);
  }

  if (changeable.length && !customRefundabilityAndExchangeability) {
    details.push(changeable);
  }

  if (registrationSeat.length) {
    details.push(registrationSeat);
  }

  if (businessHallIncluded.length) {
    details.push(businessHallIncluded);
  }

  if (refundableAfterDeparture.length && !customRefundabilityAndExchangeability) {
    details.push(refundableAfterDeparture);
  }

  if (CanUpgradeRate === VALUETICKET.INCLUDED) {
    details.push(LABELS.RATE_UP);
  }

  if (AirlineBonusInformation) {
    details.push(AirlineBonusInformation);
  }

  if (changeableAfterDeparture.length && !customRefundabilityAndExchangeability) {
    details.push(changeableAfterDeparture);
  }

  if (BaggageIncludedWithCondition && !customRefundabilityAndExchangeability) {
    Object.keys(BaggageIncludedWithCondition).forEach((field) => {
      if (BAGGAGE[BaggageIncludedWithCondition[field]]) {
        details.push(`${BAGGAGE[BaggageIncludedWithCondition[field]]} ${field}`);
      }
    });
  }

  if (RefundTicketWithCondition && !customRefundabilityAndExchangeability) {
    Object.keys(RefundTicketWithCondition).forEach((field) => {
      if (REFUNDTICKET[RefundTicketWithCondition[field]]) {
        details.push(`${LABELS.REFUND} ${field}: ${REFUNDTICKET[RefundTicketWithCondition[field]]}`);
      }
    });
  }

  if (ChangeTicketWithCondition && !customRefundabilityAndExchangeability) {
    Object.keys(ChangeTicketWithCondition).forEach((field) => {
      if (CHANGETICKET[ChangeTicketWithCondition[field]]) {
        details.push(`${LABELS.CHANGES} ${field}: ${CHANGETICKET[ChangeTicketWithCondition[field]]}`);
      }
    });
  }

  if (customRefundabilityAndExchangeability) {
    details.push(LABELS.REFUND_AND_EXCHANGE);
  }

  if (Note) {
    details.push(Note);
  }

  return details;
};

export { preparedFares as default, hasRefundWithConditions };
